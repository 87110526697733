.row--primary,
.row--secondary {
  width: 100%;
  margin: 0 auto;
}

.row--primary {
  max-width: 720px;
}

.row--secondary {
  max-width: 916px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.spacer-top-navigation {
  margin-top: rem($navigation-height + $default-spacing);
}

@media screen and (max-width: $bp-md) {
  .spacer-top-navigation {
    margin-top: rem($navigation-height-mobile + 16);
  }
}

.padding-5 {
  padding: 5px;
}
.padding-15 {
  padding: 15px;
}

/* left paddings */
.padding-left-5 {
  padding-left: 5px;
}
@media screen and (max-width: 575px) {
  .padding-xs-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-5 {
    padding-left: 5px;
  }
}

.padding-left-10 {
  padding-left: 10px;
}
@media screen and (max-width: 575px) {
  .padding-xs-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-10 {
    padding-left: 10px;
  }
}

.padding-left-15 {
  padding-left: 15px;
}
@media screen and (max-width: 575px) {
  .padding-xs-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-15 {
    padding-left: 15px;
  }
}

.padding-left-24 {
  padding-left: 24px;
}

.padding-left-30 {
  padding-left: 30px;
}
@media screen and (max-width: 575px) {
  .padding-xs-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-30 {
    padding-left: 30px;
  }
}

/* right paddings */
.padding-right-5 {
  padding-right: 5px;
}
@media screen and (max-width: 575px) {
  .padding-xs-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-lg-right-5 {
    padding-right: 5px;
  }
}

.padding-right-10 {
  padding-right: 10px;
}
@media screen and (max-width: 575px) {
  .padding-xs-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-right-10 {
    padding-right: 10px;
  }
}

.padding-right-15 {
  padding-right: 15px;
}
@media screen and (max-width: 575px) {
  .padding-xs-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-right-15 {
    padding-right: 15px;
  }
}

.padding-right-30 {
  padding-right: 30px;
}
@media screen and (max-width: 575px) {
  .padding-xs-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-right-30 {
    padding-right: 30px;
  }
}

/* padding top */
.padding-top-2 {
  padding-top: 2px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-top-30 {
  padding-top: 30px;
}
@media screen and (max-width: 575px) {
  .padding-xs-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-top-15 {
    padding-top: 15px;
  }
}

/* padding bottom */
.padding-bottom-2 {
  padding-bottom: 2px;
}
.padding-bottom-5 {
  padding-bottom: 5px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-15 {
  padding-bottom: 15px;
}
.padding-bottom-30 {
  padding-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .padding-xs-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-bottom-15 {
    padding-bottom: 15px;
  }
}

/* margins ***/
.margin-left-2-2 {
  margin-left: 2.2rem;
}

.margin-left-10 {
  margin-left: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}
@media screen and (max-width: 575px) {
  .margin-xs-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-left-10 {
    margin-left: 10px;
  }
}

.margin-right-5 {
  margin-right: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .margin-xs-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-right-15 {
    margin-right: 15px;
  }
}

.margin-right-30 {
  margin-right: 30px;
}
@media screen and (max-width: 575px) {
  .margin-xs-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-right-30 {
    margin-right: 30px;
  }
}

.margin-top-5 {
  margin-top: 5px;
}
.margin-top-8 {
  margin-top: 0.5rem;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-15 {
  margin-top: 15px;
}
@media screen and (max-width: 575px) {
  .margin-xs-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-top-15 {
    margin-top: 15px;
  }
}

.margin-top-30 {
  margin-top: 30px;
}
@media screen and (max-width: 575px) {
  .margin-xs-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-top-30 {
    margin-top: 30px;
  }
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}
@media screen and (max-width: 575px) {
  .margin-xs-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-bottom-15 {
    margin-bottom: 15px;
  }
}

.hidden {
  display: none !important;
}
@media screen and (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}

.flex-grow {
  flex: 1;
}
@media screen and (max-width: 575px) {
  .flex-xs-grow {
    flex-grow: 1;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .flex-sm-grow {
    flex-grow: 1;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flex-md-grow {
    flex-grow: 1;
  }
}

.flex-even {
  flex: 1;
}

.minw-15 {
  min-width: 15rem;
}

.minh-55 {
  min-height: 5.5rem;
}

.max-w-7 {
  max-width: $font-size-base * 7;
}

.min-w-4 {
  min-width: $font-size-base * 4;
}

.min-w-7 {
  min-width: $font-size-base * 7;
}
.min-w-9 {
  min-width: $font-size-base * 9;
}
.min-w-12 {
  min-width: $font-size-base * 12;
}

.max-w-fit {
  max-width: fit-content;
}
.max-w-75 {
  max-width: 75%;
}
.max-w-100 {
  max-width: 100%;
}
.w-100 {
  width: 100%;
}
.w-fit {
  width: fit-content;
}

.w-one-third {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .w-one-third {
    width: 33.333333%;
  }
}

@media screen and (max-width: 575px) {
  .max-w-xs-75 {
    max-width: 75%;
  }
}

/*
  creates width-classes for every grid breakpoint and size in $pct-widths.
  E.g: width-25, width-gs, width-md-25, width-lg-75 etc.
 */
$pct-widths: (
  50: 50%,
  75: 75%,
  100: 100%,
  gs: 100% * 3 * 0.125,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in map-keys($pct-widths) {
      .width#{$infix}-#{$value} {
        width: map-get($pct-widths, $value) !important;
      }
    }
  }
}

@media screen and (min-height: 761px) {
  .view-height-100 {
    height: 100vh;
  }
}

.flex-auto {
  flex: auto;
}
