.link {
  color: var(--color-grey-500);
  &:not(.cursor-auto) {
    cursor: pointer;
  }
}
.link-underline {
  text-decoration: underline;
}
.link:hover,
.link:focus {
  color: var(--color-grey-500);
  text-decoration: underline;
}
.link:active {
  color: var(--color-grey-500);
}

.link-primary {
  color: var(--oldblue);

  &:hover,
  &:focus {
    text-decoration: underline;
    color: var(--color-blue-400);
  }
}

.link-secondary {
  font-size: 14px;
  color: var(--color-blue-400);

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    color: var(--color-blue-400);
    text-decoration: underline;
  }

  &.disabled {
    color: var(--color-grey-300);
    cursor: not-allowed;
  }
}

.link-white {
  color: var(--white);
  &:hover,
  &:focus {
    color: var(--color-grey-500);
  }
}

.link-black {
  color: var(--color-grey-50);
  &:hover,
  &:focus {
    color: var(--color-grey-50);
  }
}

.underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

.link-underline .link-warning {
  color: var(--color-red-400);
  &:hover,
  &:focus {
    color: var(--color-red-400);
    text-decoration: underline;
  }
}

.link,
.link-primary,
.link-secondary,
.link-underline,
.link-warning,
.link-white {
  transition: 0.4s;
}
