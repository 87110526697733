.chart-container {
  width: 100%;
  height: 460px;
  position: relative;

  .loading {
    width: 100%;
    height: 100%;
  }

  .no-chart-info {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }
}

#chart-settings {
  border: none;
  background: transparent;
  outline: none;
  color: var(--color-grey-300);
}

.dropdown-menu {
  h5 {
    padding: 0.25rem 1.5rem;
  }
}

.stx_line_chart {
  color: var(--color-blue-400);
}

.stx_mountain_chart {
  color: color-mix(in srgb, var(--color-blue-400) 75%, transparent);
  background-color: color-mix(in srgb, var(--color-blue-400) 80%, transparent);
  border-top-color: var(--color-blue-400);
}

.stx-panel-control.stx-show {
  display: none;
}

.chartSize {
  display: none;
}
