.gj-datepicker.input-group {
  width: 100%;
  border-bottom: 1px solid var(--color-grey-500);
}
.gj-datepicker.input-group input {
  border: none;
  padding: 2px 0 2px 0;
}
.gj-datepicker.input-group input-group-append {
  border: none;
}
.gj-datepicker.input-group button {
  border: none;
  background: transparent;
}
.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.gj-datepicker .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled).active,
.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled):active,
.gj-datepicker .show > .btn-outline-secondary.dropdown-toggle {
  background: none;
  border: none;
  color: var(--color-grey-500);
}
.gj-datepicker-bootstrap [role='right-icon'] button .gj-icon,
.gj-datepicker-bootstrap [role='right-icon'] button .material-icons {
  top: 5px;
}
.gj-datepicker input + span.input-group-append:after {
  display: block;
  content: '';
  height: 2px;
  width: 0;
  transition: width 0.25s linear;
  background: var(--color-grey-300);
  position: absolute;
  left: 0;
  bottom: -2px;
}

.gj-datepicker input:focus + span.input-group-append:after {
  width: 100%;
}
.gj-datepicker input.ng-valid + span.input-group-append:after {
  width: 100%;
  background-color: var(--color-green-400);
}

.ngb-dp-day.disabled {
  text-decoration: line-through;
  cursor: not-allowed;
  text-decoration-color: var(--color-blue-400);
  div {
    background: var(--color-grey-800);
  }
}
