ul:not(.list-square):not(.list):not(.list-doc):not(.list-letter):not(.list-number):not(.pagination) {
  padding-left: 24px;
  list-style: none;

  li::before {
    content: '•';
    color: var(--color-blue-400);
    display: inline-block;
    position: relative;
    width: 16px;
    margin-left: -1em;
    font-size: 24px;
    top: 4px;
  }
}

.list-square,
.list-doc {
  list-style: none;
  padding-left: 24px;
}
.list-square li,
.list-doc li {
  position: relative;
  font-size: 1rem;
  line-height: 130%;
  padding-bottom: 7px;
}
.list-square {
  li:before {
    content: ' ';
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Crect width='10' height='10' stroke='none' fill='%235ca3ef' /%3E%3C/svg%3E");
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    left: -19px;
    top: 5px;
  }
  &.list-square--smaller li:before {
    width: 6px;
    height: 6px;
    left: -15px;
    top: 7px;
  }
}

.list-square.plussign li {
  font-size: 1rem;
  padding-left: 0.5rem;
}

.list-square.plussign li:before {
  background-image: svg-url(
    '<svg width="81px" height="81px" version="1.1" viewBox="-0.5 -0.5 81 81" xmlns="http://www.w3.org/2000/svg"><g pointer-events="none"><rect width="80" height="80" fill="#248eff"/><path d="m20 36h16v-16h8v16h16v8h-16v16h-8v-16h-16z" fill="#fff"/></g></svg>'
  );
  width: 14px;
  height: 14px;
  left: -1.4rem;
  top: 0.2rem;
}

.list-square.check li {
  font-size: 14px;
  line-height: unset;
}

.list-square.check li:before {
  background-image: svg-url(
    ' <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" > <g fill="none" fill-rule="evenodd"> <g fill="#fff" fill-rule="nonzero"> <g> <rect width="32" height="32" fill="#248eff"/> <path d="M20 0.453L7.706 12.797 4.305 9.263 0.71 12.797 7.5 19.547 23.288 3.785z" transform="translate(4 6)"/> </g> </g> </g> </svg>'
  );
  width: 14px;
  height: 14px;
  left: -1.4rem;
  top: 0.2rem;
}

.list-square.quotes li {
  font-size: 1rem;
  padding-left: 0.5rem;
}

.list-square.quotes li:before {
  background-image: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16.25 16.25"><defs><style>.cls-1{fill:none;}.cls-2{fill:#ccccd6;}.cls-3{clip-path:url(#clip-path);}.cls-4{fill:#fff;}</style><clipPath id="clip-path"><rect class="cls-1" width="16.25" height="16.25"/></clipPath></defs><rect class="cls-2" width="16.25" height="16.25"/><g class="cls-3"><path class="cls-4" d="M7.1,4.31a4.69,4.69,0,0,0-2,.94A3.38,3.38,0,0,0,3.92,6.8a8.94,8.94,0,0,0-.3,2.25v2.89H7.16V8.42H5.25a3.81,3.81,0,0,1,.23-1.5A2.32,2.32,0,0,1,7.1,5.64Zm5.46,0a7.27,7.27,0,0,0-1.74.7A3.74,3.74,0,0,0,9.38,6.86a6.89,6.89,0,0,0-.29,2.19v2.89h3.53V8.42H10.71a3.32,3.32,0,0,1,.41-1.87,2.8,2.8,0,0,1,1.44-.91Z"/></g></svg>'
  );
  width: 14px;
  height: 14px;
  left: -1.4rem;
  top: 0.2rem;
}

.list-letter,
.list-number {
  li:before {
    position: absolute;
    left: 1.5rem;
  }

  &.provision {
    padding-left: 20px;

    li {
      margin: 10px 0;
    }

    li:before {
      font-variation-settings: var(--font-medium);
    }
  }
}

.list-letter {
  list-style-type: upper-latin;
}
.list-number {
  list-style-type: decimal;
}

.list {
  list-style: none;
  padding: 0;
  margin-top: 1rem;

  &.footer {
    a {
      transition: 0.4s;

      &:hover {
        color: var(--color-blue-400);
      }
    }
  }

  li {
    margin-bottom: 0.5rem;
  }
}

.list-doc {
  padding-left: 44px;
  li {
    line-height: unset;
    padding-left: 5px;
    margin-left: -24px;
  }
  li:not(.defaultsymbol):before {
    content: ' ';
    background-size: cover;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' id='copy-doc' viewBox='0 0 15 18'%3E%3Cg stroke='%235CA3EF' stroke-width='1.391' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M13.8 3.783a.7.7 0 0 1 .2.491v12.03a.69.69 0 0 1-.684.696H1.684A.69.69 0 0 1 1 16.304V1.696A.69.69 0 0 1 1.684 1h9.095c.182 0 .356.073.484.204L13.8 3.783zM3.395 11.783h8.21M3.395 13.87h8.21M3.395 9.696h8.21M3.395 7.609h8.21M3.395 5.522h4.789M3.395 5.522h4.789M3.395 7.609h8.21M3.395 9.696h8.21M3.395 11.783h8.21M3.395 13.87h8.21'/%3E%3C/g%3E%3C/svg%3E");
    width: 13px;
    height: 16px;
    position: absolute;
    left: -19px;
    top: 5px;
  }
}
