.zero-text {
  font-style: normal;

  &.default {
    color: var(--color-grey-50);
  }

  &.btn,
  &.white {
    color: var(--white);
  }

  &.link {
    color: var(--color-blue-400);

    &.disabled {
      cursor: not-allowed;
      text-decoration: none;
    }

    &:not(.disabled):hover {
      color: var(--color-blue-500);
    }
  }

  &.label {
    color: var(--color-grey-400);
  }

  &.disabled {
    color: var(--color-grey-500);
  }

  &.course-red {
    color: var(--color-red-400);
  }

  &.course-green {
    color: var(--color-green-400);
  }

  &.dark-red {
    color: var(--color-red-100);
  }

  &.dark-green {
    color: var(--color-green-100);
  }

  &.dark-blue {
    color: var(--color-blue-50);
  }

  &.dark-purple {
    color: var(--color-purple-100);
  }

  &.warning {
    color: var(--color-yellow-400);
  }

  &.p-base-lig,
  &.p-base-reg,
  &.p-base-med,
  &.p-base-bold {
    font-size: 1rem;
    line-height: 22.4px;
  }

  &.p-base-lig {
    font-variation-settings: var(--font-light);
  }

  &.p-base-reg {
    font-variation-settings: var(--font-regular);
  }

  &.p-base-med {
    font-variation-settings: var(--font-medium);
  }

  &.p-base-bold {
    font-variation-settings: var(--font-bold);
  }

  &.p-small-lig,
  &.p-small-reg,
  &.p-small-med,
  &.p-small-bold {
    font-size: 14px;
    line-height: 19.6px;
  }

  &.p-small-lig {
    font-variation-settings: var(--font-light);
  }

  &.p-small-reg {
    font-variation-settings: var(--font-regular);
  }

  &.p-small-med {
    font-variation-settings: var(--font-medium);
  }

  &.p-small-bold {
    font-variation-settings: var(--font-bold);
  }

  &.p-xsmall-lig,
  &.p-xsmall-reg,
  &.p-xsmall-med,
  &.p-xsmall-bold {
    font-size: 12px;
    line-height: 16.8px;
  }

  &.p-xsmall-lig {
    font-variation-settings: var(--font-light);
  }

  &.p-xsmall-reg {
    font-variation-settings: var(--font-regular);
  }

  &.p-xsmall-med {
    font-variation-settings: var(--font-medium);
  }

  &.p-xsmall-bold {
    font-variation-settings: var(--font-bold);
  }

  &.p-big-lig,
  &.p-big-reg,
  &.p-big-med,
  &.p-big-bold {
    font-size: 18px;
    line-height: 25.2px;
  }

  &.p-big-lig {
    font-variation-settings: var(--font-light);
  }

  &.p-big-reg {
    font-variation-settings: var(--font-regular);
  }

  &.p-big-med {
    font-variation-settings: var(--font-medium);
  }

  &.p-big-bold {
    font-variation-settings: var(--font-bold);
  }

  &.p-bigger-lig,
  &.p-bigger-reg,
  &.p-bigger-med,
  &.p-bigger-bold {
    font-size: 20px;
    line-height: 28px;
  }

  &.p-bigger-lig {
    font-variation-settings: var(--font-light);
  }

  &.p-bigger-reg {
    font-variation-settings: var(--font-regular);
  }

  &.p-bigger-med {
    font-variation-settings: var(--font-medium);
  }

  &.p-bigger-bold {
    font-variation-settings: var(--font-bold);
  }

  &.p-huge-lig,
  &.p-huge-reg,
  &.p-huge-med,
  &.p-huge-bold {
    font-size: 26px;
    line-height: 36.4px;
  }

  &.p-huge-lig {
    font-variation-settings: var(--font-light);
  }

  &.p-huge-reg {
    font-variation-settings: var(--font-regular);
  }

  &.p-huge-med {
    font-variation-settings: var(--font-medium);
  }

  &.p-huge-bold {
    font-variation-settings: var(--font-bold);
  }

  &.h1-lig,
  &.h1-bold {
    font-size: 32px;
    line-height: 44.8px;
  }

  &.h1-lig {
    font-variation-settings: var(--font-light);
  }

  &.h1-bold {
    font-variation-settings: var(--font-bold);
  }
}

.zero-fix {
  &.white {
    color: var(--color-white);
  }

  &.black {
    color: var(--color-black);
  }
}

.zero-surface {
  &.oldblue {
    background: var(--oldblue);
  }

  &.grey-50 {
    background: var(--black);
  }

  &.grey-100 {
    background: var(--color-grey-100);
  }

  &.grey-200 {
    background: var(--color-grey-200);
  }

  &.grey-300 {
    background: var(--color-grey-300);
  }

  &.grey-400 {
    background: var(--color-grey-400);
  }

  &.grey-500 {
    background: var(--color-grey-500);
  }

  &.grey-600 {
    background: var(--color-grey-600);
  }

  &.grey-700 {
    background: var(--color-grey-700);
  }

  &.grey-800 {
    background: var(--color-grey-800);
  }

  &.grey-900 {
    background: var(--color-grey-900);
  }

  &.primary-50 {
    background: var(--color-blue-50);
  }

  &.primary-100 {
    background: var(--color-blue-100);
  }

  &.primary-200 {
    background: var(--color-blue-200);
  }

  &.primary-300 {
    background: var(--color-blue-300);
  }

  &.primary-400 {
    background: var(--color-blue-400);
  }

  &.primary-500 {
    background: var(--color-blue-500);
  }

  &.primary-600 {
    background: var(--color-blue-600);
  }

  &.primary-700 {
    background: var(--color-blue-700);
  }

  &.primary-800 {
    background: var(--color-blue-800);
  }

  &.primary-900 {
    background: var(--color-blue-900);
  }

  &.secondary-100 {
    background: var(--color-green-100);
  }

  &.secondary-200 {
    background: var(--color-green-200);
  }

  &.secondary-300 {
    background: var(--color-green-300);
  }

  &.secondary-400 {
    background: var(--color-green-400);
  }

  &.secondary-500 {
    background: var(--color-green-500);
  }

  &.secondary-600 {
    background: var(--color-green-600);
  }

  &.secondary-700 {
    background: var(--color-green-700);
  }

  &.secondary-800 {
    background: var(--color-green-800);
  }

  &.tertiary-100 {
    background: var(--color-red-100);
  }

  &.tertiary-200 {
    background: var(--color-red-200);
  }

  &.tertiary-300 {
    background: var(--color-red-300);
  }

  &.tertiary-400 {
    background: var(--color-red-400);
  }

  &.tertiary-500 {
    background: var(--color-red-500);
  }

  &.tertiary-600 {
    background: var(--color-red-600);
  }

  &.tertiary-700 {
    background: var(--color-red-700);
  }

  &.tertiary-800 {
    background: var(--color-red-800);
  }

  &.yellow-100 {
    background: var(--color-yellow-100);
  }

  &.yellow-200 {
    background: var(--color-yellow-200);
  }

  &.yellow-300 {
    background: var(--color-yellow-300);
  }

  &.yellow-400 {
    background: var(--color-yellow-400);
  }

  &.yellow-500 {
    background: var(--color-yellow-500);
  }

  &.yellow-600 {
    background: var(--color-yellow-600);
  }

  &.yellow-700 {
    background: var(--color-yellow-700);
  }

  &.yellow-800 {
    background: var(--color-yellow-800);
  }

  &.purple-100 {
    background: var(--color-purple-100);
  }

  &.purple-200 {
    background: var(--color-purple-200);
  }

  &.purple-300 {
    background: var(--color-purple-300);
  }

  &.purple-400 {
    background: var(--color-purple-400);
  }

  &.purple-500 {
    background: var(--color-purple-500);
  }

  &.purple-600 {
    background: var(--color-purple-600);
  }

  &.purple-700 {
    background: var(--color-purple-700);
  }

  &.purple-800 {
    background: var(--color-purple-800);
  }

  &.pink-100 {
    background: var(--color-pink-100);
  }

  &.pink-200 {
    background: var(--color-pink-200);
  }

  &.pink-300 {
    background: var(--color-pink-300);
  }

  &.pink-400 {
    background: var(--color-pink-400);
  }

  &.pink-500 {
    background: var(--color-pink-500);
  }

  &.pink-600 {
    background: var(--color-pink-500);
  }

  &.pink-700 {
    background: var(--color-pink-700);
  }

  &.pink-800 {
    background: var(--color-pink-800);
  }
}

.zero-button {
  &.default {
    background: var(--color-blue-400);
  }

  &.secondary {
    background: transparent;
  }

  &.disabled {
    background: var(--color-grey-500);
  }

  &.red {
    background: var(--color-red-300);
    border: 1px solid var(--color-red-400);
  }

  &.green {
    background: var(--color-green-300);
    border: 1px solid var(--color-green-400);
  }

  &.gold {
    background: var(--gold);
    border: 1px solid var(--gold);
  }

  &.default-light {
    background: var(--color-blue-900);
  }

  &.red-light {
    background: var(--color-red-800);
  }

  &.green-light {
    background: var(--color-green-800);
  }

  &.grey-light {
    background: var(--color-grey-800);
  }
}

.zero-icon {
  &.default {
    color: var(--color-black);
  }

  &.action {
    color: var(--color-blue-400);
  }

  &.dimmed {
    color: var(--color-grey-500);
  }

  &.white {
    color: var(--white);
  }

  &.red {
    color: var(--color-red-200);
  }

  &.green {
    color: var(--color-green-200);
  }

  &.blue {
    color: var(--color-blue-100);
  }

  &.purple {
    color: var(--color-purple-100);
  }
}

.zero-backdrop {
  &:not(body) {
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
  }

  &.main-backdrop {
    background: var(--color-grey-800);
  }

  &.box-white {
    background: var(--white);
  }

  &.gradient {
    margin: 0;
    background: linear-gradient(
      to top right,
      rgba(255, 0, 168, 0.3) 10%,
      rgba(255, 0, 168, 0) 50%,
      rgba(92, 163, 239, 0) 60%,
      rgba(92, 163, 239, 0.3) 90%
    );
    background-color: #061d3c;
  }

  &.main-dark-blue {
    background: var(--oldblue);
    color: var(--color-grey-400);
  }
}

.zero-strokes {
  &.default {
    background: var(--color-grey-500);
  }
}
