//
// dropdown select component
//
// object:            select/ dropdowns
// object-childs:     select-item (option field)
// object states:     select:hover, select:focus, select:active
// object-variations: select--error
//
$module-name: 'select-dropdown';

/* placeholder only needed on generic dropdown use */
.#{$module-name} ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-grey-300);
}
.#{$module-name} ::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-grey-300);
}
.#{$module-name} :-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-grey-300);
}
.#{$module-name} :-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-grey-300);
}

.#{$module-name} select::-ms-expand {
  display: none;
}

.#{$module-name},
.#{$module-name} > * {
  cursor: pointer;
}

.#{$module-name} {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 0;
  &.dropdown-authdevices {
    min-width: 9rem;
    width: calc(100% + 3rem);
  }

  &::after {
    position: absolute;
    right: 11px;
    top: 2rem;
    content: '';
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 48 48' width='25'%3E%3Cpath d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z' fill='%23949494' fill-opacity='1'/%3E%3Cpath d='M0-.75h48v48h-48z' fill='none'/%3E%3C/svg%3E")
      center no-repeat;
    width: 12px;
    height: 7px;
    margin-top: -10px;

    // leads to disappearance when select is used in container with background.
    // Using pointer-events: none is the solution which is used in material design
    //z-index: -1;

    pointer-events: none;
    display: block;
  }

  &--disabled {
    box-shadow: none;
    cursor: default !important;
    &::after {
      position: absolute;
      right: 11px;
      top: 25px;
      content: '';
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 48 48' width='25'%3E%3Cpath d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z' fill='%23B1B1B1' fill-opacity='1'/%3E%3Cpath d='M0-.75h48v48h-48z' fill='none'/%3E%3C/svg%3E")
        center no-repeat;
      width: 12px;
      height: 7px;
      margin-top: -10px;
      z-index: -1;
      display: block;
    }
  }

  &--disabled select,
  select[disabled='disabled'] {
    border-color: var(--color-grey-300) !important;
    color: var(--color-grey-50);
    pointer-events: none;
    box-shadow: none !important;
    background: var(--color-grey-300) !important;
  }

  &--disabled::after {
    background: none;
  }

  select {
    border: 1px solid rgba(238, 240, 242, 0.7);
    box-sizing: border-box;
    background: rgba(238, 240, 242, 0.7);
    outline: none;
    height: 3rem;
    width: 100%;
    padding: 0rem 1rem;
    user-select: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none;
    border-radius: 6px;
    color: var(--color-grey-50);

    &:disabled {
      cursor: not-allowed;
    }
  }

  option,
  .select-item {
    font-size: em(14px);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &.#{$module-name}--error:focus {
    select {
      border: 1px solid var(--color-red-400);
    }
  }

  &.#{$module-name}--error {
    select {
      border: 1px solid var(--color-red-400);
      &:focus {
        border: solid var(--color-grey-50);
      }
    }
  }
}

/* default state */
//.select-dropdown select,
.select-dropdown option:not(:checked) {
  color: var(--color-grey-50);
} /* or whatever your default style is */

/* checked state */
.select-dropdown option:checked {
  font-weight: bold;
}

/* selected state, set class "selected" on <select>-selector with javascript only possible */
.select-dropdown select.selected {
  color: var(--color-grey-50);
}
