.snackbar {
  position: fixed;
  bottom: 0px;
  right: 15px;
  width: 290px;
  transition: bottom 0.25s cubic-bezier(0, 0, 0.1, 1);
  z-index: 1000;
}

.snackbar-header {
  background: var(--white);
  font-variation-settings: var(--font-medium);
  height: 35px;
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  cursor: pointer;
  padding: 0 8px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 14%);
}

.snackbar-content {
  position: relative;
  background: var(--white);
  padding: 24px 16px 8px 16px;
  font-size: 12px;
  font-variation-settings: var(--font-medium);
  box-shadow: 0 16px 14px 0 rgb(0 0 0 / 14%);

  hr {
    border-color: var(--color-grey-700);
  }
}

.snackbar-close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.snackbar--open {
  bottom: 0;
}
