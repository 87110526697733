.box-info {
  border: 3px solid;
  padding: 1rem;
  &.box-info-secondary {
    color: var(--color-grey-500);
  }
  &.box-info-primary {
    background-color: var(--oldblue);
    color: var(--white);
    border-color: var(--oldblue);
  }
  &.box-info-hollow {
    background-color: transparent;
    border-color: var(--oldblue);
    color: var(--oldblue);
  }

  &.box-info-offering {
    flex-grow: 1;
    min-width: 260px;
    max-width: 340px;
    min-height: 240px;
    margin-bottom: 30px;
  }
}

.hover-box-container {
  position: relative;
  border: 2px solid var(--color-grey-800);

  .hover-box-title,
  .hover-box {
    display: flex;
    opacity: 0;
    position: absolute;
    transition: opacity 300ms ease;
    min-width: 100%;
    min-height: 100%;
  }
  .hover-box {
    background: var(--oldblue);
  }
  .hover-box-title {
    flex-direction: column;
  }

  &:hover {
    .hover-box {
      opacity: 0.9;
    }
    .hover-box-title {
      opacity: 1;
    }
  }
}

.box {
  margin: 0.5rem 0 0 0;
  background-color: var(--color-grey-800);
  padding: 2rem 1rem;
}

.slim-footer {
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: var(--white);
  font-size: 0.6rem;

  &.dark {
    background: var(--oldblue);

    a {
      color: var(--white);
    }
  }

  @media (min-width: $bp-md) {
    font-size: $smaller1-font-size;
  }
}

.sign-up-image {
  z-index: 2;
  position: relative;
  img {
    position: relative;

    @media (min-width: 1500px) {
      bottom: 100px;
    }
  }
}

@media (min-width: 992px) {
  .entry-quote-box .icon {
    opacity: 0;
  }

  .entry-quote-box:hover .icon {
    opacity: 1;
  }
}
