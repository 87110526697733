.tile {
  @extend .zero-backdrop;
  @extend .box-white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: $default-spacing;

  &.inbox {
    padding-top: 0rem;
  }

  &.products {
    .asset-class:hover {
      use {
        color: var(--color-blue-400);
      }
    }
  }

  hr {
    border-color: var(--color-grey-700);

    &.less-margin {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }
}

.row {
  &.same-height {
    .col-lg-4 {
      padding: 0px 15px 0px 15px;
    }

    .tile {
      height: 100%;
      margin-bottom: 0;
    }
  }
}

.leverage-products {
  .leverage {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 45px;
    min-height: 45px;
    cursor: pointer;
    font-variation-settings: var(--font-bold);
    border-radius: 4px;
    background-color: var(--color-grey-800);
    transition: all 0.15s ease-in-out;

    input {
      width: 100%;
      height: 40px;
      padding: 0 15px;
      outline: none;
      border: none;

      &::-webkit-input-placeholder {
        font-size: 10px;
      }
      &::-moz-placeholder {
        font-size: 10px;
      }
      &:-ms-input-placeholder {
        font-size: 10px;
      }
      &:-moz-placeholder {
        font-size: 10px;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.call {
      &.active,
      &:hover {
        color: var(--color-green-400);
        background: color-mix(in srgb, var(--color-green-400) 20%, transparent);
      }
    }

    &.put {
      &.active,
      &:hover {
        color: var(--color-red-400);
        background: color-mix(in srgb, var(--color-red-400) 20%, transparent);
      }
    }

    &.custom {
      border: 1px solid var(--color-grey-50);
      width: 160px;
    }
  }
  .product {
    transition: all 0.2s ease-in-out;
  }
  .product:hover {
    background: var(--color-grey-800);
  }
}

.modal-dialog {
  .modal-content {
    padding: 16px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 12px 24px -5px rgba(0, 0, 0, 0.2);
  }

  &.push-alert-modal {
    .modal-content {
      background: none;
    }
  }
  &.exit-intent {
    .modal-content {
      padding: 0;
    }
  }
}

.disallowed-hint {
  margin: 0.5rem 0;
  color: var(--color-green-300);
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--color-green-300);
  background: var(--color-green-600);
}
