.checkbox {
  display: block;
  width: unset;
  position: relative;
  padding-left: 35px;
  padding-top: 2px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.43;
  letter-spacing: 0.1px;

  color: #000;

  &.bold {
    font-weight: bold;
  }

  &.error {
    color: var(--color-red-400);

    .checkmark {
      border: solid 1px var(--color-red-400);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      border: solid 1px var(--color-blue-400);
      background-color: var(--color-blue-400);
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: rgba(238, 240, 242, 0.7);
    border: solid 1px var(--oldblue);
    border-radius: 6px;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 1px;
      width: 8px;
      height: 16px;
      border: solid var(--white);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
