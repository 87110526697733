/**** Icons ****/
@use 'sass:math';

.icon {
  display: inline-flex;
  &-rotate {
    transform: rotate(180deg);
  }
}
.icon-10 {
  width: 10px;
  height: 10px;
}
.icon-12 {
  width: 12px;
  height: 12px;
}
.icon-14 {
  width: 14px;
  height: 14px;
}
.icon-16 {
  width: 16px;
  height: 16px;
}
.icon-18 {
  width: 18px;
  height: 18px;
}
.icon-21 {
  width: 21px;
  height: 21px;
}
.icon-24 {
  width: 24px;
  height: 24px;
}
.icon-29 {
  width: 29px;
  height: 29px;
}
.icon-32 {
  width: 32px;
  height: 32px;
}
.icon-48 {
  width: 48px;
  height: 48px;
}
.icon-64 {
  width: 64px;
  height: 64px;
}
.icon-70 {
  width: 70px;
  height: 70px;
}
.icon-logo-with-text {
  height: 29px;
  width: 152px;
}
.icon-logo-text-only {
  height: 1rem;
  width: 10rem;
}
.icon-partner-logo {
  height: 60px;
  img {
    height: 100%;
  }
}
.icon-partner-logo-hvb {
  height: 60px;
  img {
    width: 95%;
  }
}

$partner-slider-logo-height: 42px;
.icon-partner-slider-logo-container {
  padding: 0 10px;
}

.icon-partner-slider-logo {
  height: $partner-slider-logo-height;
  &.hsbc {
    width: math.div($partner-slider-logo-height * 244.51, 86.3);
  }
  &.hvb {
    width: math.div($partner-slider-logo-height * 643.29, 86.3);
  }
  &.dws {
    width: math.div($partner-slider-logo-height * 288.65, 86.3);
  }
  &.amundi {
    width: math.div($partner-slider-logo-height * 233.23, 86.3);
  }
  @include media-breakpoint-down(md) {
    &.hsbc {
      width: math.div($partner-slider-logo-height * 0.75 * 244.51, 86.3);
    }
    &.hvb {
      width: math.div($partner-slider-logo-height * 0.75 * 643.29, 86.3);
    }
    &.dws {
      width: math.div($partner-slider-logo-height * 0.75 * 288.65, 86.3);
    }
    &.amundi {
      width: math.div($partner-slider-logo-height * 0.75 * 233.23, 86.3);
    }
  }
}

.icon-active path {
  fill: var(--oldblue);
}
.icon-inactive path {
  fill: var(--color-grey-300);
}
.icon-mobile-table {
  width: 7.5rem;
  height: 1.4rem;
}

.haken {
  background-image: svg-url(
    '<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1213 1.06066L6.06066 13.1213L0 7.06066L1.06066 6L6.06066 11L17.0607 0L18.1213 1.06066Z" fill="#278565"/></svg>'
  );
  background-size: cover;
  background-position: center;
}
.cross {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85457' height='24' width='24'%3E%3Cpath d='M22.245 4.015a.808.808 0 0 1 0 1.139l-6.276 6.27a.81.81 0 0 0 0 1.14l6.273 6.272a.81.81 0 0 1 0 1.14l-2.285 2.277a.814.814 0 0 1-1.142 0l-6.271-6.271a.81.81 0 0 0-1.141 0l-6.276 6.267a.81.81 0 0 1-1.141 0l-2.282-2.28a.81.81 0 0 1 0-1.14l6.278-6.269a.81.81 0 0 0 0-1.14L1.709 5.147a.808.808 0 0 1 0-1.14l2.284-2.278a.813.813 0 0 1 1.142.001L11.405 8a.81.81 0 0 0 1.141.001l6.276-6.267a.812.812 0 0 1 1.141 0l2.282 2.281z'/%3E%3C/svg%3E");

  background-size: cover;
  background-position: center;
}

.icon-home {
  height: 300px;
  &.width100Pct {
    width: 100%;
  }
}
.icon-founder {
  height: 300px;
}

@media screen and (max-width: 399px) {
  .icon-founder .svganimation svg {
    width: 100% !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 576px) {
  .icon-founder {
    margin-bottom: -65px;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .icon-founder {
    margin-bottom: -110px;
  }
}

.icon-myths {
  width: 100%;
  height: 17rem;
}

.thumbnails-gallery {
  max-width: 226px;
  height: 180px;
}
.lightbox {
  display: flex !important;
  flex-direction: column-reverse;
}
.box-shadow {
  box-shadow: 4px 4px 4px 0px var(--color-grey-300);
}

.icon-active {
  color: var(--oldblue);
  fill: none;
  &.icon-filled {
    fill: var(--oldblue);
  }
}
.icon-inactive {
  fill: none;
  &.icon-filled {
    fill: var(--color-grey-300);
  }
}

.icon-light {
  color: var(--white);
  fill: var(--white);
}

.icon-dark {
  color: var(--oldblue);
  fill: var(--oldblue);
}

.icon-secondary {
  color: var(--color-blue-400);
  fill: var(--color-blue-400);

  &.icon-active {
    fill: var(--color-blue-400);
  }

  &.icon-inactive {
    fill: none;
  }
}

.playbutton-primary {
  color: var(--color-grey-500);
}
.playbutton-white {
  color: var(--white);
}

.rot-90 {
  transform: rotate(90deg);
}

.rot-180 {
  transform: rotate(180deg);
}
.rot-270 {
  transform: rotate(270deg);
}

img.blackwhite {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

$gb-icon-size-product-overview: 110px;
.icon-prod-ov {
  width: $gb-icon-size-product-overview;
  height: $gb-icon-size-product-overview;
}

.background-logo {
  background-repeat: no-repeat;
  background-position: 110% 110%;
  background-size: 9rem 9rem;
  background-image: svg-url(
    '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  viewBox="0 0 42 42" style="enable-background:new 0 0 42 42;" xml:space="preserve"> <style type="text/css"> .st0{fill:#FFFFFF; fill-opacity:0.1875;} </style> <g> <g> <path class="st0" d="M41,21c0,11-8.9,20-19.9,20C10,41,1,32.1,1,21C1,10,9.9,1,20.9,1C32,1,40.9,9.9,41,21 M21.1,34.5 c3.2,0,7.3-0.6,9.2-1.1l0-14.9h-7.1v9.6c-0.5,0-1.3,0.1-2.1,0.1c-1.8,0-2.6-0.7-2.6-2.5v-1.9h2v-2.3h-2v-0.8h2v-2.3h-2l0-1.3 c0-2.1,0.9-2.8,3.2-2.8c2.3,0,5.1,0.3,7,0.8l0.8-6.3c-2.7-0.7-5.7-1-8.7-1c-7.2,0-10.2,2.4-10.2,9l0,1.7H8v2.3h2.6v0.8H8v2.3h2.6 l0,2.5C10.6,31.9,13.9,34.5,21.1,34.5"/> </g> </g> </svg>'
  );

  &.panel-header {
    background-position: 80% 50%;
    background-size: 7rem 7rem;
    padding: 0rem 1.25rem;
    min-height: 4rem;
  }
}

.svganimation {
  min-height: 300px;
  min-width: 300px;

  canvas,
  svg {
    width: inherit !important;
    height: 300px !important;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
    width: inherit;
    height: 300.2px;

    canvas,
    svg {
      width: inherit !important;
      height: 300.1px !important;
    }
  }
}

.img-founders {
  max-width: 140%;
}

.icon-kundewerben {
  @extend .icon-prod-ov;
  @media screen and (max-width: 576px) {
    width: 75px;
  }
}

.icon-claim {
  @media screen and (max-width: 400px) {
    width: 90%;
  }
}

.savings-plan {
  .icon {
    image {
      width: 100%;
    }
  }
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.app-icon {
  width: 200px;
  height: 59px;

  &.small {
    width: 150px;
    height: 45px;
  }
}

.icon-footer-logo {
  height: 50px;
  width: 156px;
}

.icon-zero-main {
  width: 148px;
  height: 48px;
}
