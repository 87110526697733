/**** Typography ****/
// TODO: Replace all text-size-classes with responsive font-size-classes
@use 'sass:math';

.text-size-xsmall {
  font-size: 11px;
}

.text-size-small {
  font-size: 12px;
}

.text-size-medium {
  font-size: 13px;
}

.text-size-large {
  font-size: 14px;
}

.text-size-xlarge {
  font-size: 15px;
}

.text-default {
  font-size: 1rem;
}

.text-size-xl {
  font-size: 1.25rem;
}

.text-size-xxl {
  font-size: 28px;
}

.text-size-important {
  font-size: 2rem;
}

.text-size-huge {
  font-size: 64px;
}

.line-height-small {
  line-height: 120%;
}

/**** Text & Typography ****/
.color-tertiary {
  color: var(--white);
}

.text-color-primary {
  color: var(--color-grey-50);
}

.text-primary {
  color: var(--oldblue);
}

.text-disabled {
  color: var(--color-grey-300);
}

label.rised.text-color-success,
.text-color-success {
  color: var(--color-green-400);
}

.text-color-area-ok {
  color: var(--color-green-400);
}

.text-color-error {
  color: var(--color-red-400);
}

.text-color-area-warning {
  color: var(--color-red-400);
}

.text-color-basic {
  color: var(--oldblue);
}

.text-color-secondary {
  color: var(--color-grey-500);
}

.color-secondary {
  color: var(--color-blue-400);
}

.text-color-dark-blue {
  color: var(--oldblue);
}

.text-color-grey {
  color: var(--color-grey-200);
}

.text-color-alert {
  color: var(--color-red-500);
}

.text-align-center {
  text-align: center;
}

// TODO: Replace all occurences with bootstrap class text-center
.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-bold,
.text-bold-medium {
  font-variation-settings: var(--font-bold);
}

.search-highlight {
  background-color: var(--color-blue-400);
}

.text-strike-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

$font-sizes: (
  smaller2: $smaller2-font-size,
  smaller1: $smaller1-font-size,
  base: $base-font-size,
  6: $h6-font-size,
  5: $h5-font-size,
  4: $h4-font-size,
  3: $h3-font-size,
  2: $h2-font-size,
  1: $h1-font-size,
);

/*
  creates font-size-classes for every grid breakpoint and size in $font-sizes.
  E.g: font-size-2, font-size-md-smaller1, font-size-lg-base etc.
 */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in map-keys($font-sizes) {
      .font-size#{$infix}-#{$value} {
        font-size: map-get($font-sizes, $value) !important;
      }
    }
  }
}

$line-height-1: math.div(39, 16);
$line-height-2: math.div(33, 16);
$line-height-3: math.div(29, 16);

.line-height-3 {
  line-height: $line-height-3;
}

.datenschutztext {
  h2 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  h4 {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  h5 {
    margin-top: 1.25rem;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-transform-unset {
  text-transform: initial;
}

.highlighted {
  display: inline-block;
  padding: 2px 4px;
  text-transform: uppercase;
  font-size: 0.5rem;
  white-space: nowrap;
  font-variation-settings: var(--font-medium);

  border-radius: 3px;
  border: solid 1px var(--color-grey-700);

  @media (min-width: 576px) {
    font-size: $smaller2-font-size;
  }
}

.text-no-wrap {
  white-space: nowrap;
}
