/* sorter arrow module */
/* usage: class = "sorter-before" + optional: "sorter-before--down" "sorter-before--active" */
/* usage: class = "sorter-after" + optional: "sorter-after--down" "sorter-after--active" */

.sorter {
  position: relative;
}

.sorter-icon {
  display: inline-block;
  width: 12px;
  height: 1rem;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='sorter-arrow' viewBox='0 0 12 12'%3E%3Cg id='Web'%3E%3Cg id='zero-web-new-color' transform='translate(-406.000000, -1512.000000)'%3E%3Cg id='Group-2' transform='translate(345.000000, 353.000000)'%3E%3Cg id='Group-7' transform='translate(0.000000, 1030.000000)'%3E%3Cg id='ic_sort' transform='translate(60.000000, 130.000000)'%3E%3Cg%3E%3Cpath fill='%239c9c9c' d='M6.999344,11c-0.314744,0-0.628934-0.119271-0.868029-0.358918L3.32237,7.832689 C2.947991,7.457206,2.947991,6.846494,3.321818,6.472115c0.374379-0.374379,0.985643-0.376036,1.360574-0.002209 l1.356709,1.357261L6.036892-0.036996C6.036892-0.568194,6.468698-1,6.999344-1 c0.529541,0,0.961899,0.431253,0.963004,0.960795l0.002209,7.859746l1.349531-1.34953 c0.374378-0.375484,0.985091-0.37714,1.361678,0c0.181115,0.180011,0.281612,0.421866,0.282717,0.679183 c0,0.257317-0.100497,0.500276-0.282717,0.682496l-2.808945,2.808393C7.627727,10.880729,7.313535,11,6.999344,11z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  transform: rotate(180deg);
}

.sorter-icon-flat {
  display: inline-block;
  width: 12px;
  height: 0.5rem;
  content: '';
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='none' stroke-width='9' stroke='%235ca3ef'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  transition: all 0.2s linear;
}

.disabled {
  .sorter-icon-flat {
    cursor: not-allowed;
  }
}

.sorter-icon--active {
  display: inline-block;

  width: 12px;
  height: 1rem;
  content: '';
  background-repeat: no-repeat;
  transition: all 0.2s linear;
}

.sorter-after {
  &:after {
    display: inline-block;
    content: '';
    cursor: pointer;
    @extend .sorter-icon;
    width: 12px;
    height: 12px;
  }
  &.sorter-after--active:after {
    @extend .sorter-icon--active;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='sorter-arrow' viewBox='0 0 12 12'%3E%3Cg id='Web'%3E%3Cg id='zero-web-new-color' transform='translate(-406.000000, -1512.000000)'%3E%3Cg id='Group-2' transform='translate(345.000000, 353.000000)'%3E%3Cg id='Group-7' transform='translate(0.000000, 1030.000000)'%3E%3Cg id='ic_sort' transform='translate(60.000000, 130.000000)'%3E%3Cg%3E%3Cpath fill='%235ca3ef' d='M6.999344,11c-0.314744,0-0.628934-0.119271-0.868029-0.358918L3.32237,7.832689 C2.947991,7.457206,2.947991,6.846494,3.321818,6.472115c0.374379-0.374379,0.985643-0.376036,1.360574-0.002209 l1.356709,1.357261L6.036892-0.036996C6.036892-0.568194,6.468698-1,6.999344-1 c0.529541,0,0.961899,0.431253,0.963004,0.960795l0.002209,7.859746l1.349531-1.34953 c0.374378-0.375484,0.985091-0.37714,1.361678,0c0.181115,0.180011,0.281612,0.421866,0.282717,0.679183 c0,0.257317-0.100497,0.500276-0.282717,0.682496l-2.808945,2.808393C7.627727,10.880729,7.313535,11,6.999344,11z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  &--down:after {
    transform: rotate(360deg);
  }
}

.sorter-after-flat {
  &:after {
    display: inline-block;
    content: '';
    cursor: pointer;
    @extend .sorter-icon-flat;
    width: 12px;
    height: 12px;
  }
  &:after.sorter-after--active {
    @extend .sorter-icon--active;
  }
  &--down:after {
    transform: rotate(180deg);
  }
}

.sorter-before {
  &:before {
    display: inline-block;
    content: '';
    cursor: pointer;
    @extend .sorter-icon;
    width: 12px;
    height: 12px;
  }
  &.sorter-before--active:before {
    @extend .sorter-icon--active;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='sorter-arrow' viewBox='0 0 12 12'%3E%3Cg id='Web'%3E%3Cg id='zero-web-new-color' transform='translate(-406.000000, -1512.000000)'%3E%3Cg id='Group-2' transform='translate(345.000000, 353.000000)'%3E%3Cg id='Group-7' transform='translate(0.000000, 1030.000000)'%3E%3Cg id='ic_sort' transform='translate(60.000000, 130.000000)'%3E%3Cg%3E%3Cpath fill='%235ca3ef' d='M6.999344,11c-0.314744,0-0.628934-0.119271-0.868029-0.358918L3.32237,7.832689 C2.947991,7.457206,2.947991,6.846494,3.321818,6.472115c0.374379-0.374379,0.985643-0.376036,1.360574-0.002209 l1.356709,1.357261L6.036892-0.036996C6.036892-0.568194,6.468698-1,6.999344-1 c0.529541,0,0.961899,0.431253,0.963004,0.960795l0.002209,7.859746l1.349531-1.34953 c0.374378-0.375484,0.985091-0.37714,1.361678,0c0.181115,0.180011,0.281612,0.421866,0.282717,0.679183 c0,0.257317-0.100497,0.500276-0.282717,0.682496l-2.808945,2.808393C7.627727,10.880729,7.313535,11,6.999344,11z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  &--down:before {
    transform: rotate(360deg);
  }

  &--active {
    white-space: nowrap;
  }
}

.sorter-big,
.sorter-big {
  &:after,
  &:before {
    width: 24px !important;
    height: 12px !important;
    margin-bottom: 6px;
  }
  &.sorter-align-center {
    margin-bottom: -2px;
  }
}
