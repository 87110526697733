/**** material design input/textarea/dropdown ****/
/*************************************************/

/*
  Spin-Buttons in Chrome deaktivieren
 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type='number'] {
  -moz-appearance: textfield;
}

/**
 verhindert einen roten Rand im Firefox um input-Felder
 */
input:required {
  box-shadow: none;
}

$font-size-input: $base-font-size;
$font-size-input-small: $smaller1-font-size;
$font-size-input-message: $smaller2-font-size;
$font-size-input-label: $base-font-size;
$font-size-input-small-label: $smaller1-font-size;
$font-size-input-label-lifted: $smaller2-font-size;
$font-size-input-small-label-lifted: $smaller2-font-size;
$font-size-typeahed: $smaller1-font-size;

/* SASS module start input-group-material */
/******************************************/

.input-group-material {
  position: relative;
  width: 100%;
  &.input-group-right {
    .input-group-material-icon {
      right: 0;
      top: 1rem;
      @media (min-width: 992px) {
        top: 0.5rem;
      }
    }
  }
  &.asset-search-header {
    width: 100%;
  }

  .inbox-search {
    input {
      border: none;
    }
  }

  input,
  textarea {
    border: 1px solid var(--color-grey-500);
    border-radius: 4px;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    font-variation-settings: var(--font-regular);
    letter-spacing: 0.11px;
    line-height: normal;
    padding: 1rem 0;
    text-indent: 1rem;
    transition: border 0.3s ease-in-out;
    width: 100%;
    outline: none;
    height: 3rem;

    &::placeholder {
      color: var(--color-grey-50)-lighter;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px var(--white) inset;
      -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
    }

    &:-webkit-autofill::first-line {
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      font-variation-settings: var(--font-regular);
      line-height: normal;
    }

    &.asset-search-input {
      background: none;
      color: gray;
      font-size: $font-size-input;
      width: 100%;
    }

    &.date {
      color: var(--color-grey-500);
    }
  }

  &.input-group-material-small.input-group-material--light > label.asset-search-header-label {
    text-transform: none;
    font-size: rem(14px);

    @media (min-width: 1500px) {
      font-size: rem(20px);
    }
  }

  &.input-group-material-small.input-group-material--light input:focus ~ label.asset-search-header-label {
    font-size: $font-size-input-small-label-lifted;
  }

  textarea {
    height: 6rem;
    padding: 1rem 1rem;
    text-indent: 0;

    &.expanded {
      height: 12rem;
    }
  }

  label {
    color: var(--color-grey-50);
    font-size: $font-size-input-label-lifted;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    top: -$font-size-input-label-lifted * 1.6;
    left: 0;
    transition: 200ms ease all;
    .label-required {
      font-size: $smaller2-font-size;
    }
  }

  input:not(.empty).ng-valid ~ label,
  textarea:not(.empty).ng-valid ~ label {
    color: var(--oldblue);
  }

  input:not(.empty) ~ label,
  textarea:not(.empty) ~ label,
  web-components-phone-input:not(.empty) ~ label,
  textarea:not(.empty).ng-valid ~ label,
  textarea:not(.empty).ng-invalid ~ label,
  input:focus ~ label,
  textarea:focus ~ label,
  select:focus ~ label,
  select:not(.empty) ~ label,
  select.ng-invalid:not(.ng-pristine) ~ label {
    .label-required:not(.no-fade) {
      opacity: 0;
    }
  }

  .input-group-material-icon {
    display: inline-block;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    color: var(--color-grey-500);

    &.top-space {
      top: 1rem;
    }

    ~ label {
      font-size: $font-size-input-label;
    }
    ~ input {
      color: var(--color-grey-500);
      padding-left: 22px;
    }
    .icon {
      width: 14px;
      height: 14px;
      fill: var(--color-grey-500);
    }
  }

  .input-group-material-icon-right {
    position: absolute;
    top: 2px;
    right: 0;
    width: 20px;
    color: var(--color-grey-500);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-group-material-message {
    visibility: visible;
    font-size: $font-size-input-message;
    padding-top: 2px;
    margin-bottom: 4px;
    color: var(--color-grey-50);
  }

  .input-group-material-icon-state {
    display: block;
    position: absolute;
    right: 0px;
    top: -13px;
    height: 15px;
    width: 15px;
  }

  /* additional classes on input-group-material */
  &.input-group-material-message-hidden .input-group-material-message {
    visibility: hidden !important;
  }

  &.input-group-material--centered {
    text-align: center;
    input {
      text-align: center;
      padding-left: 22px;
    }
    label {
      width: 100%;
      //padding-right: 22px;
    }
  }

  /* light version */
  &.input-group-material--light {
    input,
    textarea {
      border-bottom-color: var(--color-grey-800);
      color: var(--white);
    }
    input ~ label,
    input:focus ~ label,
    &.keepcolor input.ng-valid ~ label,
    &.keepcolor input:not(.empty).ng-valid ~ label,
    &.keepcolor input:focus.ng-valid ~ label {
      color: var(--color-grey-800);
    }

    input:not(.empty).ng-valid {
      & ~ label {
        color: var(--color-green-400);
      }
    }
  }

  /* small version */
  &.input-group-material-small label {
    font-size: $font-size-input-small;
  }
  &.input-group-material-small input {
    font-size: $font-size-input-small;
    height: $font-size-input-small * 2;
  }
  &.input-group-material-small input:focus ~ label,
  &.input-group-material-small input:not(.empty).ng-valid ~ label,
  &.input-group-material-small textarea:focus ~ label,
  &.input-group-material-small textarea:not(.empty).ng-valid ~ label {
    top: -$font-size-input-small-label-lifted;
    font-size: $font-size-input-small-label-lifted;
  }
  &.input-group-material-small .input-group-material-icon {
    display: inline-flex;
    position: absolute;
    top: 0.5rem;
  }
  &.input-group-material-small .input-group-material-icon .icon {
    width: 14px;
    height: 14px;
  }
  &.input-group-material-small input:not(.ng-pristine).ng-valid ~ .input-group-material-icon-state:after {
    width: 12px;
    height: 12px;
  }
  &.input-group-material-small .input-group-material-icon ~ input {
    padding-left: 18px;
    font-size: $font-size-input-small;
    &.asset-search-input {
      padding-left: 2px;
    }
  }
  &.input-group-material-small .input-group-material-icon ~ label {
    font-size: $font-size-input-small;
    top: 4px;
    @media (min-width: 1500px) {
      top: -2px;
    }
  }
  /* error state for small version */
  &.input-group-material-small input-group-material--error input ~ .input-group-material-icon-state:after,
  &.input-group-material-small input:not(.ng-pristine).ng-invalid ~ .input-group-material-icon-state:after {
    width: 12px;
    height: 12px;
  }
  /* small version end */

  /* input variation as dropdown */
  &.input-group-material-dropdown input {
    color: transparent;
    text-shadow: 0 0 0 gray;
    cursor: pointer;
  }
  &.input-group-material-dropdown .input-group-material-icon-state {
    top: 0;
  }
  &.input-group-material-with-icon input {
    padding-left: 20px !important;
  }

  &:not(.keepcolor) {
    .input-group-material--valid input:not(.empty),
    input:not(.ng-pristine).ng-valid {
      border-color: var(--color-green-400);
    }
    & ~ .input-group-material-icon-state::after {
      display: block;
      justify-content: flex-end;
      width: 15px;
      height: 15px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='533.973' fill='%2363cb82' height='533.973'%3E%3Cpath d='M477.931 52.261c-12.821-12.821-33.605-12.821-46.427 0l-266.96 266.954-62.075-62.069c-12.821-12.821-33.604-12.821-46.426 0L9.616 303.572c-12.821 12.821-12.821 33.604 0 46.426l85.289 85.289 46.426 46.426c12.821 12.821 33.611 12.821 46.426 0l46.426-46.426 290.173-290.174c12.821-12.821 12.821-33.605 0-46.426l-46.425-46.426z'/%3E%3C/svg%3E");
      background-size: cover;
      background-position: center center;
      position: absolute;
      z-index: 5;
      right: 0.75rem;
      top: 30px;
      content: '';
    }
  }
}

/* input group material liste dropdown */
/* todo: needed? and refactor */
/***************************************/
.input-group-material-liste,
web-components-asset-search ngb-typeahead-window.dropdown-menu {
  position: absolute;
  background: var(--white);
  font-size: $font-size-typeahed;
  border: none;
  z-index: 5;
  box-shadow: 0px 12px 24px 8px rgb(132 132 132 / 35%);
  display: none;
  margin: 0px;
  padding: 0px;
  border-radius: unset;
}

web-components-asset-search .asset-search-multi-order ngb-typeahead-window.dropdown-menu {
  position: relative !important;
  box-shadow: none;
  max-height: 350px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: none !important;
  margin: 1rem -1rem 1rem 0rem;
}

.input-group-material input:focus ~ .input-group-material-liste {
  display: block;
}

.input-group-material-liste-item {
  width: 100%;
  border: none;
  background: transparent;
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--color-grey-800);

  &.autocompletionempty {
    white-space: initial;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.input-group-material-liste > .input-group-material-liste-item:hover,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item .input-group-material-liste-item:hover,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item.active .input-group-material-liste-item,
.input-group-material-liste > .input-group-material-liste-item:focus,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item .input-group-material-liste-item:focus {
  background: var(--color-grey-800);
  cursor: pointer;
}

tickets-zendesk-faq-search .dropdown-item.active {
  background: var(--color-grey-800);
  color: var(--color-grey-50);
}

web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item:hover,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item:focus {
  outline: none;
}

web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item.active {
  color: unset;
  background-color: unset;
}

.input-group-material-liste-headline,
.dropdown-item .input-group-material-liste-headline {
  font-size: 0.75rem;
  letter-spacing: 0.09px;
  background: var(--color-grey-800);
  padding: 0.5rem 1rem;
  color: var(--color-grey-50);

  @media screen and (min-width: 767px) {
    min-width: 450px;
  }
}
/*** input group material liste end ***/

/*** DEFAULT STATE for dropdown with icon after ***/
/******************************************************/

.input-group-material.input-group-material-dropdown input:not(.ng-valid) ~ .input-group-material-icon-state:after,
.input-group-material.input-group-material-dropdown input:not(.ng-invalid) ~ .input-group-material-icon-state:after {
  position: absolute;
  right: 11px;
  top: 22px;
  content: '';
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 48 48' width='25'%3E%3Cpath d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z' fill='%23949494' fill-opacity='1'/%3E%3Cpath d='M0-.75h48v48h-48z' fill='none'/%3E%3C/svg%3E")
    center no-repeat;
  width: 12px;
  height: 7px;
  margin-top: -10px;
  z-index: -1;
  display: block;
}

/*** VALID STATE for input/dropdown with icon after ***/
/******************************************************/

.input-group-material:not(.keepcolor):not(.input-group-material--error) input:not(.empty).ng-valid ~ label,
.input-group-material:not(.keepcolor):not(.input-group-material--error) web-components-number-input.ng-valid input:not(.empty) ~ label,
.input-group-material:not(.keepcolor):not(.input-group-material--error) web-components-phone-input:not(.empty).ng-valid ~ label,
.input-group-material:not(.keepcolor):not(.input-group-material--error) select:not(.empty).ng-valid ~ label,
.input-group-material.input-group-material--valid input:not(.empty) ~ label,
.input-group-material.input-group-material--valid select:not(.empty) ~ label,
.input-group-material.input-group-material--valid input:focus ~ label,
.input-group-material.input-group-material--valid select:focus ~ label {
  color: var(--color-green-400);
}

.input-group-material.input-group-material--valid .input-group-material-message,
.input-group-material:not(.input-group-material--error) input:not(.ng-pristine).ng-valid ~ .input-group-material-message {
  visibility: visible;
  color: var(--color-green-400) !important;
}

/* valid state for small version */
.input-group-material.input-group-material-small input-group-material--valid input:not(.empty) ~ .input-group-material-icon-state:after,
.input-group-material.input-group-material-small input:not(.ng-pristine).ng-valid ~ .input-group-material-icon-state:after {
  width: 12px;
  height: 12px;
}

/*** ERROR STATE for input/dropdown with icon after ***/
/******************************************************/

.input-group-material.input-group-material--error input ~ .input-group-material-icon-state:after,
.input-group-material input:not(.ng-pristine).ng-invalid ~ .input-group-material-icon-state:after {
  display: inline-flex;
  justify-content: flex-end;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85457' height='24' width='24'%3E%3Cpath d='M22.245 4.015a.808.808 0 0 1 0 1.139l-6.276 6.27a.81.81 0 0 0 0 1.14l6.273 6.272a.81.81 0 0 1 0 1.14l-2.285 2.277a.814.814 0 0 1-1.142 0l-6.271-6.271a.81.81 0 0 0-1.141 0l-6.276 6.267a.81.81 0 0 1-1.141 0l-2.282-2.28a.81.81 0 0 1 0-1.14l6.278-6.269a.81.81 0 0 0 0-1.14L1.709 5.147a.808.808 0 0 1 0-1.14l2.284-2.278a.813.813 0 0 1 1.142.001L11.405 8a.81.81 0 0 0 1.141.001l6.276-6.267a.812.812 0 0 1 1.141 0l2.282 2.281z'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 5;
  right: 0.75rem;
  top: 30px;
  content: '';
}

.input-group-material input:not(.ng-pristine).ng-invalid,
.input-group-material web-components-phone-input:not(.ng-pristine).ng-invalid,
.input-group-material.input-group-material--error input,
.input-group-material.input-group-material--error input:focus,
.input-group-material select:not(.ng-pristine).ng-invalid,
.input-group-material.input-group-material--error select,
.input-group-material.input-group-material--error select:focus {
  border-color: var(--color-red-400);
  & ~ label {
    color: var(--color-red-400);
  }
}

.input-group-material.input-group-material--error .input-group-material-message,
.input-group-material input:not(.ng-pristine).ng-invalid ~ .input-group-material-message,
.input-group-material web-components-phone-input:not(.ng-pristine).ng-invalid ~ .input-group-material-message,
.input-group-material select:not(.ng-pristine).ng-invalid ~ .input-group-material-message {
  visibility: visible;
  color: var(--color-red-400) !important;
}

.input-group-material select:not(.ng-pristine).ng-invalid,
.input-group-material select:not(.ng-pristine).ng-invalid:focus,
.input-group-material.input-group-material--error select,
.input-group-material.input-group-material--error select:focus {
  border-bottom-color: var(--color-red-400);
}

/***********************************/
/******** WARNING, NOT ERROR ******/
/***********************************/
.input-group-material-message.text-color-primary {
  color: var(--color-blue-400) !important;
}

/************************************************/
/**** experimental material 2               *****/
/************************************************/

.input-group-material-bordered input {
  border: 1px solid var(--color-grey-500);
  padding: 3px;
  height: 38px;
}

.input-group-material-bordered input ~ label {
  top: 7px;
  left: 7px;
}

.input-group-material-bordered input:focus ~ label,
.input-group-material-bordered input.ng-valid ~ label {
  left: 7px;
  padding: 0 2px;
  background: var(--white);
  top: -8px;
}

.input-group-material-bordered .input-group-material-icon-right {
  top: 6px;
  right: 6px;
}

.input-group-material-bordered.input-group-material--error:after {
  content: '';
}

// für watchlist
.watchlist .input-group-material.input-group-material--light input {
  color: var(--oldblue);
  font-size: $font-size-input;
  height: 2rem;
}
.watchlist {
  .input-group-material {
    .input-group-material-icon {
      .icon {
        width: 20px;
        height: 20px;
        position: relative;
      }

      ~ label {
        top: 0.75rem;
        left: 2.5rem;
      }
    }

    input:focus ~ label,
    input:not(.empty) ~ label {
      font-size: 0.75rem;
      top: -1rem;
      left: 0;
    }

    &.input-group-material-small .input-group-material-icon .icon {
      width: 14px !important;
      height: 14px !important;
    }
    &.input-group-material-small .input-group-material-icon ~ label {
      left: 24px;
      font-size: 1rem;
    }
    &.input-group-material-small input {
      padding-left: 22px;
    }
  }
}

.order-mask {
  .optional {
    .link-secondary {
      @media screen and (max-width: 767px) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .exchange,
  .cash .label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: var(--color-grey-50);
  }

  .cash {
    .value {
      font-size: 1.75rem;
      font-variation-settings: var(--font-medium);
      color: var(--color-grey-50);
      text-align: right;
    }

    .label {
      text-align: right;
    }
  }

  .summary {
    .value {
      font-size: 28px;
      font-variation-settings: var(--font-bold);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #090a0c;
    }

    .cash {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.09px;
      text-align: right;
      color: #090a0c;
    }
  }
}

.asset-search {
  .input-group-material {
    .input-group-material-icon {
      .icon {
        width: 20px;
        height: 20px;
        position: relative;
        color: var(--color-grey-500);
      }

      ~ label {
        font-size: 0.75rem;
        left: 0rem;
      }

      right: 1rem;
    }

    input {
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 0.5rem 0;
      }

      &::placeholder {
        color: var(--color-grey-500)-lighter;
      }
    }
  }
}

.input-group-material.asset-search-default {
  .input-group-material-icon {
    top: 0.75rem;
    right: 0.5rem;
  }

  input {
    border: 1px solid #02172d;
    text-indent: 0;
    padding-left: 1rem;
    height: 3rem;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.savings-plan,
.leverage-products {
  .savings-plan-search,
  .underlying-search {
    .input-group-material {
      .input-group-material-icon {
        .icon {
          width: 20px;
          height: 20px;
          position: relative;
          top: 0.2rem;
        }

        ~ label {
          font-size: 0.75rem;
          left: 0rem;
        }
      }

      input {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
  }

  .btn {
    &.fit {
      text-transform: initial;
    }
    &:not(.fit) {
      width: 100%;
      height: 48px;
      white-space: nowrap;
    }
  }
}

.inbox {
  .input-group-material {
    .input-group-material-icon {
      .icon {
        width: 20px;
        height: 20px;
        position: relative;
      }

      ~ label {
        font-size: 0.75rem;
        top: -1.2rem;
        left: 0;
      }
    }

    input {
      padding-left: 0;
      padding-right: 0;
      &.date {
        background-color: rgba(238, 240, 242, 0.7);
        text-indent: 1rem;
      }
    }
  }
}

.mobile-search {
  input {
    text-indent: 0;
  }
}

.not-allowed {
  cursor: not-allowed;
}

.force-word-break {
  word-break: break-word;
}
.auth {
  .input-group-material input:not(.ng-pristine).ng-invalid ~ .input-group-material-message {
    color: #fff !important;
  }
  input {
    &::placeholder {
      color: var(--color-grey-50);
    }
  }
}

.onboarding {
  input {
    background: rgba(238, 240, 242, 0.7);
    border: 1px solid rgba(238, 240, 242, 0.7);

    &:focus {
      border: 1px solid var(--color-grey-50);
    }

    &::placeholder {
      color: var(--color-grey-50);
    }
  }

  label,
  input:not(:focus).ng-valid.empty ~ label {
    transition: 0.25s ease all;
    top: 13px;
    left: 16px;
    font-size: 1rem;
  }

  .input-group-material:not(.keepcolor):not(.input-group-material--error) select:not(.empty).ng-valid ~ label,
  .input-group-material:not(.keepcolor):not(.input-group-material--error) input:not(.empty).ng-valid ~ label,
  .input-group-material.input-group-material--error input ~ label,
  .input-group-material select:not(.ng-pristine).ng-invalid ~ label,
  .input-group-material.input-group-material--error select ~ label,
  .input-group-material input:not(.ng-pristine).ng-invalid ~ label,
  input:valid ~ label,
  input:focus ~ label,
  web-components-phone-input ~ label,
  input:disabled ~ label {
    font-size: 0.75rem;
    top: -1.2rem;
    left: 0;
  }
}

.kryptoonboarding {
  input {
    background: rgba(238, 240, 242, 0.7);
    border: 1px solid rgba(238, 240, 242, 0.7);

    &:focus {
      border: 1px solid var(--color-grey-50);
    }

    &::placeholder {
      color: var(--color-grey-50);
    }
    &:disabled {
      //color: var(--color-grey-300);
      color: #7b8593; // lt. zeplin ist es diese Farbe
    }
  }

  input:disabled ~ label {
    color: #7f7f7f;
  }
}
