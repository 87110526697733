:root {
  --oldblue: #061d3c;
  --black: #000;
  --white: #fff;
  --gold: #d3c398;
  --color-grey-50: #000;
  --color-grey-100: #111112;
  --color-grey-200: #232425;
  --color-grey-300: #3a3c3d;
  --color-grey-400: #777f89;
  --color-grey-500: #abb5bf;
  --color-grey-600: #c5cbd1;
  --color-grey-700: #dfe3e7;
  --color-grey-800: #f3f5f6;

  --color-blue-50: #144e8c;
  --color-blue-100: #1963b3;
  --color-blue-200: #1f79d9;
  --color-blue-300: #308df0;
  --color-blue-400: #248eff;
  --color-blue-500: #47a0ff;
  --color-blue-600: #70b5ff;
  --color-blue-700: #91c6ff;
  --color-blue-800: #bdddff;
  --color-blue-900: #dcedff;

  --color-green-100: #1e694f;
  --color-green-200: #278565;
  --color-green-300: #2fa27a;
  --color-green-400: #34b287;
  --color-green-500: #37be90;
  --color-green-600: #55c8a1;
  --color-green-700: #73d1b1;
  --color-green-800: #91dbc2;

  --color-red-100: #8a2631;
  --color-red-200: #af303f;
  --color-red-300: #d53b4d;
  --color-red-400: #fa455a;
  --color-red-500: #fa5165;
  --color-red-600: #fb6b7c;
  --color-red-700: #fc8593;
  --color-red-800: #fc9faa;

  --color-yellow-100: #8c6d1e;
  --color-yellow-200: #b38b26;
  --color-yellow-300: #d9a92e;
  --color-yellow-400: #ffc736;
  --color-yellow-500: #ffc839;
  --color-yellow-600: #ffcf54;
  --color-yellow-700: #ffd872;
  --color-yellow-800: #ffe090;

  --color-purple-100: #5e378f;
  --color-purple-200: #7947b8;
  --color-purple-300: #9659e6;
  --color-purple-400: #a763ff;
  --color-purple-500: #b072ff;
  --color-purple-600: #b982ff;
  --color-purple-700: #c292ff;
  --color-purple-800: #d0aaff;

  --color-pink-100: #8f4187;
  --color-pink-200: #b854ae;
  --color-pink-300: #e668d9;
  --color-pink-400: #ff74f1;
  --color-pink-500: #ff82f3;
  --color-pink-600: #ff90f4;
  --color-pink-700: #ff9ef5;
  --color-pink-800: #ffb4f8;
}
