@use 'apps/web-app/src/sass/design-system/_theme.scss';

.btn {
  font-weight: unset;
  font-variation-settings: var(--font-regular);

  &.fit {
    width: unset;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &.btn-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--color-blue-400);

    &.active {
      background: var(--color-blue-400);
      color: var(--white);
    }
  }

  &.btn-sm {
    line-height: 1;
  }
}

button[aria-label='Close']:focus {
  outline: none;
}

.trade {
  width: 40px;
  height: 40px;
  &.small {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue-400);
  border-radius: 3px;

  border: none;
  outline: none;
  transition: all 0.15s ease-in-out;

  &.buy,
  &.sell,
  &.savings-plan,
  &.info {
    &:hover {
      &:not(:disabled) {
        filter: brightness(85%);
      }
    }
  }

  &.buy {
    background: color-mix(in srgb, var(--color-green-400) 20%, transparent);
    color: var(--color-green-400);
    margin-left: 5px;
  }

  &.sell {
    background: color-mix(in srgb, var(--color-red-400) 20%, transparent);
    color: var(--color-red-400);
    margin-left: 5px;
  }

  &.savings-plan,
  &.info {
    background: #47a0ff33;
  }

  &.instrument-detail-header,
  &.more {
    box-shadow: none;
  }

  &.instrument-detail-header {
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    text-transform: uppercase;

    &.savings-plan {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.buy {
      border: 1px solid var(--color-green-400);
      background: #cbecd8;
      color: var(--color-green-400);
    }

    &.sell {
      border: 1px solid var(--color-red-400);
      background: #f4cece;
      color: var(--color-red-400);
    }

    &.buy,
    &.sell {
      &:disabled {
        border: 1px solid color-mix(in srgb, var(--color-grey-700) 40%, transparent);
        color: var(--color-grey-500);
        background: color-mix(in srgb, var(--color-grey-700) 40%, transparent);
        cursor: not-allowed;
      }
    }

    .quote {
      font-size: 11px;
      font-variation-settings: var(--font-regular);
      position: relative;
      top: -3px;
    }
  }

  &.more {
    width: unset;
    font-size: $smaller2-font-size;
  }

  &.search {
    min-width: 24px;
    height: 24px;
    box-shadow: none;
  }
}

.further {
  height: 48px;
  width: 48px;
  border-radius: 6px;
  background-color: rgba(238, 240, 242, 0.7);
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;

  &.active {
    cursor: pointer;
  }
}

.detract-attention {
  .btn-success {
    background: color-mix(in srgb, var(--color-grey-300) 20%, transparent);

    &:hover,
    &:focus,
    &:active {
      background: color-mix(in srgb, var(--color-grey-300) 20%, transparent);
    }
  }
}

.btn-success {
  display: block;
  border-radius: 4px;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--color-green-400);
  background: color-mix(in srgb, var(--color-green-400) 20%, transparent);

  @media (min-width: 768px) {
    width: 200px;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--color-green-400);
    background: color-mix(in srgb, var(--color-green-400) 20%, transparent);
  }
}

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: var(--color-blue-900);
  transition: all 0.15s ease-in-out;

  &.disabled {
    cursor: not-allowed;
    background: color-mix(in srgb, var(--color-blue-400) 5%, transparent);
  }

  .icon:not(.icon-inactive) {
    color: var(--color-blue-400);
  }

  .icon-inactive {
    color: color-mix(in srgb, var(--color-blue-400) 10%, transparent);
  }

  &:hover:not(:has(.icon-inactive)) {
    filter: brightness(95%);
  }
}

.btn-icon,
.trade {
  @media (min-width: 768px) and (max-width: 1199px) {
    width: 30px;
    height: 30px;
  }
}

.full-width-on-small-devices button {
  display: inline-block;
  width: 100%;

  @media (min-width: 576px) {
    width: 320px;
  }
}

// this is only for user notes
.zero-user-note-button {
  @extend .zero-button;
  @extend .zero-text;
  @extend .default;
  @extend .btn;

  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 11px 16px;

  &:hover {
    color: var(--white);
  }
}
