@keyframes delayedUiBlock {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

.loading-indicator {
  width: 100%;
  height: 100%;
  background: color-mix(in srgb, var(--white) 70%, transparent);
  z-index: 9;
  position: absolute;
  top: 0px;
  left: 0px;
  animation: delayedUiBlock 0.25s;
  margin-left: auto;

  &.no-delay {
    animation: none;
  }

  .description {
    position: absolute;
    top: 52%;
    font-size: 18px;
    color: var(--color-blue-400);
    font-variation-settings: var(--font-semi-bold);
  }
}

.auth {
  .loading-indicator {
    background: color-mix(in srgb, var(--color-grey-50) 70%, transparent);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--color-blue-400);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
