@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-VariableFont_wght.ttf') format('truetype');
}

:root {
  --font-light: 'wght' 300;
  --font-regular: 'wght' 400;
  --font-medium: 'wght' 500;
  --font-bold: 'wght' 600;
}

.font-light {
  font-variation-settings: var(--font-light);
}

.font-regular {
  font-variation-settings: var(--font-regular);
}

.font-medium {
  font-variation-settings: var(--font-medium);
}

.font-bold {
  font-variation-settings: var(--font-bold);
}
