/**** icon with tooltip ****/
.icon-tooltip-wrapper {
  position: relative;
  top: 2px;
  overflow: visible;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
}

.icon-tooltip-content {
  border: 1px solid var(--color-grey-800);
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  padding: 20px 10px 10px 10px;
  left: 0;
  background: var(--white);
  color: #333;
  width: 240px;
  height: 140px;
  display: none;
  z-index: 8;
  box-shadow: 1px 1px 11px 0px var(--color-grey-500);
  transition: opacity 0.25s linear;
  opacity: 0;
  font-size: $smaller2-font-size;

  &.autoheight {
    height: auto;
  }

  &.left-aligned {
    left: unset;
    right: 0;
  }
}

.icon-tooltip-close {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  cursor: pointer;
}

.icon-tooltip-content--show {
  display: block;
  opacity: 1;
}

.text-underline-dotted {
  text-decoration: underline dotted;
  cursor: pointer;
}

ngb-tooltip-window {
  &.tooltip {
    &.show {
      opacity: 1;
    }

    &.gb-tooltip {
      .tooltip-inner {
        background: var(--color-grey-800);
        box-shadow: 1px 1px 5px 0px var(--color-grey-500);
        color: $body-color;
      }

      .arrow::before {
        border-top-color: var(--color-grey-500);
      }
    }

    &.zero-tooltip {
      .tooltip-inner {
        background: var(--white);
        border-radius: 4px;
        box-shadow: 0 0px 12px 0 rgba(73, 74, 74, 0.32);
        color: var(--color-grey-400);
        padding: 4px 8px;
      }

      .arrow::before {
        border-top-color: var(--white);
        border-bottom-color: var(--white);
      }
    }

    &.without-arrow {
      .tooltip-inner {
        max-width: 400px;
        padding: 16px;
        background: white;
        border-radius: 8px;
        box-shadow: 0px 12px 24px -5px rgba(0, 0, 0, 0.2);
        color: $body-color;
      }

      .arrow::before {
        opacity: 0;
        border-bottom-color: var(--color-grey-500);
      }
    }
  }
}
