* {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: $base-font-size;
  font-variant-numeric: tabular-nums;
  overflow-y: scroll;
  position: relative;

  &.dark.zero-backdrop.main-backdrop {
    background: var(--oldblue);
    h1,
    button {
      color: var(--white);
    }
    footer {
      color: var(--white);
      a,
      svg {
        color: var(--color-grey-500);
      }
    }
  }
}

/*** Make content fill screen so that footer is at bottom of viewport ***/
zero-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

caption {
  display: none;
}

router-outlet + * {
  flex: 1;
}

strong {
  font-variation-settings: var(--font-bold);
  font-weight: inherit;
}

blockquote {
  padding: 1rem;
  background: var(--color-grey-800);
}

h5.anchor {
  position: relative;
  span {
    position: absolute;
    top: -150px;
  }
}

a:hover,
a:focus {
  text-decoration: none;
}

.no-focus-outline:focus {
  outline: none;
}
.no-selection::selection {
  background: none;
}
input,
textarea {
  color: $input-color;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-moz-selection {
  color: var(--white);
  background: var(--color-blue-400);
}

::selection {
  color: var(--white);
  background: var(--color-blue-400);
}

.text-nowrap {
  white-space: nowrap;
}

.truncate {
  width: 126px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.countdown-container {
  visibility: hidden;
  position: relative;
  height: 20px;
  width: 20px;
  text-align: center;

  &.active {
    visibility: visible;
  }

  .countdown-number {
    color: var(--color-blue-400);
    display: inline-block;
    position: relative;
    font-size: 10px;
    bottom: 3px;
  }

  svg.countdown-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    transform: rotateY(-180deg) rotateZ(-90deg);

    circle {
      stroke-dasharray: 56.5px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 2px;
      stroke: var(--color-blue-400);
      fill: none;
      animation: countdown 30s linear infinite forwards;
    }
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: 56.5px;
    }
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  background-color: #f3f5f6;
}

.circle-filled {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  color: var(--color-blue-400);
  background: color-mix(in srgb, var(--color-blue-400) 20%, transparent);
}

sup {
  font-size: 16px;
  vertical-align: super;
  top: -4px;
  right: 0px;

  &.order {
    font-size: 12px;
    right: 0px;
    top: 7px;
  }
}

.text-transform-none {
  text-transform: none !important;
}

.app-version {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 10px;
}

.loading-shimmer {
  background: #f3f5f6;
  background-image: linear-gradient(to right, #f3f5f6 0%, #fafafb 20%, #e4eaef 40%, #e4eaef 100%);
  background-repeat: no-repeat;
  background-size: 800px 275px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  -webkit-animation-name: placeholderShimmer;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  border-radius: 5px;

  &.performance-header-value {
    height: 46px;
    width: 100%;
    margin-top: 10px;
  }

  &.performance-header-perf {
    height: 18px;
    width: 100%;
    margin-top: 10px;
  }

  &.position {
    height: 18px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;

    &.random {
      width: 66%;
    }
  }

  &.trade-tick {
    height: 24px;
    width: 100%;
    margin-bottom: -5px;
  }

  &.headline {
    height: 24px;
    width: 33.3%;
  }

  &.multi-order-strategy {
    width: 100%;
    height: 275px;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.spacer-default {
  height: $default-spacing;
}
