.container,
.container-fluid {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 150px;
  &.onboarding {
    margin-bottom: 200px;
  }

  @media (min-width: 767px) {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-bottom: 0;
  }
}

.form-control {
  background: transparent;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
}
.form-control:focus {
  background: transparent;
}

.modal-open .modal {
  overflow-y: scroll;
}

.modal-fullscreen {
  max-width: 100%;
  height: 100%;
  margin: 0;
  .modal-content {
    height: 100%;
  }
}

.btn {
  border-radius: 0px;
  border-width: 0px;
  padding: 0.25rem 1rem;

  &:disabled {
    cursor: not-allowed;
  }
}

.nav-tabs {
  border-bottom: none;
  display: flex;
  justify-content: flex-start;
  margin: -8px 0px 8px -8px;

  .nav-item.show .nav-link,
  .nav-link.active {
    border: none;
    background: none;
    color: var(--color-grey-50);
  }

  .nav-link {
    position: relative;
    border-bottom: 4px solid transparent;
    width: auto;
    text-align: center;
    font-size: 15px;
    text-transform: capitalize;
    cursor: pointer;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .nav-link.active,
  .nav-link.active:hover {
    color: var(--color-blue-400);
    border-bottom: 4px solid var(--color-blue-400);
  }

  a.nav-link {
    color: #abb5bf;
    padding: 0px;
  }

  .nav-link:hover {
    color: var(--color-blue-400);
    border-color: transparent;
  }

  .nav-item {
    margin: 8px;
  }
}

/*** nav-tabs strong version ****/

.nav-tabs--strong {
  border-bottom: 4px solid var(--color-grey-500);
  padding-right: inherit;
}
.nav-tabs.nav-tabs--strong .nav-item {
  margin-bottom: -3px;
}
.nav-tabs--strong .nav-link {
  border-bottom: 4px solid transparent;
}
.nav-tabs--strong .nav-item .nav-link.active {
  border-bottom: 4px solid var(--oldblue);
}
.nav-tabs--strong .nav-link.active:after,
.nav-tabs--strong .nav-link:hover:after {
  content: '';
  height: 18px;
  width: 30px;
  position: absolute;
  left: 50%;
  bottom: -4px;
  margin-left: -12px;
  background-size: 30px 30px;
  z-index: 1;
}

.nav-tabs--strong .nav-link:hover.active:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23173365' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav-tabs--strong .nav-link.active:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23173365' stroke-width='10' stroke='%23173365'/%3E%3C/svg%3E");
}
.nav-tabs--strong .nav-link:hover:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23173365' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav-tabs--white-triangle .nav-link:hover.active:after,
.nav-tabs--white-triangle .nav-link.active:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}
.nav-tabs--white-triangle .nav-link:hover:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav-tabs--strong .nav-link:focus,
.nav-tabs--strong .nav-link:hover {
  border-bottom: 4px solid var(--color-blue-400);
}
@media screen and (max-width: 767px) {
  .nav-tabs--strong .nav-link:hover.active:after,
  .nav-tabs.nav-tabs--strong .nav-link:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
  }
  .nav-tabs--strong .nav-link:hover:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
  }

  .nav-tabs--strong {
    margin-bottom: 10px;
  }
}

/* nav tabs progress bar variation */
.nav.nav-tabs--stepper {
  width: 100%;
}
.nav.nav-tabs--stepper .nav-item {
  padding-left: 15px;
  padding-right: 15px;
}
.nav.nav-tabs--stepper .nav-link {
  border-bottom: 1px solid var(--color-grey-300);
  color: var(--color-grey-300);
}

.nav.nav-tabs--stepper .nav-link.active:after,
.nav.nav-tabs--stepper .nav-link.active:hover:after,
.nav.nav-tabs--stepper .nav-link.active:focus:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23ffffff' stroke-width='5' stroke='%235ca3ef'/%3E%3C/svg%3E");
}
.nav.nav-tabs--stepper .nav-link.active,
.nav.nav-tabs--stepper .nav-link.active:hover,
.nav.nav-tabs--stepper .nav-link.active:focus {
  border-bottom: 4px solid var(--color-blue-400);
  color: var(--color-blue-400);
}

.nav.nav-tabs--stepper .nav-link.done:after,
.nav.nav-tabs--stepper .nav-link.done:hover:after,
.nav.nav-tabs--stepper .nav-link.done:focus:after {
  background-image: none;
}
.nav.nav-tabs--stepper .nav-link.done,
.nav.nav-tabs--stepper .nav-link.done:hover,
.nav.nav-tabs--stepper .nav-link.done:focus {
  border-bottom: 1px solid var(--color-blue-400);
  color: var(--color-blue-400);
}

/* no hover state wanted in nav-tabs--stepper */
.nav.nav-tabs--stepper .nav-link:hover,
.nav.nav-tabs--stepper .nav-link:focus {
  border-color: transparent;
  border-bottom: 1px solid var(--color-grey-300);
  color: var(--color-grey-300);
  cursor: inherit;
}
.nav.nav-tabs--stepper .nav-link:focus:after,
.nav.nav-tabs--stepper .nav-link:hover:after {
  background-image: none;
}

/* radio buttons */
.form-check-label {
  margin-left: 10px;
  font-size: 14px;
}

.badge {
  display: inline-block;

  a {
    color: var(--color-blue-400);
  }
}

.badge-pill {
  font-size: 10px;
  font-weight: unset;
  font-variation-settings: var(--font-bold);
  position: relative;
  top: -10px;
  right: -4px;
  border-radius: rem(4px);
  padding: 0.125rem 0.25rem 0.125rem 0.25rem;
}
.badge-primary,
.badge-primary-inactive,
.badge-secondary {
  background: #47a0ff33;
  color: var(--color-blue-400);
  border-radius: 4px;
}
.badge-secondary {
  background-color: var(--color-red-500);
  color: var(--white);
}
.badge-primary-inactive {
  background-color: var(--color-grey-500);
  color: var(--oldblue);
}
.badge-outline {
  color: var(--color-grey-500);
  border: 1px solid var(--color-grey-500);
}

.badge-danger {
  color: var(--color-grey-50);
  background-color: var(--color-grey-800);
}
.badge-alert {
  color: #ffffff;
  background-color: var(--color-red-500);
}

@media screen and (max-width: 576px) {
  :not(.container-fluid) > .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.alert-dismissible {
  padding: 0.75rem 1.25rem;
  .close {
    opacity: 1;
    &:hover {
      color: inherit;
    }
  }
}

.close {
  text-shadow: none;
  opacity: 1;
}

hr {
  border-width: 1px;
  border-color: var(--color-grey-500);
  &.full-size {
    margin: 0 -15px 15px -15px;
  }
}

.dropup .dropdown-toggle::after {
  border: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0px 12px 24px -5px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;

  @media screen and (max-width: 374px) {
    padding: 0.5rem 0.5rem;
    font-size: 12px;
  }
}
