/**** tables ****/
/*** depot & list ***/

th {
  font-weight: unset;
  font-variation-settings: var(--font-medium);
}

.table,
.table-depot {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  tr.top-align {
    td {
      vertical-align: top;
    }
  }
}

@media (min-width: 992px) {
  .table-orders {
    //second child width 17%
    th:nth-child(2) {
      width: 17%;
    }
    td {
      max-width: 150px;
    }
  }
}

.table {
  &.portfolio-transfer {
    th:first-child {
      width: 20%;
    }

    th:last-child {
      width: 20%;
    }
  }
}

.table thead th,
.table thead td {
  border-bottom: 1px solid var(--color-grey-700);
  border-top: none;
}

.table th,
.table-depot th {
  font-size: $smaller1-font-size;
  font-variation-settings: var(--font-medium);
  font-weight: unset;
  vertical-align: bottom;
  letter-spacing: 0.1px;
  border-bottom: 1px solid var(--color-grey-700);
}

.table-depot th:first-child {
  width: 30%;
}

.table td,
.table-depot td {
  font-size: $base-font-size;
  border-bottom: 1px solid var(--color-grey-700);
}

.table-depot td {
  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: $smaller1-font-size;
  }
}

.table td,
.table th,
.table-depot td,
.table-depot th {
  padding: 8px 10px;
  text-align: right;

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 8px 5px;
  }
}

.table td:first-child,
.table th:first-child,
.table-depot td:first-child,
.table-depot th:first-child {
  text-align: left;
  padding-left: 0;
}

.table td:last-child,
.table th:last-child,
.table-depot td:last-child,
.table-depot th:last-child {
  padding-right: 0;
}

.table-positions {
  tr:nth-last-child(2) td {
    @media screen and (min-width: 992px) {
      border-bottom: 8px solid var(--color-grey-700);
    }
  }
}

@media screen and (max-width: 991px) {
  .table-depot td {
    display: block;
    text-align: inherit;
    padding-right: 0;
    padding-left: 0;
  }
  .table-depot tr td:last-child {
    border-bottom: 2px solid var(--oldblue);
  }
  .table-depot th {
    display: none;
  }

  .table-depot tr:last-child td,
  .table-positions tr:nth-last-child(2) td {
    border-bottom-color: var(--color-grey-500);
  }

  .table-positions tr:last-child td:nth-last-child(3),
  .table-depot tr:nth-last-child(2) td:last-child,
  .table-depot tr:last-child td:last-child {
    border-bottom-color: var(--oldblue);
    border-bottom-width: 2px;
  }

  .table-depot tr:nth-child(2) td:first-child {
    border-top: 2px solid var(--oldblue);
  }

  .table-positions tr:last-child td:last-child {
    border: none;
  }
}

.table-tickets,
.table-post {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table-tickets th:first-child,
.table-post th:first-child {
  width: 100%;
}

.table-tickets td,
.table-post td {
  border-bottom: 1px solid var(--color-grey-700);
  vertical-align: top;
  color: var(--color-grey-50);

  & > div {
    overflow: hidden;
  }
}

.table-post td {
  vertical-align: middle;
}

.table-tickets th,
.table-post th {
  border-bottom: 1px solid var(--color-grey-700);
}

.table-tickets td,
.table-tickets th,
.table-post td,
.table-post th {
  padding: 8px 10px;
  text-align: right;
}

.table-tickets td:first-child,
.table-tickets th:first-child,
.table-post td:first-child,
.table-post th:first-child {
  text-align: left;
}

@media screen and (max-width: 991px) {
  .table-tickets td,
  .table-post td {
    display: block;
    text-align: inherit;
    padding-right: 0.9rem;
  }
  .table-tickets tr td:last-child,
  .table-tickets tr:last-child td:last-child,
  .table-post tr:last-child td:last-child,
  .table-post tr td:last-child {
    border-bottom: 2px solid var(--oldblue);
  }
  .table-tickets tr:nth-child(2) td:first-child,
  .table-post tr:nth-child(2) td:first-child {
    border-top: 2px solid var(--oldblue);
  }
  .table-tickets th,
  .table-post th {
    display: none;
  }

  .table-tickets tr:last-child td,
  .table-post tr:last-child td {
    border-bottom-color: var(--color-grey-500);
  }
}

/*** kontoumsaetze **/
.table-konto {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.table-konto th {
  font-size: $smaller1-font-size;
  font-weight: normal;
  vertical-align: bottom;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  border-bottom: 1px solid var(--color-grey-700);
}

.table-konto td {
  border-bottom: 1px solid var(--color-grey-700);

  & > div {
    overflow: hidden;
  }
}

.table-konto td,
.table-konto th {
  padding: 8px 10px;
  text-align: right;
}

.table-konto td:first-child,
.table-konto th:first-child {
  text-align: left;
  padding-left: 0;
  white-space: nowrap;
}

.table-konto td:last-child,
.table-konto th:last-child {
  padding-right: 0;
}

.table-konto th:nth-child(1) {
  width: 10%;
}

.table-konto th:nth-child(2) {
  width: 15%;
}

.table-konto th:nth-child(3) {
  width: 15%;
}

.table-konto th:nth-child(4) {
  width: 52.5%;
}

.table-konto th:nth-child(5) {
  width: 7.5%;
}

.table-konto tr:last-child td {
  border-bottom-color: var(--color-grey-700);
}

@media screen and (max-width: 991px) {
  .table-konto td {
    display: block;
    text-align: inherit;
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .table-konto tr td:last-child {
    border-bottom: 2px solid var(--color-grey-700);
  }
  .table-konto th {
    display: none;
  }
  .table-konto tr:last-child td {
    border-bottom-color: var(--color-grey-700);
  }
  .table-konto tr:last-child td:last-child {
    border-bottom-color: var(--color-grey-700);
    border-bottom-width: 2px;
  }
}

/*** price comparison ***/
.table-price-comparison {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1px;

  th {
    background-color: var(--oldblue);
    font-size: 13px;
    font-weight: normal;
    vertical-align: bottom;
  }

  td {
    font-size: 15px;
    border-bottom: 1px solid var(--color-grey-500);
  }

  td,
  th {
    padding: 10px 10px;
    text-align: right;
    vertical-align: middle;
  }

  tr th:last-child {
    border-left: var(--oldblue);
    border-left-style: solid;
    border-left-width: 1px;
  }

  th:first-child {
    width: 30%;
  }

  td:first-child {
    text-align: left;
    padding-left: 2px;
  }

  tr:nth-child(3) td,
  tr:nth-child(4) td {
    border-bottom-color: var(--oldblue);
  }

  td.empty {
    padding-right: 22px;
  }

  tr:nth-child(4) td > div {
    min-width: 3.75rem;
  }

  td.disclaimer {
    padding: 5px 0px 0px 0px;
    font-size: 13px;
    border-bottom: none;
    color: var(--color-grey-500);
  }

  td.more-link {
    padding: 30px 0px 0px 2px;
    border: none;
  }

  td.headline {
    padding: 30px 0px 5px 0px;
  }

  &.table-price-comparison--mobile {
    th:first-child {
      width: inherit;
    }

    tr td:last-child,
    tr th:last-child {
      width: 4rem;
    }
  }
}

.table-blue {
  th {
    background-color: var(--oldblue);
    color: var(--white);
    font-size: 13px;
    font-weight: normal;
    vertical-align: bottom;
  }
}

.table-myths {
  td {
    vertical-align: top;
  }

  tr td:last-child,
  tr td:first-child {
    width: 45%;
    border-top: 1px dashed var(--color-grey-500);
    padding-top: 0.5rem;
  }

  tr:last-child {
    td:first-child,
    td:last-child {
      border-bottom: 1px dashed var(--color-grey-500);
    }
  }
}

.table-compressed {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;

  thead {
    display: none;
  }
}

.table-compressed td {
  font-size: $base-font-size;
  border-bottom: 1px solid var(--oldblue);
  padding: 4px 10px;
  div {
    min-height: 25px;
  }
}

.table-watchlist {
  @media screen and (min-width: $bp-sm) {
    td:first-child {
      min-width: 10rem;
    }
    td:nth-child(3) {
      min-width: 7rem;
    }
  }
  @media screen and (min-width: $bp-md) {
    td:first-child {
      min-width: 15rem;
    }
  }
  .vertical-align-top {
    vertical-align: top;
  }
}

.table-basic {
  width: 100%;

  tr {
    td {
      font-family: 'Montserrat', sans-serif;
      font-size: $smaller2-font-size;
      padding: 8px 0;
      border-bottom: 1px solid #d9d9d9;

      &:first-of-type {
        font-weight: bold;
      }

      &:last-of-type {
        text-align: right;
      }
    }

    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }
}

@media screen and (min-width: $bp-md) and (max-width: $bp-lg) {
  .flex-md-text-ellipsis {
    text-overflow: ellipsis;
    max-width: 70px;
    overflow: hidden;
    white-space: nowrap;
  }
}

table.light-opacity {
  th:not(.ignore-opacity),
  td:not(.ignore-opacity) {
    opacity: 0.5;
  }
}

.table-depot tr.no-border {
  td {
    border-bottom: none;
  }
}

.note {
  font-size: 12px;
  background: #fffad2;
  padding: 6px;
  word-break: break-word;
}

textarea {
  resize: none;
}

.table-simple {
  width: calc(100% + 32px);
  margin: 0 -16px;

  thead {
    background: var(--color-grey-800);

    th {
      color: var(--color-grey-400);
      font-size: 14px;
    }
  }

  tr:last-child {
    td {
      border: none;
      padding-bottom: 0;
    }
  }

  th,
  td {
    padding: 8px 16px;
  }

  td {
    border-bottom: 1px solid var(--color-grey-700);
  }
}
