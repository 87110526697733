.trans-zoom {
  transition: transform 300ms ease;

  &:hover {
    transform: scale(1.05, 1.05);
  }
}

.opacity-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.25s linear;

  &.no-max-height {
    max-height: unset;
  }

  &.active {
    opacity: 1;
    max-height: 100%;
    overflow: visible;
  }
}
