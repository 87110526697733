.gb-cc-dialog {
  color: var(--white);
  background: var(--oldblue);
  opacity: 0.95;
  box-shadow: 0px 0 25px -5px var(--oldblue);
}
.gb-cc-dialog .cc-deny {
  border: 0;
}
.cc-btn + .cc-btn {
  margin-left: 0;
}

.gb-cc-dialog .cc-message {
  margin: 1rem !important;
  margin-bottom: 1.5rem !important;
}

.cc-window.cc-floating {
  padding: 0.5rem;
}
@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-banner,
  .cc-window.cc-floating,
  .cc-window.cc-left,
  .cc-window.cc-right {
    left: 1%;
    right: 1%;
  }
}
.cc-link:active,
.cc-link:visited {
  color: var(--oldblue);
}
