/**
 * px to rem
 * @requires {variable} $base-font-size
 * @param {number} $target - size to convert
 * @param {number} $context ($base-font-size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: rem(30px); }
 * @returns {number}
 */

@use 'sass:math';

@function rem($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return math.div($target, $context) + 0rem;
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

//
//  Function to create an optimized svg url for use as css background-image
//
@function svg-url($svg) {
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(math.div(str-length($svg), $slice));
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode (may need a few extra replacements)
    //
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url('data:image/svg+xml;charset=utf8,#{$encoded}');
}
