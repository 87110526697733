/* Breakpoints analog bootstrap 4.x */
$bp-xs: 575px;
$bp-sm: 767px;
$bp-md: 991px;
$bp-lg: 1199px;
$bp-xl: 1200px;

// DEPRECATED: do not use these classes anymore; use tokens instead (_theme.scss)
$smaller1-font-size: 0.875rem; // 14px
$smaller2-font-size: 0.75rem; // 12px
$base-font-size: 16px;
$bigger1-font-size: 1.125rem; // 18px
$bigger2-font-size: 1.25rem; // 20px
$bigger3-font-size: 1.625rem; // 26px

$navigation-height: 72px;
$navigation-height-mobile: 48px;

$default-spacing: 30px;
