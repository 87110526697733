/* This is the default state */
.custom-radio .custom-control-label::before {
  background-color: transparent;
  top: 2px;
  border: 1px solid var(--color-grey-300);
}
.custom-radio .custom-control-label {
  padding-left: 10px;
  font-size: 14px;
}

/* This is the checked state */
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--oldblue);
  /* this bg image SVG is just a white circle, you can replace it with any valid SVG code */
  border-radius: 50%;
  top: 2px;
}

/* active state i.e. displayed while the mouse is being pressed down */
.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: var(--white);
  top: 3px;
}

/* This is the default state */
.custom-checkbox .custom-control-label::before {
  background-color: transparent;
  top: 3px;
  border: 1px solid var(--color-grey-300);
}
.custom-checkbox .custom-control-label {
  padding-left: 10px;
  font-size: 14px;
}

/* This is the checked state */

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--oldblue);
  /* this bg image SVG is just a white circle, you can replace it with any valid SVG code */
  border-radius: 0;
}

/* active state i.e. displayed while the mouse is being pressed down */
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  color: var(--white);
  top: 3px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--white);
  border-color: inherit;
  background-color: inherit;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-control.custom-checkbox.checkbox--alert {
  label::after,
  label::before {
    left: inherit;
    right: -1.55rem;
    top: 0.15rem;
  }
  label {
    margin-right: 1.5rem;
  }

  .custom-control-input:checked {
    & ~ .custom-control-label {
      &.custom-control-label--success::before,
      &.custom-control-label--success::after {
        background-color: $success;
      }
      &.custom-control-label--info::before,
      &.custom-control-label--info::after {
        background-color: $info;
      }
      &.custom-control-label--danger::before,
      &.custom-control-label--danger::after {
        background-color: $danger;
      }
    }
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label {
    &.custom-control-label--success::before {
      border-color: $success;
    }
    &.custom-control-label--info::before {
      border-color: $info;
    }
    &.custom-control-label--danger::before {
      border-color: $danger;
    }
  }

  .custom-control-input:active ~ .custom-control-label::before {
    top: 0.15rem;
  }
}
