.navigation-hamburger {
  padding: 15px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger:hover {
  opacity: 0.7;
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger .navigation-hamburger-inner,
.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger .navigation-hamburger-inner::before,
.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger .navigation-hamburger-inner::after {
  background-color: var(--white);
}

.navigation-hamburger-box {
  width: 28px;
  height: 22px;
  display: inline-block;
  position: relative;
}

.navigation-hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.navigation-hamburger-inner,
.navigation-hamburger-inner::before,
.navigation-hamburger-inner::after {
  width: 26px;
  height: 3px;
  background-color: var(--white);
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.navigation-hamburger-inner::before,
.navigation-hamburger-inner::after {
  content: '';
  display: block;
}

.navigation-hamburger--spring .navigation-hamburger-inner {
  top: 3px;
  transition: background-color 0s 0.13s linear;
}

.navigation-hamburger--spring .navigation-hamburger-inner::before {
  top: 0.5rem;
  transition:
    top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navigation-hamburger--spring .navigation-hamburger-inner::after {
  top: 1rem;
  transition:
    top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger--spring .navigation-hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger--spring .navigation-hamburger-inner::before {
  top: 0;
  transition:
    top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 0.5rem, 0) rotate(45deg);
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger--spring .navigation-hamburger-inner::after {
  top: 0;
  transition:
    top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 0.5rem, 0) rotate(-45deg);
}

/**** Navigation Main ****/
/*************************/

.navigation a {
  color: var(--white);
}

.navigation {
  height: rem($navigation-height-mobile);
  position: fixed;
  top: 0;
  z-index: 10;

  @media (min-width: 992px) {
    height: rem($navigation-height);
  }
}

.navigation-inner {
  justify-content: space-between;
}

.navigation-submenu {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem;
  height: 100%;
  align-items: center;
}

.navigation-submenu-item {
  font-size: 1rem;
  font-variation-settings: var(--font-bold);
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  cursor: pointer;
  outline: none;
}

.navigation-submenu-item a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-asset-search,
.mobile-search {
  .header-search-input-container {
    input {
      width: 100%;
      height: 40px;
      padding: 8px 0 8px 40px;
      border-radius: 6px;
      border: none;

      @media (min-width: $bp-lg) {
        width: 251px;
      }
    }

    .input-group-material-icon {
      position: absolute;
      left: 8px;
      top: 16px;
      height: unset;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;

      @media (min-width: $bp-md) {
        left: 0;
        top: 0;
        height: 100%;
      }
    }
  }
}

.item-highlight {
  position: absolute;
  top: 25px;
  width: 100%;
  height: 2px;
}

.navigation-submenu-item svg {
  fill: var(--white);
}

.navigation-submenu-item .navigation-submenu-list {
  display: none;
}

.navigation-submenu-item--hover:hover .navigation-submenu-list,
.navigation-submenu-item--hover:focus .navigation-submenu-list {
  display: block;
}

.navigation-submenu-item--open .navigation-submenu-list {
  display: block;
}

.navigation-submenu-item-checkbox {
  display: none;
}

.navigation-submenu-list {
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: none;
  background: var(--oldblue);
  min-width: 140px;
  position: absolute;
  top: 52px;
  border-top: 0.07rem solid var(--oldblue);
  margin-left: 0;
  z-index: 2;
}

.navigation-submenu-list-icon {
  position: absolute;
  left: 50%;
  top: -14px;
  margin-left: -9px;
}

.navigation-submenu-list-item {
  padding: 0.5rem 1rem 0.5rem 1rem;
  white-space: nowrap;
}

.navigation-submenu-list-item a {
  display: block;
  color: var(--white);
  text-decoration: none;
  border-bottom: 0.07rem solid transparent;
}

.navigation-submenu-item-absolute-primary {
  padding-left: 0;
  padding-right: 0;
}

.navigation-submenu-item-absolute-secondary {
  padding-left: 0;
  padding-right: 10px;
}

.navigation-hamburger-wrapper {
  z-index: 1;
}

.navigation-submenu-checkbox {
  display: none;
  opacity: 0;
  z-index: 10;
  background: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 5px;
  border: 0.15rem solid var(--color-red-400);
}

@media screen and (max-width: 575px) {
  .navigation {
    align-items: center;
  }
  .navigation-inner {
    flex-direction: column;
    position: relative;
  }

  .navigation-submenu-item {
    width: 100%;
    font-size: $h2-font-size;
    font-variation-settings: var(--font-medium);
    height: auto;
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    line-height: 2.75rem;
  }
  .navigation-submenu-list-item {
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
  }

  .navigation-submenu-item-absolute-primary {
    position: absolute;
    right: 0;
    width: auto;
    height: 40px;
    top: 5px;
    padding-right: 15px;
    border-bottom: none;
  }
  .navigation-submenu-item-absolute-secondary {
    position: absolute;
    right: 47px;
    width: auto;
    height: 40px;
    top: 7px;
    border-bottom: none;
  }
  .navigation-submenu-list {
    position: relative;
    top: inherit;
    border-bottom: 0.08rem solid var(--color-blue-400);
    line-height: 2.25rem;
    padding-top: 0;
  }

  /* open submenu-item-list on hover and on click checkbox */
  .navigation-submenu-item-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 31px;
    opacity: 0;
    display: block;
    cursor: pointer;
  }
  .navigation-submenu-item-checkbox ~ .navigation-submenu-list {
    display: none;
  }
  .navigation-submenu-item-checkbox:checked ~ .navigation-submenu-list {
    display: block !important;
  }

  .navigation-hamburger-wrapper {
    display: block;
  }
  .navigation-submenu-checkbox {
    display: block;
    cursor: pointer;
  }
  .navigation-submenu {
    border-bottom: 0.24rem solid var(--oldblue);
    position: absolute;
    height: auto;
    top: 35px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    transform: translatex(100%);
    transition: all 0.25s;
    width: calc(100% + 30px);
    padding-left: 0;
    padding-right: 0;
    margin: 0 -15px;
    background: var(--oldblue);
  }
  .navigation-submenu-checkbox:checked ~ .navigation-submenu {
    transform: translatex(0) !important;
    opacity: 1;
  }
  .navigation-submenu {
    display: none !important;
  }
  /* just a display none/block on submenu */
  .navigation-submenu-checkbox:checked ~ .navigation-submenu {
    display: block !important;
  }
  /* just a display none/block on submenu */

  .navigation-inner {
    align-items: flex-start !important;
    padding-left: 0;
    padding-right: 0;
  }
  .navigation-submenu-item {
    display: block;
    text-align: center;
  }
  .navigation-submenu-item a {
    justify-content: center;
  }

  .navigation-submenu-item-checkbox {
    width: 40px;
    right: 0;
    left: inherit;
    opacity: 0;
    z-index: 1000;
  }
  .navigation-submenu-list {
    background: transparent;
    border-bottom: none;
    border-top: none;
  }
  .navigation-submenu-list-icon {
    display: none;
  }
  .navigation-submenu-item > a:before {
    content: '';
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
  }
  .navigation-submenu-item > a:after {
    content: '';
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='none' stroke-width='7' stroke='%23ffffff'/%3E%3C/svg%3E");
    background-size: 17px 12px;
    z-index: 100;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    transition: transform 0.25s linear;
  }
  .navigation-submenu-item .navigation-submenu-item-checkbox:checked + a:after,
  .navigation-submenu-item.navigation-submenu-item--open a:after {
    transform: rotate(0deg);
  }
}

.navigation-positioning-mobile {
  position: absolute;
}

.link-selector {
  .dropdown-menu {
    z-index: 5;
    border-radius: unset;
    background: var(--white);
    box-shadow: 1px 1px 11px 0px var(--color-grey-500);
    border: 0;
    line-height: 1.5;
    padding: 10px 15px;
    .link-selector-link {
      font-variation-settings: var(--font-regular);
      display: block;
    }
  }

  .dropdown-toggle::after {
    border: inherit;
    vertical-align: inherit;
    transform: rotate(180deg);
  }
  &.show.dropdown .dropdown-toggle::after {
    transform: rotate(0deg);
  }
}

.navigation-header {
  background: var(--oldblue);
  width: 100%;
  font-size: 1rem;
  color: var(--white);

  @media screen and (min-width: $bp-md) {
    height: 72px;
  }

  * {
    outline: none;
  }

  .navigation-submenu-item--active {
    box-shadow: inset 8px 0 var(--color-blue-400);
    .item-active {
      display: none;
    }
  }

  .navigation-hamburger-wrapper {
    display: block;

    .navigation-hamburger-inner::before {
      top: 0.5rem;
      transition:
        top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .navigation-hamburger-inner::after {
      top: 1rem;
      transition:
        top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.open {
    .navigation-hamburger-inner {
      background-color: color-mix(in srgb, var(--white) 0%, transparent);
    }

    .navigation-hamburger-inner::before {
      top: 0;
      transition:
        top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0.5rem, 0) rotate(45deg);
      background-color: #fff4f4;
    }

    .navigation-hamburger-inner::after {
      top: 0;
      transition:
        top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0.5rem, 0) rotate(-45deg);
      background-color: #fff4f4;
    }

    .navigation-items {
      margin-top: 3rem;
      display: block;
      width: 80%;
      height: calc(100vh - 3rem);
      overflow-y: scroll;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--oldblue);
      .navigation-item {
        padding: 0.3rem 2rem;
        font-size: 1rem;
        &:first-of-type {
          padding-top: 1rem;

          @media (max-width: $bp-md) {
            margin-top: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .inner {
    display: flex;
    height: 100%;
    justify-content: space-between;

    @media (min-width: 992px) {
      justify-content: space-between;
      width: 100%;
      margin: auto;
      padding: 0 16px;
    }

    @media (min-width: 1500px) {
      justify-content: center;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 155px;

    .iban {
      font-size: 12px;
      letter-spacing: 0.17px;
      font-weight: normal;
      color: var(--white);
      margin-top: 5px;
    }
  }

  .navigation-mobile-search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .navigation-items {
    display: none;
    &.open {
      margin-top: 2rem;
      display: block;
      width: 80%;
      height: 100vh;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--oldblue);
      .navigation-item {
        padding: 0.5rem 2rem;
      }
    }
  }

  .navigation-action-bar {
    display: none;
  }

  .private-links {
    position: absolute;
    display: none;
    border-radius: 4px;
  }

  @media (min-width: 992px) {
    .navigation-hamburger-wrapper {
      display: none;
    }

    .logo-container {
      justify-content: flex-end;
    }

    .navigation-submenu-item--active {
      box-shadow: none;
      .item-active {
        display: block;
        background: var(--color-grey-800);
      }

      .private-links-link {
        border-bottom: 4px solid var(--color-blue-400);
      }
    }

    .navigation-action-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (min-width: 1500px) {
        width: 155px;
        justify-content: flex-start;
      }

      .action-bar-item {
        position: relative;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }

      .profile-icon-wrapper {
        background: linear-gradient(var(--color-red-500), var(--color-blue-400));
        border-radius: 24px;
      }

      .profile-icon {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        width: 40px;
        height: 40px;

        .icon {
          padding: 7px;
        }

        .badge {
          position: absolute;
          left: rem(27px);
          top: rem(-4px);
          width: fit-content;
        }
      }
    }

    .navigation-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 32px;
      width: 100%;

      @media (min-width: 1500px) {
        width: 1140px;
        padding: 0 16px;
      }

      .navigation-item {
        position: relative;
        cursor: pointer;
        margin: 0 rem(8px);
        font-size: rem(14px);
        white-space: nowrap;

        @media (min-width: 1500px) {
          font-size: rem(16px);
          margin: 0 1rem;
        }

        &.navigation-item-mobile {
          display: none;
        }

        &:hover {
          .item-highlight {
            display: block;
            background: var(--color-grey-800);
            background-color: var(--color-grey-500);

            transition: all 0.25s;
          }
        }
      }

      .navigation-item-seperator,
      .search-outer {
        display: none;
      }
    }

    .private-links {
      display: none;
      border-radius: 10px;
      position: absolute;
      top: 45px;
      right: -15px;
      right: 0;
      white-space: nowrap;
      padding: 0.5rem;

      background: var(--oldblue);

      &-long {
        width: auto;
        left: -13rem;
      }

      &.open {
        display: block;
      }

      .navigation-item {
        position: relative;
        margin-right: 0;
        padding: 0.5rem;
        width: fit-content;

        &:hover {
          .highlight {
            display: block;
            background: var(--color-grey-800);
            transition: all 0.25s;
          }
        }

        .highlight {
          position: absolute;
          top: 35px;
          width: calc(100% - 16px);
          height: 2px;
        }
      }
    }
  }
}

.second-level-navigation {
  position: fixed;
  top: 80px;
  width: 100%;
  background: var(--white);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-grey-800);

  .item {
    padding: 8px;
    margin: 0px 8px 0 16px;
    font-variation-settings: var(--font-bold);
    font-size: 1rem;
    color: var(--color-grey-500);
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    border-bottom: 3px solid transparent;

    &:hover,
    &.active {
      border-color: var(--color-blue-400);
    }
  }
}
