.border-color-basic {
  border-color: var(--oldblue) !important;
}
.border-color-success {
  border-color: var(--color-green-400) !important;
  input {
    border-color: var(--color-green-400) !important;
  }
}

.border-top {
  border-top: 1px solid var(--color-grey-700);
}

.border-attention {
  border: 2px solid var(--color-blue-400);
}

.border-error {
  border: 2px solid var(--color-red-400);
}

.b-r-4 {
  border-radius: 4px;
}

.border,
.b-r-8 {
  border-radius: 8px;
}

.border-primary {
  border: 1px solid var(--oldblue);
}

.border-default {
  border: 1px solid var(--color-grey-700);
}

.gap {
  gap: 1rem;
}

.gap-3-quarters {
  gap: 0.75rem;
}

.gap-half {
  gap: 0.5rem;
}

.gap-small {
  gap: 0.25rem;
}

.gap-large {
  gap: 40px;
}
