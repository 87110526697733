@charset "UTF-8";
:root {
  --oldblue: #061d3c;
  --black: #000;
  --white: #fff;
  --gold: #d3c398;
  --color-grey-50: #000;
  --color-grey-100: #111112;
  --color-grey-200: #232425;
  --color-grey-300: #3a3c3d;
  --color-grey-400: #777f89;
  --color-grey-500: #abb5bf;
  --color-grey-600: #c5cbd1;
  --color-grey-700: #dfe3e7;
  --color-grey-800: #f3f5f6;
  --color-blue-50: #144e8c;
  --color-blue-100: #1963b3;
  --color-blue-200: #1f79d9;
  --color-blue-300: #308df0;
  --color-blue-400: #248eff;
  --color-blue-500: #47a0ff;
  --color-blue-600: #70b5ff;
  --color-blue-700: #91c6ff;
  --color-blue-800: #bdddff;
  --color-blue-900: #dcedff;
  --color-green-100: #1e694f;
  --color-green-200: #278565;
  --color-green-300: #2fa27a;
  --color-green-400: #34b287;
  --color-green-500: #37be90;
  --color-green-600: #55c8a1;
  --color-green-700: #73d1b1;
  --color-green-800: #91dbc2;
  --color-red-100: #8a2631;
  --color-red-200: #af303f;
  --color-red-300: #d53b4d;
  --color-red-400: #fa455a;
  --color-red-500: #fa5165;
  --color-red-600: #fb6b7c;
  --color-red-700: #fc8593;
  --color-red-800: #fc9faa;
  --color-yellow-100: #8c6d1e;
  --color-yellow-200: #b38b26;
  --color-yellow-300: #d9a92e;
  --color-yellow-400: #ffc736;
  --color-yellow-500: #ffc839;
  --color-yellow-600: #ffcf54;
  --color-yellow-700: #ffd872;
  --color-yellow-800: #ffe090;
  --color-purple-100: #5e378f;
  --color-purple-200: #7947b8;
  --color-purple-300: #9659e6;
  --color-purple-400: #a763ff;
  --color-purple-500: #b072ff;
  --color-purple-600: #b982ff;
  --color-purple-700: #c292ff;
  --color-purple-800: #d0aaff;
  --color-pink-100: #8f4187;
  --color-pink-200: #b854ae;
  --color-pink-300: #e668d9;
  --color-pink-400: #ff74f1;
  --color-pink-500: #ff82f3;
  --color-pink-600: #ff90f4;
  --color-pink-700: #ff9ef5;
  --color-pink-800: #ffb4f8;
}

.zero-text, .zero-user-note-button {
  font-style: normal;
}
.zero-text.default, .zero-user-note-button {
  color: var(--color-grey-50);
}
.zero-text.btn, .zero-user-note-button, .zero-text.white {
  color: var(--white);
}
.zero-text.link, .link.zero-user-note-button {
  color: var(--color-blue-400);
}
.zero-text.link.disabled, .link.disabled.zero-user-note-button {
  cursor: not-allowed;
  text-decoration: none;
}
.zero-text.link:not(.disabled):hover, .link.zero-user-note-button:not(.disabled):hover {
  color: var(--color-blue-500);
}
.zero-text.label, .label.zero-user-note-button {
  color: var(--color-grey-400);
}
.zero-text.disabled, .disabled.zero-user-note-button {
  color: var(--color-grey-500);
}
.zero-text.course-red, .course-red.zero-user-note-button {
  color: var(--color-red-400);
}
.zero-text.course-green, .course-green.zero-user-note-button {
  color: var(--color-green-400);
}
.zero-text.dark-red, .dark-red.zero-user-note-button {
  color: var(--color-red-100);
}
.zero-text.dark-green, .dark-green.zero-user-note-button {
  color: var(--color-green-100);
}
.zero-text.dark-blue, .dark-blue.zero-user-note-button {
  color: var(--color-blue-50);
}
.zero-text.dark-purple, .dark-purple.zero-user-note-button {
  color: var(--color-purple-100);
}
.zero-text.warning, .warning.zero-user-note-button {
  color: var(--color-yellow-400);
}
.zero-text.p-base-lig, .p-base-lig.zero-user-note-button, .zero-text.p-base-reg, .p-base-reg.zero-user-note-button, .zero-text.p-base-med, .p-base-med.zero-user-note-button, .zero-text.p-base-bold, .p-base-bold.zero-user-note-button {
  font-size: 1rem;
  line-height: 22.4px;
}
.zero-text.p-base-lig, .p-base-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}
.zero-text.p-base-reg, .p-base-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}
.zero-text.p-base-med, .p-base-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}
.zero-text.p-base-bold, .p-base-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}
.zero-text.p-small-lig, .p-small-lig.zero-user-note-button, .zero-text.p-small-reg, .p-small-reg.zero-user-note-button, .zero-text.p-small-med, .p-small-med.zero-user-note-button, .zero-text.p-small-bold, .p-small-bold.zero-user-note-button {
  font-size: 14px;
  line-height: 19.6px;
}
.zero-text.p-small-lig, .p-small-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}
.zero-text.p-small-reg, .p-small-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}
.zero-text.p-small-med, .p-small-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}
.zero-text.p-small-bold, .p-small-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}
.zero-text.p-xsmall-lig, .p-xsmall-lig.zero-user-note-button, .zero-text.p-xsmall-reg, .p-xsmall-reg.zero-user-note-button, .zero-text.p-xsmall-med, .p-xsmall-med.zero-user-note-button, .zero-text.p-xsmall-bold, .p-xsmall-bold.zero-user-note-button {
  font-size: 12px;
  line-height: 16.8px;
}
.zero-text.p-xsmall-lig, .p-xsmall-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}
.zero-text.p-xsmall-reg, .p-xsmall-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}
.zero-text.p-xsmall-med, .p-xsmall-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}
.zero-text.p-xsmall-bold, .p-xsmall-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}
.zero-text.p-big-lig, .p-big-lig.zero-user-note-button, .zero-text.p-big-reg, .p-big-reg.zero-user-note-button, .zero-text.p-big-med, .p-big-med.zero-user-note-button, .zero-text.p-big-bold, .p-big-bold.zero-user-note-button {
  font-size: 18px;
  line-height: 25.2px;
}
.zero-text.p-big-lig, .p-big-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}
.zero-text.p-big-reg, .p-big-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}
.zero-text.p-big-med, .p-big-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}
.zero-text.p-big-bold, .p-big-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}
.zero-text.p-bigger-lig, .p-bigger-lig.zero-user-note-button, .zero-text.p-bigger-reg, .p-bigger-reg.zero-user-note-button, .zero-text.p-bigger-med, .p-bigger-med.zero-user-note-button, .zero-text.p-bigger-bold, .p-bigger-bold.zero-user-note-button {
  font-size: 20px;
  line-height: 28px;
}
.zero-text.p-bigger-lig, .p-bigger-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}
.zero-text.p-bigger-reg, .p-bigger-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}
.zero-text.p-bigger-med, .p-bigger-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}
.zero-text.p-bigger-bold, .p-bigger-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}
.zero-text.p-huge-lig, .p-huge-lig.zero-user-note-button, .zero-text.p-huge-reg, .p-huge-reg.zero-user-note-button, .zero-text.p-huge-med, .p-huge-med.zero-user-note-button, .zero-text.p-huge-bold, .p-huge-bold.zero-user-note-button {
  font-size: 26px;
  line-height: 36.4px;
}
.zero-text.p-huge-lig, .p-huge-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}
.zero-text.p-huge-reg, .p-huge-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}
.zero-text.p-huge-med, .p-huge-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}
.zero-text.p-huge-bold, .p-huge-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}
.zero-text.h1-lig, .h1-lig.zero-user-note-button, .zero-text.h1-bold, .h1-bold.zero-user-note-button {
  font-size: 32px;
  line-height: 44.8px;
}
.zero-text.h1-lig, .h1-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}
.zero-text.h1-bold, .h1-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-fix.white {
  color: var(--color-white);
}
.zero-fix.black {
  color: var(--color-black);
}

.zero-surface.oldblue {
  background: var(--oldblue);
}
.zero-surface.grey-50 {
  background: var(--black);
}
.zero-surface.grey-100 {
  background: var(--color-grey-100);
}
.zero-surface.grey-200 {
  background: var(--color-grey-200);
}
.zero-surface.grey-300 {
  background: var(--color-grey-300);
}
.zero-surface.grey-400 {
  background: var(--color-grey-400);
}
.zero-surface.grey-500 {
  background: var(--color-grey-500);
}
.zero-surface.grey-600 {
  background: var(--color-grey-600);
}
.zero-surface.grey-700 {
  background: var(--color-grey-700);
}
.zero-surface.grey-800 {
  background: var(--color-grey-800);
}
.zero-surface.grey-900 {
  background: var(--color-grey-900);
}
.zero-surface.primary-50 {
  background: var(--color-blue-50);
}
.zero-surface.primary-100 {
  background: var(--color-blue-100);
}
.zero-surface.primary-200 {
  background: var(--color-blue-200);
}
.zero-surface.primary-300 {
  background: var(--color-blue-300);
}
.zero-surface.primary-400 {
  background: var(--color-blue-400);
}
.zero-surface.primary-500 {
  background: var(--color-blue-500);
}
.zero-surface.primary-600 {
  background: var(--color-blue-600);
}
.zero-surface.primary-700 {
  background: var(--color-blue-700);
}
.zero-surface.primary-800 {
  background: var(--color-blue-800);
}
.zero-surface.primary-900 {
  background: var(--color-blue-900);
}
.zero-surface.secondary-100 {
  background: var(--color-green-100);
}
.zero-surface.secondary-200 {
  background: var(--color-green-200);
}
.zero-surface.secondary-300 {
  background: var(--color-green-300);
}
.zero-surface.secondary-400 {
  background: var(--color-green-400);
}
.zero-surface.secondary-500 {
  background: var(--color-green-500);
}
.zero-surface.secondary-600 {
  background: var(--color-green-600);
}
.zero-surface.secondary-700 {
  background: var(--color-green-700);
}
.zero-surface.secondary-800 {
  background: var(--color-green-800);
}
.zero-surface.tertiary-100 {
  background: var(--color-red-100);
}
.zero-surface.tertiary-200 {
  background: var(--color-red-200);
}
.zero-surface.tertiary-300 {
  background: var(--color-red-300);
}
.zero-surface.tertiary-400 {
  background: var(--color-red-400);
}
.zero-surface.tertiary-500 {
  background: var(--color-red-500);
}
.zero-surface.tertiary-600 {
  background: var(--color-red-600);
}
.zero-surface.tertiary-700 {
  background: var(--color-red-700);
}
.zero-surface.tertiary-800 {
  background: var(--color-red-800);
}
.zero-surface.yellow-100 {
  background: var(--color-yellow-100);
}
.zero-surface.yellow-200 {
  background: var(--color-yellow-200);
}
.zero-surface.yellow-300 {
  background: var(--color-yellow-300);
}
.zero-surface.yellow-400 {
  background: var(--color-yellow-400);
}
.zero-surface.yellow-500 {
  background: var(--color-yellow-500);
}
.zero-surface.yellow-600 {
  background: var(--color-yellow-600);
}
.zero-surface.yellow-700 {
  background: var(--color-yellow-700);
}
.zero-surface.yellow-800 {
  background: var(--color-yellow-800);
}
.zero-surface.purple-100 {
  background: var(--color-purple-100);
}
.zero-surface.purple-200 {
  background: var(--color-purple-200);
}
.zero-surface.purple-300 {
  background: var(--color-purple-300);
}
.zero-surface.purple-400 {
  background: var(--color-purple-400);
}
.zero-surface.purple-500 {
  background: var(--color-purple-500);
}
.zero-surface.purple-600 {
  background: var(--color-purple-600);
}
.zero-surface.purple-700 {
  background: var(--color-purple-700);
}
.zero-surface.purple-800 {
  background: var(--color-purple-800);
}
.zero-surface.pink-100 {
  background: var(--color-pink-100);
}
.zero-surface.pink-200 {
  background: var(--color-pink-200);
}
.zero-surface.pink-300 {
  background: var(--color-pink-300);
}
.zero-surface.pink-400 {
  background: var(--color-pink-400);
}
.zero-surface.pink-500 {
  background: var(--color-pink-500);
}
.zero-surface.pink-600 {
  background: var(--color-pink-500);
}
.zero-surface.pink-700 {
  background: var(--color-pink-700);
}
.zero-surface.pink-800 {
  background: var(--color-pink-800);
}

.zero-button.default, .zero-user-note-button {
  background: var(--color-blue-400);
}
.zero-button.secondary, .secondary.zero-user-note-button {
  background: transparent;
}
.zero-button.disabled, .disabled.zero-user-note-button {
  background: var(--color-grey-500);
}
.zero-button.red, .red.zero-user-note-button {
  background: var(--color-red-300);
  border: 1px solid var(--color-red-400);
}
.zero-button.green, .green.zero-user-note-button {
  background: var(--color-green-300);
  border: 1px solid var(--color-green-400);
}
.zero-button.gold, .gold.zero-user-note-button {
  background: var(--gold);
  border: 1px solid var(--gold);
}
.zero-button.default-light, .default-light.zero-user-note-button {
  background: var(--color-blue-900);
}
.zero-button.red-light, .red-light.zero-user-note-button {
  background: var(--color-red-800);
}
.zero-button.green-light, .green-light.zero-user-note-button {
  background: var(--color-green-800);
}
.zero-button.grey-light, .grey-light.zero-user-note-button {
  background: var(--color-grey-800);
}

.zero-icon.default, .zero-icon.zero-user-note-button {
  color: var(--color-black);
}
.zero-icon.action {
  color: var(--color-blue-400);
}
.zero-icon.dimmed {
  color: var(--color-grey-500);
}
.zero-icon.white {
  color: var(--white);
}
.zero-icon.red {
  color: var(--color-red-200);
}
.zero-icon.green {
  color: var(--color-green-200);
}
.zero-icon.blue {
  color: var(--color-blue-100);
}
.zero-icon.purple {
  color: var(--color-purple-100);
}

.zero-backdrop:not(body), .tile:not(body), .alert:not(body) {
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
}
.zero-backdrop.main-backdrop, .main-backdrop.tile, .main-backdrop.alert {
  background: var(--color-grey-800);
}
.zero-backdrop.box-white, .tile, .box-white.alert {
  background: var(--white);
}
.zero-backdrop.gradient, .gradient.tile, .gradient.alert {
  margin: 0;
  background: linear-gradient(to top right, rgba(255, 0, 168, 0.3) 10%, rgba(255, 0, 168, 0) 50%, rgba(92, 163, 239, 0) 60%, rgba(92, 163, 239, 0.3) 90%);
  background-color: #061d3c;
}
.zero-backdrop.main-dark-blue, .main-dark-blue.tile, .main-dark-blue.alert {
  background: var(--oldblue);
  color: var(--color-grey-400);
}

.zero-strokes.default, .zero-strokes.zero-user-note-button {
  background: var(--color-grey-500);
}

/* Breakpoints analog bootstrap 4.x */
/**
 * px to rem
 * @requires {variable} $base-font-size
 * @param {number} $target - size to convert
 * @param {number} $context ($base-font-size) - context conversion is based on
 * @example scss
 * .foobar { padding-bottom: rem(30px); }
 * @returns {number}
 */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #061d3c;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: rgb(0, 86.1, 178.5);
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgb(183.6, 218.04, 255);
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: rgb(122.4, 186.36, 255);
}

.table-hover .table-primary:hover {
  background-color: rgb(158.1, 204.84, 255);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: rgb(158.1, 204.84, 255);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgb(213.84, 216.36, 218.6);
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: rgb(178.56, 183.24, 187.4);
}

.table-hover .table-secondary:hover {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgb(194.8, 230.36, 202.92);
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: rgb(143.2, 209.24, 158.28);
}

.table-hover .table-success:hover {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgb(185.28, 191.72, 200.4);
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: rgb(125.52, 137.48, 153.6);
}

.table-hover .table-info:hover {
  background-color: rgb(170.9793243243, 178.7402702703, 189.2006756757);
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: rgb(170.9793243243, 178.7402702703, 189.2006756757);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgb(255, 237.64, 185.56);
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: rgb(255, 222.76, 126.04);
}

.table-hover .table-warning:hover {
  background-color: rgb(255, 231.265, 160.06);
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: rgb(255, 231.265, 160.06);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgb(245.2, 198.44, 202.92);
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: rgb(236.8, 149.96, 158.28);
}

.table-hover .table-danger:hover {
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgb(253.04, 253.32, 253.6);
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: rgb(251.36, 251.88, 252.4);
}

.table-hover .table-light:hover {
  background-color: rgb(238.165, 240.57, 242.975);
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: rgb(238.165, 240.57, 242.975);
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgb(198.16, 199.84, 201.52);
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: rgb(149.44, 152.56, 155.68);
}

.table-hover .table-dark:hover {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: rgb(69.1465517241, 77.125, 85.1034482759);
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgb(69.1465517241, 77.125, 85.1034482759);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--oldblue);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: rgb(51.6956521739, 206.3043478261, 87);
  background-color: rgb(51.6956521739, 206.3043478261, 87);
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: rgb(227.5316455696, 96.4683544304, 109.0253164557);
  background-color: rgb(227.5316455696, 96.4683544304, 109.0253164557);
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .zero-user-note-button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .zero-user-note-button {
    transition: none;
  }
}
.btn:hover, .zero-user-note-button:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .zero-user-note-button:focus, .btn.focus, .focus.zero-user-note-button {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.btn.disabled, .disabled.zero-user-note-button, .btn:disabled, .zero-user-note-button:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled), .zero-user-note-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, a.disabled.zero-user-note-button,
fieldset:disabled a.btn,
fieldset:disabled a.zero-user-note-button {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: rgb(0, 104.55, 216.75);
  border-color: rgb(0, 98.4, 204);
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: rgb(0, 104.55, 216.75);
  border-color: rgb(0, 98.4, 204);
  box-shadow: 0 0 0 0 rgba(38.25, 142.8, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(0, 98.4, 204);
  border-color: rgb(0, 92.25, 191.25);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38.25, 142.8, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  box-shadow: 0 0 0 0 rgba(130.05, 137.7, 144.5, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  border-color: rgb(78.4506437768, 84.9881974249, 90.7993562232);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(130.05, 137.7, 144.5, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: rgb(32.6086956522, 136.1413043478, 56.25);
  border-color: rgb(30.1449275362, 125.8550724638, 52);
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: rgb(32.6086956522, 136.1413043478, 56.25);
  border-color: rgb(30.1449275362, 125.8550724638, 52);
  box-shadow: 0 0 0 0 rgba(72.25, 180.2, 96.9, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
  border-color: rgb(27.6811594203, 115.5688405797, 47.75);
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(72.25, 180.2, 96.9, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #061d3c;
  border-color: #061d3c;
}
.btn-info:hover {
  color: #fff;
  background-color: rgb(2.5227272727, 12.1931818182, 25.2272727273);
  border-color: rgb(1.3636363636, 6.5909090909, 13.6363636364);
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: rgb(2.5227272727, 12.1931818182, 25.2272727273);
  border-color: rgb(1.3636363636, 6.5909090909, 13.6363636364);
  box-shadow: 0 0 0 0 rgba(43.35, 62.9, 89.25, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #061d3c;
  border-color: #061d3c;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(1.3636363636, 6.5909090909, 13.6363636364);
  border-color: rgb(0.2045454545, 0.9886363636, 2.0454545455);
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(43.35, 62.9, 89.25, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
  box-shadow: 0 0 0 0 rgba(221.7, 169.6, 12.1, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: rgb(211, 158.25, 0);
  border-color: rgb(198.25, 148.6875, 0);
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221.7, 169.6, 12.1, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: rgb(200.082278481, 34.667721519, 50.5158227848);
  border-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: rgb(200.082278481, 34.667721519, 50.5158227848);
  border-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
  box-shadow: 0 0 0 0 rgba(225.25, 83.3, 96.9, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
  border-color: rgb(178.3481012658, 30.9018987342, 45.0284810127);
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225.25, 83.3, 96.9, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
  box-shadow: 0 0 0 0 rgba(215.75, 217.2, 218.65, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: rgb(218.25, 223.5, 228.75);
  border-color: rgb(210.8125, 217.125, 223.4375);
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(215.75, 217.2, 218.65, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: rgb(34.8534482759, 38.875, 42.8965517241);
  border-color: rgb(29.1379310345, 32.5, 35.8620689655);
  box-shadow: 0 0 0 0 rgba(82.45, 87.55, 92.65, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
  border-color: rgb(23.4224137931, 26.125, 28.8275862069);
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82.45, 87.55, 92.65, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #061d3c;
  border-color: #061d3c;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #061d3c;
  border-color: #061d3c;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(6, 29, 60, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #061d3c;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #061d3c;
  border-color: #061d3c;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(6, 29, 60, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: rgb(0, 86.1, 178.5);
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .zero-user-note-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .zero-user-note-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: rgb(21.6283783784, 24.25, 26.8716216216);
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .btn-group > .zero-user-note-button,
.btn-group-vertical > .btn,
.btn-group-vertical > .zero-user-note-button {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover, .btn-group > .zero-user-note-button:hover,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .zero-user-note-button:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .zero-user-note-button:focus, .btn-group > .btn:active, .btn-group > .zero-user-note-button:active, .btn-group > .btn.active, .btn-group > .active.zero-user-note-button,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .zero-user-note-button:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .zero-user-note-button:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.zero-user-note-button {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .zero-user-note-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .zero-user-note-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .zero-user-note-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child), .btn-group > .zero-user-note-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .zero-user-note-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .zero-user-note-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .zero-user-note-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .btn-group-vertical > .zero-user-note-button,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .zero-user-note-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .zero-user-note-button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .zero-user-note-button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .zero-user-note-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .zero-user-note-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .zero-user-note-button,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .zero-user-note-button {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .zero-user-note-button input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .zero-user-note-button input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .zero-user-note-button input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .zero-user-note-button input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn, .input-group-prepend .zero-user-note-button,
.input-group-append .btn,
.input-group-append .zero-user-note-button {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus, .input-group-prepend .zero-user-note-button:focus,
.input-group-append .btn:focus,
.input-group-append .zero-user-note-button:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn, .input-group-prepend .zero-user-note-button + .btn, .input-group-prepend .btn + .zero-user-note-button, .input-group-prepend .zero-user-note-button + .zero-user-note-button,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .zero-user-note-button + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .zero-user-note-button,
.input-group-append .btn + .btn,
.input-group-append .zero-user-note-button + .btn,
.input-group-append .btn + .zero-user-note-button,
.input-group-append .zero-user-note-button + .zero-user-note-button,
.input-group-append .btn + .input-group-text,
.input-group-append .zero-user-note-button + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .zero-user-note-button {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .zero-user-note-button,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .zero-user-note-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .zero-user-note-button,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .zero-user-note-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .zero-user-note-button,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .zero-user-note-button,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .zero-user-note-button,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .zero-user-note-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .zero-user-note-button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .zero-user-note-button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .zero-user-note-button:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--oldblue);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: rgb(178.5, 215.4, 255);
  border-color: rgb(178.5, 215.4, 255);
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: var(--oldblue);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: var(--oldblue);
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: rgb(178.5, 215.4, 255);
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: rgb(178.5, 215.4, 255);
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: rgb(178.5, 215.4, 255);
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: rgb(0, 86.1, 178.5);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge, .zero-user-note-button .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: rgb(0, 98.4, 204);
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #061d3c;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: rgb(1.3636363636, 6.5909090909, 13.6363636364);
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(6, 29, 60, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: rgb(211, 158.25, 0);
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: rgb(218.25, 223.5, 228.75);
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: rgb(29.1379310345, 32.5, 35.8620689655);
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: rgb(0, 63.96, 132.6);
  background-color: white;
  border-color: #007bff;
}
.alert-primary hr {
  border-top-color: rgb(0, 110.7, 229.5);
}
.alert-primary .alert-link {
  color: rgb(0, 39.36, 81.6);
}

.alert-secondary {
  color: rgb(56.16, 60.84, 65);
  background-color: white;
  border-color: #6c757d;
}
.alert-secondary hr {
  border-top-color: rgb(96.1802575107, 104.19527897, 111.3197424893);
}
.alert-secondary .alert-link {
  color: rgb(32.5205150215, 35.2305579399, 37.6394849785);
}

.alert-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: white;
  border-color: #28a745;
}
.alert-success hr {
  border-top-color: rgb(35.0724637681, 146.4275362319, 60.5);
}
.alert-success .alert-link {
  color: rgb(10.9449275362, 45.6950724638, 18.88);
}

.alert-info {
  color: rgb(3.12, 15.08, 31.2);
  background-color: white;
  border-color: #061d3c;
}
.alert-info hr {
  border-top-color: rgb(3.6818181818, 17.7954545455, 36.8181818182);
}
.alert-info .alert-link {
  color: black;
}

.alert-warning {
  color: rgb(132.6, 100.36, 3.64);
  background-color: white;
  border-color: #ffc107;
}
.alert-warning hr {
  border-top-color: rgb(236.5, 177.375, 0);
}
.alert-warning .alert-link {
  color: rgb(82.9625954198, 62.7912977099, 2.2774045802);
}

.alert-danger {
  color: rgb(114.4, 27.56, 35.88);
  background-color: white;
  border-color: #dc3545;
}
.alert-danger hr {
  border-top-color: rgb(210.9493670886, 36.5506329114, 53.2594936709);
}
.alert-danger .alert-link {
  color: rgb(73.3010989011, 17.6589010989, 22.9898901099);
}

.alert-light {
  color: rgb(128.96, 129.48, 130);
  background-color: white;
  border-color: #f8f9fa;
}
.alert-light hr {
  border-top-color: rgb(233.125, 236.25, 239.375);
}
.alert-light .alert-link {
  color: rgb(103.5492351816, 103.98, 104.4107648184);
}

.alert-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: white;
  border-color: #343a40;
}
.alert-dark hr {
  border-top-color: rgb(40.5689655172, 45.25, 49.9310344828);
}
.alert-dark .alert-link {
  color: rgb(4.1779310345, 4.66, 5.1420689655);
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(183.6, 218.04, 255);
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(158.1, 204.84, 255);
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(0, 63.96, 132.6);
  border-color: rgb(0, 63.96, 132.6);
}

.list-group-item-secondary {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(213.84, 216.36, 218.6);
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: rgb(56.16, 60.84, 65);
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(56.16, 60.84, 65);
  border-color: rgb(56.16, 60.84, 65);
}

.list-group-item-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(194.8, 230.36, 202.92);
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: rgb(20.8, 86.84, 35.88);
  border-color: rgb(20.8, 86.84, 35.88);
}

.list-group-item-info {
  color: rgb(3.12, 15.08, 31.2);
  background-color: rgb(185.28, 191.72, 200.4);
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: rgb(3.12, 15.08, 31.2);
  background-color: rgb(170.9793243243, 178.7402702703, 189.2006756757);
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: rgb(3.12, 15.08, 31.2);
  border-color: rgb(3.12, 15.08, 31.2);
}

.list-group-item-warning {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 237.64, 185.56);
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: rgb(132.6, 100.36, 3.64);
  background-color: rgb(255, 231.265, 160.06);
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: rgb(132.6, 100.36, 3.64);
  border-color: rgb(132.6, 100.36, 3.64);
}

.list-group-item-danger {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(245.2, 198.44, 202.92);
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: rgb(114.4, 27.56, 35.88);
  border-color: rgb(114.4, 27.56, 35.88);
}

.list-group-item-light {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(253.04, 253.32, 253.6);
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: rgb(128.96, 129.48, 130);
  background-color: rgb(238.165, 240.57, 242.975);
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: rgb(128.96, 129.48, 130);
  border-color: rgb(128.96, 129.48, 130);
}

.list-group-item-dark {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(198.16, 199.84, 201.52);
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: rgb(27.04, 30.16, 33.28);
  background-color: rgb(185.0216751269, 187.09, 189.1583248731);
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: rgb(27.04, 30.16, 33.28);
  border-color: rgb(27.04, 30.16, 33.28);
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid rgb(247.35, 247.35, 247.35);
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: rgb(247.35, 247.35, 247.35);
  border-bottom: 1px solid rgb(234.6, 234.6, 234.6);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: rgb(0, 98.4, 204) !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785) !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: rgb(30.1449275362, 125.8550724638, 52) !important;
}

.bg-info {
  background-color: #061d3c !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: rgb(1.3636363636, 6.5909090909, 13.6363636364) !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: rgb(211, 158.25, 0) !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: rgb(189.2151898734, 32.7848101266, 47.7721518987) !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: rgb(218.25, 223.5, 228.75) !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgb(29.1379310345, 32.5, 35.8620689655) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #061d3c !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(0, 86.1, 178.5) !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(72.5407725322, 78.5858369099, 83.9592274678) !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(25.2173913043, 105.2826086957, 43.5) !important;
}

.text-info {
  color: #061d3c !important;
}

a.text-info:hover, a.text-info:focus {
  color: black !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(185.5, 139.125, 0) !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: rgb(167.4810126582, 29.0189873418, 42.2848101266) !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(203.375, 210.75, 218.125) !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: rgb(17.7068965517, 19.75, 21.7931034483) !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn):not(.zero-user-note-button) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@font-face {
  font-family: "Work Sans";
  src: url("/assets/fonts/WorkSans-VariableFont_wght.ttf") format("truetype");
}
:root {
  --font-light: "wght" 300;
  --font-regular: "wght" 400;
  --font-medium: "wght" 500;
  --font-bold: "wght" 600;
}

.font-light {
  font-variation-settings: var(--font-light);
}

.font-regular {
  font-variation-settings: var(--font-regular);
}

.font-medium {
  font-variation-settings: var(--font-medium);
}

.font-bold {
  font-variation-settings: var(--font-bold);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 150px;
}
.container.onboarding,
.container-fluid.onboarding,
.onboarding.container-sm,
.onboarding.container-md,
.onboarding.container-lg,
.onboarding.container-xl {
  margin-bottom: 200px;
}
@media (min-width: 767px) {
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-bottom: 0;
  }
}

.form-control {
  background: transparent;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
}

.form-control:focus {
  background: transparent;
}

.modal-open .modal {
  overflow-y: scroll;
}

.modal-fullscreen {
  max-width: 100%;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
}

.btn, .zero-user-note-button {
  border-radius: 0px;
  border-width: 0px;
  padding: 0.25rem 1rem;
}
.btn:disabled, .zero-user-note-button:disabled {
  cursor: not-allowed;
}

.nav-tabs {
  border-bottom: none;
  display: flex;
  justify-content: flex-start;
  margin: -8px 0px 8px -8px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  background: none;
  color: var(--color-grey-50);
}
.nav-tabs .nav-link {
  position: relative;
  border-bottom: 4px solid transparent;
  width: auto;
  text-align: center;
  font-size: 15px;
  text-transform: capitalize;
  cursor: pointer;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover {
  color: var(--color-blue-400);
  border-bottom: 4px solid var(--color-blue-400);
}
.nav-tabs a.nav-link {
  color: #abb5bf;
  padding: 0px;
}
.nav-tabs .nav-link:hover {
  color: var(--color-blue-400);
  border-color: transparent;
}
.nav-tabs .nav-item {
  margin: 8px;
}

/*** nav-tabs strong version ****/
.nav-tabs--strong {
  border-bottom: 4px solid var(--color-grey-500);
  padding-right: inherit;
}

.nav-tabs.nav-tabs--strong .nav-item {
  margin-bottom: -3px;
}

.nav-tabs--strong .nav-link {
  border-bottom: 4px solid transparent;
}

.nav-tabs--strong .nav-item .nav-link.active {
  border-bottom: 4px solid var(--oldblue);
}

.nav-tabs--strong .nav-link.active:after,
.nav-tabs--strong .nav-link:hover:after {
  content: "";
  height: 18px;
  width: 30px;
  position: absolute;
  left: 50%;
  bottom: -4px;
  margin-left: -12px;
  background-size: 30px 30px;
  z-index: 1;
}

.nav-tabs--strong .nav-link:hover.active:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23173365' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav-tabs--strong .nav-link.active:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23173365' stroke-width='10' stroke='%23173365'/%3E%3C/svg%3E");
}

.nav-tabs--strong .nav-link:hover:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23173365' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav-tabs--white-triangle .nav-link:hover.active:after,
.nav-tabs--white-triangle .nav-link.active:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav-tabs--white-triangle .nav-link:hover:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav-tabs--strong .nav-link:focus,
.nav-tabs--strong .nav-link:hover {
  border-bottom: 4px solid var(--color-blue-400);
}

@media screen and (max-width: 767px) {
  .nav-tabs--strong .nav-link:hover.active:after,
  .nav-tabs.nav-tabs--strong .nav-link:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
  }
  .nav-tabs--strong .nav-link:hover:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23FFFFFF' stroke-width='10' stroke='%235ca3ef'/%3E%3C/svg%3E");
  }
  .nav-tabs--strong {
    margin-bottom: 10px;
  }
}
/* nav tabs progress bar variation */
.nav.nav-tabs--stepper {
  width: 100%;
}

.nav.nav-tabs--stepper .nav-item {
  padding-left: 15px;
  padding-right: 15px;
}

.nav.nav-tabs--stepper .nav-link {
  border-bottom: 1px solid var(--color-grey-300);
  color: var(--color-grey-300);
}

.nav.nav-tabs--stepper .nav-link.active:after,
.nav.nav-tabs--stepper .nav-link.active:hover:after,
.nav.nav-tabs--stepper .nav-link.active:focus:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23ffffff' stroke-width='5' stroke='%235ca3ef'/%3E%3C/svg%3E");
}

.nav.nav-tabs--stepper .nav-link.active,
.nav.nav-tabs--stepper .nav-link.active:hover,
.nav.nav-tabs--stepper .nav-link.active:focus {
  border-bottom: 4px solid var(--color-blue-400);
  color: var(--color-blue-400);
}

.nav.nav-tabs--stepper .nav-link.done:after,
.nav.nav-tabs--stepper .nav-link.done:hover:after,
.nav.nav-tabs--stepper .nav-link.done:focus:after {
  background-image: none;
}

.nav.nav-tabs--stepper .nav-link.done,
.nav.nav-tabs--stepper .nav-link.done:hover,
.nav.nav-tabs--stepper .nav-link.done:focus {
  border-bottom: 1px solid var(--color-blue-400);
  color: var(--color-blue-400);
}

/* no hover state wanted in nav-tabs--stepper */
.nav.nav-tabs--stepper .nav-link:hover,
.nav.nav-tabs--stepper .nav-link:focus {
  border-color: transparent;
  border-bottom: 1px solid var(--color-grey-300);
  color: var(--color-grey-300);
  cursor: inherit;
}

.nav.nav-tabs--stepper .nav-link:focus:after,
.nav.nav-tabs--stepper .nav-link:hover:after {
  background-image: none;
}

/* radio buttons */
.form-check-label {
  margin-left: 10px;
  font-size: 14px;
}

.badge {
  display: inline-block;
}
.badge a {
  color: var(--color-blue-400);
}

.badge-pill {
  font-size: 10px;
  font-weight: unset;
  font-variation-settings: var(--font-bold);
  position: relative;
  top: -10px;
  right: -4px;
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem 0.125rem 0.25rem;
}

.badge-primary,
.badge-primary-inactive,
.badge-secondary {
  background: rgba(71, 160, 255, 0.2);
  color: var(--color-blue-400);
  border-radius: 4px;
}

.badge-secondary {
  background-color: var(--color-red-500);
  color: var(--white);
}

.badge-primary-inactive {
  background-color: var(--color-grey-500);
  color: var(--oldblue);
}

.badge-outline {
  color: var(--color-grey-500);
  border: 1px solid var(--color-grey-500);
}

.badge-danger {
  color: var(--color-grey-50);
  background-color: var(--color-grey-800);
}

.badge-alert {
  color: #ffffff;
  background-color: var(--color-red-500);
}

@media screen and (max-width: 576px) {
  :not(.container-fluid):not(.container-sm):not(.container-md):not(.container-lg):not(.container-xl) > .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.alert-dismissible {
  padding: 0.75rem 1.25rem;
}
.alert-dismissible .close {
  opacity: 1;
}
.alert-dismissible .close:hover {
  color: inherit;
}

.close {
  text-shadow: none;
  opacity: 1;
}

hr {
  border-width: 1px;
  border-color: var(--color-grey-500);
}
hr.full-size {
  margin: 0 -15px 15px -15px;
}

.dropup .dropdown-toggle::after {
  border: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0px 12px 24px -5px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
}
@media screen and (max-width: 374px) {
  .dropdown-item {
    padding: 0.5rem 0.5rem;
    font-size: 12px;
  }
}

* {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-variant-numeric: tabular-nums;
  overflow-y: scroll;
  position: relative;
}
body.dark.zero-backdrop.main-backdrop, body.dark.main-backdrop.tile, body.dark.main-backdrop.alert {
  background: var(--oldblue);
}
body.dark.zero-backdrop.main-backdrop h1, body.dark.main-backdrop.tile h1, body.dark.main-backdrop.alert h1,
body.dark.zero-backdrop.main-backdrop button,
body.dark.main-backdrop.tile button,
body.dark.main-backdrop.alert button {
  color: var(--white);
}
body.dark.zero-backdrop.main-backdrop footer, body.dark.main-backdrop.tile footer, body.dark.main-backdrop.alert footer {
  color: var(--white);
}
body.dark.zero-backdrop.main-backdrop footer a, body.dark.main-backdrop.tile footer a, body.dark.main-backdrop.alert footer a,
body.dark.zero-backdrop.main-backdrop footer svg,
body.dark.main-backdrop.tile footer svg,
body.dark.main-backdrop.alert footer svg {
  color: var(--color-grey-500);
}

/*** Make content fill screen so that footer is at bottom of viewport ***/
zero-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

caption {
  display: none;
}

router-outlet + * {
  flex: 1;
}

strong {
  font-variation-settings: var(--font-bold);
  font-weight: inherit;
}

blockquote {
  padding: 1rem;
  background: var(--color-grey-800);
}

h5.anchor {
  position: relative;
}
h5.anchor span {
  position: absolute;
  top: -150px;
}

a:hover,
a:focus {
  text-decoration: none;
}

.no-focus-outline:focus {
  outline: none;
}

.no-selection::selection {
  background: none;
}

input,
textarea {
  color: #495057;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-moz-selection {
  color: var(--white);
  background: var(--color-blue-400);
}

::selection {
  color: var(--white);
  background: var(--color-blue-400);
}

.text-nowrap {
  white-space: nowrap;
}

.truncate {
  width: 126px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.countdown-container {
  visibility: hidden;
  position: relative;
  height: 20px;
  width: 20px;
  text-align: center;
}
.countdown-container.active {
  visibility: visible;
}
.countdown-container .countdown-number {
  color: var(--color-blue-400);
  display: inline-block;
  position: relative;
  font-size: 10px;
  bottom: 3px;
}
.countdown-container svg.countdown-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}
.countdown-container svg.countdown-icon circle {
  stroke-dasharray: 56.5px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: var(--color-blue-400);
  fill: none;
  animation: countdown 30s linear infinite forwards;
}
@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 56.5px;
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  background-color: #f3f5f6;
}

.circle-filled {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  color: var(--color-blue-400);
  background: color-mix(in srgb, var(--color-blue-400) 20%, transparent);
}

sup {
  font-size: 16px;
  vertical-align: super;
  top: -4px;
  right: 0px;
}
sup.order {
  font-size: 12px;
  right: 0px;
  top: 7px;
}

.text-transform-none {
  text-transform: none !important;
}

.app-version {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 10px;
}

.loading-shimmer {
  background: #f3f5f6;
  background-image: linear-gradient(to right, #f3f5f6 0%, #fafafb 20%, #e4eaef 40%, #e4eaef 100%);
  background-repeat: no-repeat;
  background-size: 800px 275px;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  -webkit-animation-name: placeholderShimmer;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  border-radius: 5px;
}
.loading-shimmer.performance-header-value {
  height: 46px;
  width: 100%;
  margin-top: 10px;
}
.loading-shimmer.performance-header-perf {
  height: 18px;
  width: 100%;
  margin-top: 10px;
}
.loading-shimmer.position {
  height: 18px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.loading-shimmer.position.random {
  width: 66%;
}
.loading-shimmer.trade-tick {
  height: 24px;
  width: 100%;
  margin-bottom: -5px;
}
.loading-shimmer.headline {
  height: 24px;
  width: 33.3%;
}
.loading-shimmer.multi-order-strategy {
  width: 100%;
  height: 275px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.spacer-default {
  height: 30px;
}

.zero-text, .zero-user-note-button {
  font-style: normal;
}

.zero-text.default, .zero-user-note-button {
  color: var(--color-grey-50);
}

.zero-text.btn, .zero-user-note-button, .zero-text.white {
  color: var(--white);
}

.zero-text.link, .link.zero-user-note-button {
  color: var(--color-blue-400);
}

.zero-text.link.disabled, .link.disabled.zero-user-note-button {
  cursor: not-allowed;
  text-decoration: none;
}

.zero-text.link:not(.disabled):hover, .link.zero-user-note-button:not(.disabled):hover {
  color: var(--color-blue-500);
}

.zero-text.label, .label.zero-user-note-button {
  color: var(--color-grey-400);
}

.zero-text.disabled, .disabled.zero-user-note-button {
  color: var(--color-grey-500);
}

.zero-text.course-red, .course-red.zero-user-note-button {
  color: var(--color-red-400);
}

.zero-text.course-green, .course-green.zero-user-note-button {
  color: var(--color-green-400);
}

.zero-text.dark-red, .dark-red.zero-user-note-button {
  color: var(--color-red-100);
}

.zero-text.dark-green, .dark-green.zero-user-note-button {
  color: var(--color-green-100);
}

.zero-text.dark-blue, .dark-blue.zero-user-note-button {
  color: var(--color-blue-50);
}

.zero-text.dark-purple, .dark-purple.zero-user-note-button {
  color: var(--color-purple-100);
}

.zero-text.warning, .warning.zero-user-note-button {
  color: var(--color-yellow-400);
}

.zero-text.p-base-lig, .p-base-lig.zero-user-note-button, .zero-text.p-base-reg, .p-base-reg.zero-user-note-button, .zero-text.p-base-med, .p-base-med.zero-user-note-button, .zero-text.p-base-bold, .p-base-bold.zero-user-note-button {
  font-size: 1rem;
  line-height: 22.4px;
}

.zero-text.p-base-lig, .p-base-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}

.zero-text.p-base-reg, .p-base-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}

.zero-text.p-base-med, .p-base-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}

.zero-text.p-base-bold, .p-base-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-text.p-small-lig, .p-small-lig.zero-user-note-button, .zero-text.p-small-reg, .p-small-reg.zero-user-note-button, .zero-text.p-small-med, .p-small-med.zero-user-note-button, .zero-text.p-small-bold, .p-small-bold.zero-user-note-button {
  font-size: 14px;
  line-height: 19.6px;
}

.zero-text.p-small-lig, .p-small-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}

.zero-text.p-small-reg, .p-small-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}

.zero-text.p-small-med, .p-small-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}

.zero-text.p-small-bold, .p-small-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-text.p-xsmall-lig, .p-xsmall-lig.zero-user-note-button, .zero-text.p-xsmall-reg, .p-xsmall-reg.zero-user-note-button, .zero-text.p-xsmall-med, .p-xsmall-med.zero-user-note-button, .zero-text.p-xsmall-bold, .p-xsmall-bold.zero-user-note-button {
  font-size: 12px;
  line-height: 16.8px;
}

.zero-text.p-xsmall-lig, .p-xsmall-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}

.zero-text.p-xsmall-reg, .p-xsmall-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}

.zero-text.p-xsmall-med, .p-xsmall-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}

.zero-text.p-xsmall-bold, .p-xsmall-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-text.p-big-lig, .p-big-lig.zero-user-note-button, .zero-text.p-big-reg, .p-big-reg.zero-user-note-button, .zero-text.p-big-med, .p-big-med.zero-user-note-button, .zero-text.p-big-bold, .p-big-bold.zero-user-note-button {
  font-size: 18px;
  line-height: 25.2px;
}

.zero-text.p-big-lig, .p-big-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}

.zero-text.p-big-reg, .p-big-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}

.zero-text.p-big-med, .p-big-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}

.zero-text.p-big-bold, .p-big-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-text.p-bigger-lig, .p-bigger-lig.zero-user-note-button, .zero-text.p-bigger-reg, .p-bigger-reg.zero-user-note-button, .zero-text.p-bigger-med, .p-bigger-med.zero-user-note-button, .zero-text.p-bigger-bold, .p-bigger-bold.zero-user-note-button {
  font-size: 20px;
  line-height: 28px;
}

.zero-text.p-bigger-lig, .p-bigger-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}

.zero-text.p-bigger-reg, .p-bigger-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}

.zero-text.p-bigger-med, .p-bigger-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}

.zero-text.p-bigger-bold, .p-bigger-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-text.p-huge-lig, .p-huge-lig.zero-user-note-button, .zero-text.p-huge-reg, .p-huge-reg.zero-user-note-button, .zero-text.p-huge-med, .p-huge-med.zero-user-note-button, .zero-text.p-huge-bold, .p-huge-bold.zero-user-note-button {
  font-size: 26px;
  line-height: 36.4px;
}

.zero-text.p-huge-lig, .p-huge-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}

.zero-text.p-huge-reg, .p-huge-reg.zero-user-note-button {
  font-variation-settings: var(--font-regular);
}

.zero-text.p-huge-med, .p-huge-med.zero-user-note-button {
  font-variation-settings: var(--font-medium);
}

.zero-text.p-huge-bold, .p-huge-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-text.h1-lig, .h1-lig.zero-user-note-button, .zero-text.h1-bold, .h1-bold.zero-user-note-button {
  font-size: 32px;
  line-height: 44.8px;
}

.zero-text.h1-lig, .h1-lig.zero-user-note-button {
  font-variation-settings: var(--font-light);
}

.zero-text.h1-bold, .h1-bold.zero-user-note-button {
  font-variation-settings: var(--font-bold);
}

.zero-fix.white {
  color: var(--color-white);
}

.zero-fix.black {
  color: var(--color-black);
}

.zero-surface.oldblue {
  background: var(--oldblue);
}

.zero-surface.grey-50 {
  background: var(--black);
}

.zero-surface.grey-100 {
  background: var(--color-grey-100);
}

.zero-surface.grey-200 {
  background: var(--color-grey-200);
}

.zero-surface.grey-300 {
  background: var(--color-grey-300);
}

.zero-surface.grey-400 {
  background: var(--color-grey-400);
}

.zero-surface.grey-500 {
  background: var(--color-grey-500);
}

.zero-surface.grey-600 {
  background: var(--color-grey-600);
}

.zero-surface.grey-700 {
  background: var(--color-grey-700);
}

.zero-surface.grey-800 {
  background: var(--color-grey-800);
}

.zero-surface.grey-900 {
  background: var(--color-grey-900);
}

.zero-surface.primary-50 {
  background: var(--color-blue-50);
}

.zero-surface.primary-100 {
  background: var(--color-blue-100);
}

.zero-surface.primary-200 {
  background: var(--color-blue-200);
}

.zero-surface.primary-300 {
  background: var(--color-blue-300);
}

.zero-surface.primary-400 {
  background: var(--color-blue-400);
}

.zero-surface.primary-500 {
  background: var(--color-blue-500);
}

.zero-surface.primary-600 {
  background: var(--color-blue-600);
}

.zero-surface.primary-700 {
  background: var(--color-blue-700);
}

.zero-surface.primary-800 {
  background: var(--color-blue-800);
}

.zero-surface.primary-900 {
  background: var(--color-blue-900);
}

.zero-surface.secondary-100 {
  background: var(--color-green-100);
}

.zero-surface.secondary-200 {
  background: var(--color-green-200);
}

.zero-surface.secondary-300 {
  background: var(--color-green-300);
}

.zero-surface.secondary-400 {
  background: var(--color-green-400);
}

.zero-surface.secondary-500 {
  background: var(--color-green-500);
}

.zero-surface.secondary-600 {
  background: var(--color-green-600);
}

.zero-surface.secondary-700 {
  background: var(--color-green-700);
}

.zero-surface.secondary-800 {
  background: var(--color-green-800);
}

.zero-surface.tertiary-100 {
  background: var(--color-red-100);
}

.zero-surface.tertiary-200 {
  background: var(--color-red-200);
}

.zero-surface.tertiary-300 {
  background: var(--color-red-300);
}

.zero-surface.tertiary-400 {
  background: var(--color-red-400);
}

.zero-surface.tertiary-500 {
  background: var(--color-red-500);
}

.zero-surface.tertiary-600 {
  background: var(--color-red-600);
}

.zero-surface.tertiary-700 {
  background: var(--color-red-700);
}

.zero-surface.tertiary-800 {
  background: var(--color-red-800);
}

.zero-surface.yellow-100 {
  background: var(--color-yellow-100);
}

.zero-surface.yellow-200 {
  background: var(--color-yellow-200);
}

.zero-surface.yellow-300 {
  background: var(--color-yellow-300);
}

.zero-surface.yellow-400 {
  background: var(--color-yellow-400);
}

.zero-surface.yellow-500 {
  background: var(--color-yellow-500);
}

.zero-surface.yellow-600 {
  background: var(--color-yellow-600);
}

.zero-surface.yellow-700 {
  background: var(--color-yellow-700);
}

.zero-surface.yellow-800 {
  background: var(--color-yellow-800);
}

.zero-surface.purple-100 {
  background: var(--color-purple-100);
}

.zero-surface.purple-200 {
  background: var(--color-purple-200);
}

.zero-surface.purple-300 {
  background: var(--color-purple-300);
}

.zero-surface.purple-400 {
  background: var(--color-purple-400);
}

.zero-surface.purple-500 {
  background: var(--color-purple-500);
}

.zero-surface.purple-600 {
  background: var(--color-purple-600);
}

.zero-surface.purple-700 {
  background: var(--color-purple-700);
}

.zero-surface.purple-800 {
  background: var(--color-purple-800);
}

.zero-surface.pink-100 {
  background: var(--color-pink-100);
}

.zero-surface.pink-200 {
  background: var(--color-pink-200);
}

.zero-surface.pink-300 {
  background: var(--color-pink-300);
}

.zero-surface.pink-400 {
  background: var(--color-pink-400);
}

.zero-surface.pink-500 {
  background: var(--color-pink-500);
}

.zero-surface.pink-600 {
  background: var(--color-pink-500);
}

.zero-surface.pink-700 {
  background: var(--color-pink-700);
}

.zero-surface.pink-800 {
  background: var(--color-pink-800);
}

.zero-button.default, .zero-user-note-button {
  background: var(--color-blue-400);
}

.zero-button.secondary, .secondary.zero-user-note-button {
  background: transparent;
}

.zero-button.disabled, .disabled.zero-user-note-button {
  background: var(--color-grey-500);
}

.zero-button.red, .red.zero-user-note-button {
  background: var(--color-red-300);
  border: 1px solid var(--color-red-400);
}

.zero-button.green, .green.zero-user-note-button {
  background: var(--color-green-300);
  border: 1px solid var(--color-green-400);
}

.zero-button.gold, .gold.zero-user-note-button {
  background: var(--gold);
  border: 1px solid var(--gold);
}

.zero-button.default-light, .default-light.zero-user-note-button {
  background: var(--color-blue-900);
}

.zero-button.red-light, .red-light.zero-user-note-button {
  background: var(--color-red-800);
}

.zero-button.green-light, .green-light.zero-user-note-button {
  background: var(--color-green-800);
}

.zero-button.grey-light, .grey-light.zero-user-note-button {
  background: var(--color-grey-800);
}

.zero-icon.default, .zero-icon.zero-user-note-button {
  color: var(--color-black);
}

.zero-icon.action {
  color: var(--color-blue-400);
}

.zero-icon.dimmed {
  color: var(--color-grey-500);
}

.zero-icon.white {
  color: var(--white);
}

.zero-icon.red {
  color: var(--color-red-200);
}

.zero-icon.green {
  color: var(--color-green-200);
}

.zero-icon.blue {
  color: var(--color-blue-100);
}

.zero-icon.purple {
  color: var(--color-purple-100);
}

.zero-backdrop:not(body), .tile:not(body), .alert:not(body) {
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
}

.zero-backdrop.main-backdrop, .main-backdrop.tile, .main-backdrop.alert {
  background: var(--color-grey-800);
}

.zero-backdrop.box-white, .tile, .box-white.alert {
  background: var(--white);
}

.zero-backdrop.gradient, .gradient.tile, .gradient.alert {
  margin: 0;
  background: linear-gradient(to top right, rgba(255, 0, 168, 0.3) 10%, rgba(255, 0, 168, 0) 50%, rgba(92, 163, 239, 0) 60%, rgba(92, 163, 239, 0.3) 90%);
  background-color: #061d3c;
}

.zero-backdrop.main-dark-blue, .main-dark-blue.tile, .main-dark-blue.alert {
  background: var(--oldblue);
  color: var(--color-grey-400);
}

.zero-strokes.default, .zero-strokes.zero-user-note-button {
  background: var(--color-grey-500);
}

.btn, .zero-user-note-button {
  font-weight: unset;
  font-variation-settings: var(--font-regular);
}
.btn.fit, .fit.zero-user-note-button {
  width: unset;
}
@media (max-width: 768px) {
  .btn, .zero-user-note-button {
    font-size: 12px;
  }
}
.btn.btn-toggle, .btn-toggle.zero-user-note-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--color-blue-400);
}
.btn.btn-toggle.active, .btn-toggle.active.zero-user-note-button {
  background: var(--color-blue-400);
  color: var(--white);
}
.btn.btn-sm, .btn-sm.zero-user-note-button, .btn-group-sm > .btn, .btn-group-sm > .zero-user-note-button {
  line-height: 1;
}

button[aria-label=Close]:focus {
  outline: none;
}

.trade {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue-400);
  border-radius: 3px;
  border: none;
  outline: none;
  transition: all 0.15s ease-in-out;
}
.trade.small {
  font-size: 14px;
  width: 24px;
  height: 24px;
}
.trade.buy:hover:not(:disabled), .trade.sell:hover:not(:disabled), .trade.savings-plan:hover:not(:disabled), .trade.info:hover:not(:disabled) {
  filter: brightness(85%);
}
.trade.buy {
  background: color-mix(in srgb, var(--color-green-400) 20%, transparent);
  color: var(--color-green-400);
  margin-left: 5px;
}
.trade.sell {
  background: color-mix(in srgb, var(--color-red-400) 20%, transparent);
  color: var(--color-red-400);
  margin-left: 5px;
}
.trade.savings-plan, .trade.info {
  background: rgba(71, 160, 255, 0.2);
}
.trade.instrument-detail-header, .trade.more {
  box-shadow: none;
}
.trade.instrument-detail-header {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  text-transform: uppercase;
}
.trade.instrument-detail-header.savings-plan {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trade.instrument-detail-header.buy {
  border: 1px solid var(--color-green-400);
  background: #cbecd8;
  color: var(--color-green-400);
}
.trade.instrument-detail-header.sell {
  border: 1px solid var(--color-red-400);
  background: #f4cece;
  color: var(--color-red-400);
}
.trade.instrument-detail-header.buy:disabled, .trade.instrument-detail-header.sell:disabled {
  border: 1px solid color-mix(in srgb, var(--color-grey-700) 40%, transparent);
  color: var(--color-grey-500);
  background: color-mix(in srgb, var(--color-grey-700) 40%, transparent);
  cursor: not-allowed;
}
.trade.instrument-detail-header .quote {
  font-size: 11px;
  font-variation-settings: var(--font-regular);
  position: relative;
  top: -3px;
}
.trade.more {
  width: unset;
  font-size: 0.75rem;
}
.trade.search {
  min-width: 24px;
  height: 24px;
  box-shadow: none;
}

.further {
  height: 48px;
  width: 48px;
  border-radius: 6px;
  background-color: rgba(238, 240, 242, 0.7);
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
.further.active {
  cursor: pointer;
}

.detract-attention .btn-success {
  background: color-mix(in srgb, var(--color-grey-300) 20%, transparent);
}
.detract-attention .btn-success:hover, .detract-attention .btn-success:focus, .detract-attention .btn-success:active {
  background: color-mix(in srgb, var(--color-grey-300) 20%, transparent);
}

.btn-success {
  display: block;
  border-radius: 4px;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--color-green-400);
  background: color-mix(in srgb, var(--color-green-400) 20%, transparent);
}
@media (min-width: 768px) {
  .btn-success {
    width: 200px;
  }
}
.btn-success:hover, .btn-success:focus, .btn-success:active {
  color: var(--color-green-400);
  background: color-mix(in srgb, var(--color-green-400) 20%, transparent);
}

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: var(--color-blue-900);
  transition: all 0.15s ease-in-out;
}
.btn-icon.disabled {
  cursor: not-allowed;
  background: color-mix(in srgb, var(--color-blue-400) 5%, transparent);
}
.btn-icon .icon:not(.icon-inactive) {
  color: var(--color-blue-400);
}
.btn-icon .icon-inactive {
  color: color-mix(in srgb, var(--color-blue-400) 10%, transparent);
}
.btn-icon:hover:not(:has(.icon-inactive)) {
  filter: brightness(95%);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .btn-icon,
  .trade {
    width: 30px;
    height: 30px;
  }
}

.full-width-on-small-devices button {
  display: inline-block;
  width: 100%;
}
@media (min-width: 576px) {
  .full-width-on-small-devices button {
    width: 320px;
  }
}

.zero-user-note-button {
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 11px 16px;
}
.zero-user-note-button:hover {
  color: var(--white);
}

.border-color-basic {
  border-color: var(--oldblue) !important;
}

.border-color-success {
  border-color: var(--color-green-400) !important;
}
.border-color-success input {
  border-color: var(--color-green-400) !important;
}

.border-top {
  border-top: 1px solid var(--color-grey-700);
}

.border-attention {
  border: 2px solid var(--color-blue-400);
}

.border-error {
  border: 2px solid var(--color-red-400);
}

.b-r-4 {
  border-radius: 4px;
}

.border,
.b-r-8 {
  border-radius: 8px;
}

.border-primary {
  border: 1px solid var(--oldblue);
}

.border-default {
  border: 1px solid var(--color-grey-700);
}

.gap {
  gap: 1rem;
}

.gap-3-quarters {
  gap: 0.75rem;
}

.gap-half {
  gap: 0.5rem;
}

.gap-small {
  gap: 0.25rem;
}

.gap-large {
  gap: 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.gb-cc-dialog {
  color: var(--white);
  background: var(--oldblue);
  opacity: 0.95;
  box-shadow: 0px 0 25px -5px var(--oldblue);
}

.gb-cc-dialog .cc-deny {
  border: 0;
}

.cc-btn + .cc-btn {
  margin-left: 0;
}

.gb-cc-dialog .cc-message {
  margin: 1rem !important;
  margin-bottom: 1.5rem !important;
}

.cc-window.cc-floating {
  padding: 0.5rem;
}

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-banner,
  .cc-window.cc-floating,
  .cc-window.cc-left,
  .cc-window.cc-right {
    left: 1%;
    right: 1%;
  }
}
.cc-link:active,
.cc-link:visited {
  color: var(--oldblue);
}

/**** Icons ****/
.icon {
  display: inline-flex;
}
.icon-rotate {
  transform: rotate(180deg);
}

.icon-10 {
  width: 10px;
  height: 10px;
}

.icon-12 {
  width: 12px;
  height: 12px;
}

.icon-14 {
  width: 14px;
  height: 14px;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.icon-21 {
  width: 21px;
  height: 21px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.icon-29 {
  width: 29px;
  height: 29px;
}

.icon-32 {
  width: 32px;
  height: 32px;
}

.icon-48 {
  width: 48px;
  height: 48px;
}

.icon-64 {
  width: 64px;
  height: 64px;
}

.icon-70 {
  width: 70px;
  height: 70px;
}

.icon-logo-with-text {
  height: 29px;
  width: 152px;
}

.icon-logo-text-only {
  height: 1rem;
  width: 10rem;
}

.icon-partner-logo {
  height: 60px;
}
.icon-partner-logo img {
  height: 100%;
}

.icon-partner-logo-hvb {
  height: 60px;
}
.icon-partner-logo-hvb img {
  width: 95%;
}

.icon-partner-slider-logo-container {
  padding: 0 10px;
}

.icon-partner-slider-logo {
  height: 42px;
}
.icon-partner-slider-logo.hsbc {
  width: 118.9967555041px;
}
.icon-partner-slider-logo.hvb {
  width: 313.072769409px;
}
.icon-partner-slider-logo.dws {
  width: 140.4785631518px;
}
.icon-partner-slider-logo.amundi {
  width: 113.5070683662px;
}
@media (max-width: 991.98px) {
  .icon-partner-slider-logo.hsbc {
    width: 89.247566628px;
  }
  .icon-partner-slider-logo.hvb {
    width: 234.8045770568px;
  }
  .icon-partner-slider-logo.dws {
    width: 105.3589223638px;
  }
  .icon-partner-slider-logo.amundi {
    width: 85.1303012746px;
  }
}

.icon-active path {
  fill: var(--oldblue);
}

.icon-inactive path {
  fill: var(--color-grey-300);
}

.icon-mobile-table {
  width: 7.5rem;
  height: 1.4rem;
}

.haken {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.1213 1.06066L6.06066 13.1213L0 7.06066L1.06066 6L6.06066 11L17.0607 0L18.1213 1.06066Z' fill='%23278565'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
}

.cross {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85457' height='24' width='24'%3E%3Cpath d='M22.245 4.015a.808.808 0 0 1 0 1.139l-6.276 6.27a.81.81 0 0 0 0 1.14l6.273 6.272a.81.81 0 0 1 0 1.14l-2.285 2.277a.814.814 0 0 1-1.142 0l-6.271-6.271a.81.81 0 0 0-1.141 0l-6.276 6.267a.81.81 0 0 1-1.141 0l-2.282-2.28a.81.81 0 0 1 0-1.14l6.278-6.269a.81.81 0 0 0 0-1.14L1.709 5.147a.808.808 0 0 1 0-1.14l2.284-2.278a.813.813 0 0 1 1.142.001L11.405 8a.81.81 0 0 0 1.141.001l6.276-6.267a.812.812 0 0 1 1.141 0l2.282 2.281z'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
}

.icon-home {
  height: 300px;
}
.icon-home.width100Pct {
  width: 100%;
}

.icon-founder {
  height: 300px;
}

@media screen and (max-width: 399px) {
  .icon-founder .svganimation svg {
    width: 100% !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 576px) {
  .icon-founder {
    margin-bottom: -65px;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .icon-founder {
    margin-bottom: -110px;
  }
}
.icon-myths {
  width: 100%;
  height: 17rem;
}

.thumbnails-gallery {
  max-width: 226px;
  height: 180px;
}

.lightbox {
  display: flex !important;
  flex-direction: column-reverse;
}

.box-shadow {
  box-shadow: 4px 4px 4px 0px var(--color-grey-300);
}

.icon-active {
  color: var(--oldblue);
  fill: none;
}
.icon-active.icon-filled {
  fill: var(--oldblue);
}

.icon-inactive {
  fill: none;
}
.icon-inactive.icon-filled {
  fill: var(--color-grey-300);
}

.icon-light {
  color: var(--white);
  fill: var(--white);
}

.icon-dark {
  color: var(--oldblue);
  fill: var(--oldblue);
}

.icon-secondary {
  color: var(--color-blue-400);
  fill: var(--color-blue-400);
}
.icon-secondary.icon-active {
  fill: var(--color-blue-400);
}
.icon-secondary.icon-inactive {
  fill: none;
}

.playbutton-primary {
  color: var(--color-grey-500);
}

.playbutton-white {
  color: var(--white);
}

.rot-90 {
  transform: rotate(90deg);
}

.rot-180 {
  transform: rotate(180deg);
}

.rot-270 {
  transform: rotate(270deg);
}

img.blackwhite {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.icon-prod-ov, .icon-kundewerben {
  width: 110px;
  height: 110px;
}

.background-logo {
  background-repeat: no-repeat;
  background-position: 110% 110%;
  background-size: 9rem 9rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'  viewBox='0 0 42 42' style='enable-background:new 0 0 42 42;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0{fill:%23FFFFFF; fill-opacity:0.1875;} %3C/style%3E %3Cg%3E %3Cg%3E %3Cpath class='st0' d='M41,21c0,11-8.9,20-19.9,20C10,41,1,32.1,1,21C1,10,9.9,1,20.9,1C32,1,40.9,9.9,41,21 M21.1,34.5 c3.2,0,7.3-0.6,9.2-1.1l0-14.9h-7.1v9.6c-0.5,0-1.3,0.1-2.1,0.1c-1.8,0-2.6-0.7-2.6-2.5v-1.9h2v-2.3h-2v-0.8h2v-2.3h-2l0-1.3 c0-2.1,0.9-2.8,3.2-2.8c2.3,0,5.1,0.3,7,0.8l0.8-6.3c-2.7-0.7-5.7-1-8.7-1c-7.2,0-10.2,2.4-10.2,9l0,1.7H8v2.3h2.6v0.8H8v2.3h2.6 l0,2.5C10.6,31.9,13.9,34.5,21.1,34.5'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
.background-logo.panel-header {
  background-position: 80% 50%;
  background-size: 7rem 7rem;
  padding: 0rem 1.25rem;
  min-height: 4rem;
}

.svganimation {
  min-height: 300px;
  min-width: 300px;
}
.svganimation canvas,
.svganimation svg {
  width: inherit !important;
  height: 300px !important;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
  .svganimation {
    width: inherit;
    height: 300.2px;
  }
  .svganimation canvas,
  .svganimation svg {
    width: inherit !important;
    height: 300.1px !important;
  }
}

.img-founders {
  max-width: 140%;
}

@media screen and (max-width: 576px) {
  .icon-kundewerben {
    width: 75px;
  }
}

@media screen and (max-width: 400px) {
  .icon-claim {
    width: 90%;
  }
}

.savings-plan .icon image {
  width: 100%;
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.app-icon {
  width: 200px;
  height: 59px;
}
.app-icon.small {
  width: 150px;
  height: 45px;
}

.icon-footer-logo {
  height: 50px;
  width: 156px;
}

.icon-zero-main {
  width: 148px;
  height: 48px;
}

.link {
  color: var(--color-grey-500);
}
.link:not(.cursor-auto) {
  cursor: pointer;
}

.link-underline {
  text-decoration: underline;
}

.link:hover,
.link:focus {
  color: var(--color-grey-500);
  text-decoration: underline;
}

.link:active {
  color: var(--color-grey-500);
}

.link-primary {
  color: var(--oldblue);
}
.link-primary:hover, .link-primary:focus {
  text-decoration: underline;
  color: var(--color-blue-400);
}

.link-secondary {
  font-size: 14px;
  color: var(--color-blue-400);
}
.link-secondary:hover:not(.disabled), .link-secondary:focus:not(.disabled) {
  color: var(--color-blue-400);
  text-decoration: underline;
}
.link-secondary.disabled {
  color: var(--color-grey-300);
  cursor: not-allowed;
}

.link-white {
  color: var(--white);
}
.link-white:hover, .link-white:focus {
  color: var(--color-grey-500);
}

.link-black {
  color: var(--color-grey-50);
}
.link-black:hover, .link-black:focus {
  color: var(--color-grey-50);
}

.underline-hover:hover {
  text-decoration: underline;
}

.link-underline .link-warning {
  color: var(--color-red-400);
}
.link-underline .link-warning:hover, .link-underline .link-warning:focus {
  color: var(--color-red-400);
  text-decoration: underline;
}

.link,
.link-primary,
.link-secondary,
.link-underline,
.link-warning,
.link-white {
  transition: 0.4s;
}

/**** Typography ****/
.text-size-xsmall {
  font-size: 11px;
}

.text-size-small {
  font-size: 12px;
}

.text-size-medium {
  font-size: 13px;
}

.text-size-large {
  font-size: 14px;
}

.text-size-xlarge {
  font-size: 15px;
}

.text-default {
  font-size: 1rem;
}

.text-size-xl {
  font-size: 1.25rem;
}

.text-size-xxl {
  font-size: 28px;
}

.text-size-important {
  font-size: 2rem;
}

.text-size-huge {
  font-size: 64px;
}

.line-height-small {
  line-height: 120%;
}

/**** Text & Typography ****/
.color-tertiary {
  color: var(--white);
}

.text-color-primary {
  color: var(--color-grey-50);
}

.text-primary {
  color: var(--oldblue);
}

.text-disabled {
  color: var(--color-grey-300);
}

label.rised.text-color-success,
.text-color-success {
  color: var(--color-green-400);
}

.text-color-area-ok {
  color: var(--color-green-400);
}

.text-color-error {
  color: var(--color-red-400);
}

.text-color-area-warning {
  color: var(--color-red-400);
}

.text-color-basic {
  color: var(--oldblue);
}

.text-color-secondary {
  color: var(--color-grey-500);
}

.color-secondary {
  color: var(--color-blue-400);
}

.text-color-dark-blue {
  color: var(--oldblue);
}

.text-color-grey {
  color: var(--color-grey-200);
}

.text-color-alert {
  color: var(--color-red-500);
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-bold,
.text-bold-medium {
  font-variation-settings: var(--font-bold);
}

.search-highlight {
  background-color: var(--color-blue-400);
}

.text-strike-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

/*
  creates font-size-classes for every grid breakpoint and size in $font-sizes.
  E.g: font-size-2, font-size-md-smaller1, font-size-lg-base etc.
 */
.font-size-smaller2 {
  font-size: 0.75rem !important;
}

.font-size-smaller1 {
  font-size: 0.875rem !important;
}

.font-size-base {
  font-size: 16px !important;
}

.font-size-6 {
  font-size: 1rem !important;
}

.font-size-5 {
  font-size: 1.25rem !important;
}

.font-size-4 {
  font-size: 1.5rem !important;
}

.font-size-3 {
  font-size: 1.75rem !important;
}

.font-size-2 {
  font-size: 2rem !important;
}

.font-size-1 {
  font-size: 2.5rem !important;
}

@media (min-width: 576px) {
  .font-size-sm-smaller2 {
    font-size: 0.75rem !important;
  }
  .font-size-sm-smaller1 {
    font-size: 0.875rem !important;
  }
  .font-size-sm-base {
    font-size: 16px !important;
  }
  .font-size-sm-6 {
    font-size: 1rem !important;
  }
  .font-size-sm-5 {
    font-size: 1.25rem !important;
  }
  .font-size-sm-4 {
    font-size: 1.5rem !important;
  }
  .font-size-sm-3 {
    font-size: 1.75rem !important;
  }
  .font-size-sm-2 {
    font-size: 2rem !important;
  }
  .font-size-sm-1 {
    font-size: 2.5rem !important;
  }
}
@media (min-width: 768px) {
  .font-size-md-smaller2 {
    font-size: 0.75rem !important;
  }
  .font-size-md-smaller1 {
    font-size: 0.875rem !important;
  }
  .font-size-md-base {
    font-size: 16px !important;
  }
  .font-size-md-6 {
    font-size: 1rem !important;
  }
  .font-size-md-5 {
    font-size: 1.25rem !important;
  }
  .font-size-md-4 {
    font-size: 1.5rem !important;
  }
  .font-size-md-3 {
    font-size: 1.75rem !important;
  }
  .font-size-md-2 {
    font-size: 2rem !important;
  }
  .font-size-md-1 {
    font-size: 2.5rem !important;
  }
}
@media (min-width: 992px) {
  .font-size-lg-smaller2 {
    font-size: 0.75rem !important;
  }
  .font-size-lg-smaller1 {
    font-size: 0.875rem !important;
  }
  .font-size-lg-base {
    font-size: 16px !important;
  }
  .font-size-lg-6 {
    font-size: 1rem !important;
  }
  .font-size-lg-5 {
    font-size: 1.25rem !important;
  }
  .font-size-lg-4 {
    font-size: 1.5rem !important;
  }
  .font-size-lg-3 {
    font-size: 1.75rem !important;
  }
  .font-size-lg-2 {
    font-size: 2rem !important;
  }
  .font-size-lg-1 {
    font-size: 2.5rem !important;
  }
}
@media (min-width: 1200px) {
  .font-size-xl-smaller2 {
    font-size: 0.75rem !important;
  }
  .font-size-xl-smaller1 {
    font-size: 0.875rem !important;
  }
  .font-size-xl-base {
    font-size: 16px !important;
  }
  .font-size-xl-6 {
    font-size: 1rem !important;
  }
  .font-size-xl-5 {
    font-size: 1.25rem !important;
  }
  .font-size-xl-4 {
    font-size: 1.5rem !important;
  }
  .font-size-xl-3 {
    font-size: 1.75rem !important;
  }
  .font-size-xl-2 {
    font-size: 2rem !important;
  }
  .font-size-xl-1 {
    font-size: 2.5rem !important;
  }
}
.line-height-3 {
  line-height: 1.8125;
}

.datenschutztext h2 {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.datenschutztext h4 {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
.datenschutztext h5 {
  margin-top: 1.25rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-transform-unset {
  text-transform: initial;
}

.highlighted {
  display: inline-block;
  padding: 2px 4px;
  text-transform: uppercase;
  font-size: 0.5rem;
  white-space: nowrap;
  font-variation-settings: var(--font-medium);
  border-radius: 3px;
  border: solid 1px var(--color-grey-700);
}
@media (min-width: 576px) {
  .highlighted {
    font-size: 0.75rem;
  }
}

.text-no-wrap {
  white-space: nowrap;
}

.row--primary,
.row--secondary {
  width: 100%;
  margin: 0 auto;
}

.row--primary {
  max-width: 720px;
}

.row--secondary {
  max-width: 916px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.spacer-top-navigation {
  margin-top: 6.375rem;
}

@media screen and (max-width: 991px) {
  .spacer-top-navigation {
    margin-top: 4rem;
  }
}
.padding-5 {
  padding: 5px;
}

.padding-15 {
  padding: 15px;
}

/* left paddings */
.padding-left-5 {
  padding-left: 5px;
}

@media screen and (max-width: 575px) {
  .padding-xs-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-5 {
    padding-left: 5px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-5 {
    padding-left: 5px;
  }
}
.padding-left-10 {
  padding-left: 10px;
}

@media screen and (max-width: 575px) {
  .padding-xs-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-10 {
    padding-left: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-10 {
    padding-left: 10px;
  }
}
.padding-left-15 {
  padding-left: 15px;
}

@media screen and (max-width: 575px) {
  .padding-xs-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-15 {
    padding-left: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-15 {
    padding-left: 15px;
  }
}
.padding-left-24 {
  padding-left: 24px;
}

.padding-left-30 {
  padding-left: 30px;
}

@media screen and (max-width: 575px) {
  .padding-xs-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-left-30 {
    padding-left: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-left-30 {
    padding-left: 30px;
  }
}
/* right paddings */
.padding-right-5 {
  padding-right: 5px;
}

@media screen and (max-width: 575px) {
  .padding-xs-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-5 {
    padding-right: 5px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-lg-right-5 {
    padding-right: 5px;
  }
}
.padding-right-10 {
  padding-right: 10px;
}

@media screen and (max-width: 575px) {
  .padding-xs-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-10 {
    padding-right: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-right-10 {
    padding-right: 10px;
  }
}
.padding-right-15 {
  padding-right: 15px;
}

@media screen and (max-width: 575px) {
  .padding-xs-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-15 {
    padding-right: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-right-15 {
    padding-right: 15px;
  }
}
.padding-right-30 {
  padding-right: 30px;
}

@media screen and (max-width: 575px) {
  .padding-xs-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-right-30 {
    padding-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-right-30 {
    padding-right: 30px;
  }
}
/* padding top */
.padding-top-2 {
  padding-top: 2px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-30 {
  padding-top: 30px;
}

@media screen and (max-width: 575px) {
  .padding-xs-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-top-15 {
    padding-top: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-top-15 {
    padding-top: 15px;
  }
}
/* padding bottom */
.padding-bottom-2 {
  padding-bottom: 2px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

@media screen and (max-width: 575px) {
  .padding-xs-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .padding-sm-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .padding-md-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-lg-bottom-15 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .padding-xl-bottom-15 {
    padding-bottom: 15px;
  }
}
/* margins ***/
.margin-left-2-2 {
  margin-left: 2.2rem;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

@media screen and (max-width: 575px) {
  .margin-xs-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-left-10 {
    margin-left: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-left-10 {
    margin-left: 10px;
  }
}
.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

@media screen and (max-width: 575px) {
  .margin-xs-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-right-15 {
    margin-right: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-right-15 {
    margin-right: 15px;
  }
}
.margin-right-30 {
  margin-right: 30px;
}

@media screen and (max-width: 575px) {
  .margin-xs-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-right-30 {
    margin-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-right-30 {
    margin-right: 30px;
  }
}
.margin-top-5 {
  margin-top: 5px;
}

.margin-top-8 {
  margin-top: 0.5rem;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-15 {
  margin-top: 15px;
}

@media screen and (max-width: 575px) {
  .margin-xs-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-top-15 {
    margin-top: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-top-15 {
    margin-top: 15px;
  }
}
.margin-top-30 {
  margin-top: 30px;
}

@media screen and (max-width: 575px) {
  .margin-xs-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-top-30 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-top-30 {
    margin-top: 30px;
  }
}
.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

@media screen and (max-width: 575px) {
  .margin-xs-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .margin-sm-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .margin-md-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lg-bottom-15 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .margin-xl-bottom-15 {
    margin-bottom: 15px;
  }
}
.hidden {
  display: none !important;
}

@media screen and (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}
.flex-grow {
  flex: 1;
}

@media screen and (max-width: 575px) {
  .flex-xs-grow {
    flex-grow: 1;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .flex-sm-grow {
    flex-grow: 1;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flex-md-grow {
    flex-grow: 1;
  }
}
.flex-even {
  flex: 1;
}

.minw-15 {
  min-width: 15rem;
}

.minh-55 {
  min-height: 5.5rem;
}

.max-w-7 {
  max-width: 7rem;
}

.min-w-4 {
  min-width: 4rem;
}

.min-w-7 {
  min-width: 7rem;
}

.min-w-9 {
  min-width: 9rem;
}

.min-w-12 {
  min-width: 12rem;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-75 {
  max-width: 75%;
}

.max-w-100 {
  max-width: 100%;
}

.w-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.w-one-third {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .w-one-third {
    width: 33.333333%;
  }
}
@media screen and (max-width: 575px) {
  .max-w-xs-75 {
    max-width: 75%;
  }
}
/*
  creates width-classes for every grid breakpoint and size in $pct-widths.
  E.g: width-25, width-gs, width-md-25, width-lg-75 etc.
 */
.width-50 {
  width: 50% !important;
}

.width-75 {
  width: 75% !important;
}

.width-100 {
  width: 100% !important;
}

.width-gs {
  width: 37.5% !important;
}

@media (min-width: 576px) {
  .width-sm-50 {
    width: 50% !important;
  }
  .width-sm-75 {
    width: 75% !important;
  }
  .width-sm-100 {
    width: 100% !important;
  }
  .width-sm-gs {
    width: 37.5% !important;
  }
}
@media (min-width: 768px) {
  .width-md-50 {
    width: 50% !important;
  }
  .width-md-75 {
    width: 75% !important;
  }
  .width-md-100 {
    width: 100% !important;
  }
  .width-md-gs {
    width: 37.5% !important;
  }
}
@media (min-width: 992px) {
  .width-lg-50 {
    width: 50% !important;
  }
  .width-lg-75 {
    width: 75% !important;
  }
  .width-lg-100 {
    width: 100% !important;
  }
  .width-lg-gs {
    width: 37.5% !important;
  }
}
@media (min-width: 1200px) {
  .width-xl-50 {
    width: 50% !important;
  }
  .width-xl-75 {
    width: 75% !important;
  }
  .width-xl-100 {
    width: 100% !important;
  }
  .width-xl-gs {
    width: 37.5% !important;
  }
}
@media screen and (min-height: 761px) {
  .view-height-100 {
    height: 100vh;
  }
}
.flex-auto {
  flex: auto;
}

.navigation-hamburger {
  padding: 15px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger:hover {
  opacity: 0.7;
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger .navigation-hamburger-inner,
.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger .navigation-hamburger-inner::before,
.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger .navigation-hamburger-inner::after {
  background-color: var(--white);
}

.navigation-hamburger-box {
  width: 28px;
  height: 22px;
  display: inline-block;
  position: relative;
}

.navigation-hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.navigation-hamburger-inner,
.navigation-hamburger-inner::before,
.navigation-hamburger-inner::after {
  width: 26px;
  height: 3px;
  background-color: var(--white);
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.navigation-hamburger-inner::before,
.navigation-hamburger-inner::after {
  content: "";
  display: block;
}

.navigation-hamburger--spring .navigation-hamburger-inner {
  top: 3px;
  transition: background-color 0s 0.13s linear;
}

.navigation-hamburger--spring .navigation-hamburger-inner::before {
  top: 0.5rem;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navigation-hamburger--spring .navigation-hamburger-inner::after {
  top: 1rem;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger--spring .navigation-hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger--spring .navigation-hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 0.5rem, 0) rotate(45deg);
}

.navigation-submenu-checkbox:checked ~ .navigation-hamburger-wrapper .navigation-hamburger--spring .navigation-hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 0.5rem, 0) rotate(-45deg);
}

/**** Navigation Main ****/
/*************************/
.navigation a {
  color: var(--white);
}

.navigation {
  height: 3rem;
  position: fixed;
  top: 0;
  z-index: 10;
}
@media (min-width: 992px) {
  .navigation {
    height: 4.5rem;
  }
}

.navigation-inner {
  justify-content: space-between;
}

.navigation-submenu {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem;
  height: 100%;
  align-items: center;
}

.navigation-submenu-item {
  font-size: 1rem;
  font-variation-settings: var(--font-bold);
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  cursor: pointer;
  outline: none;
}

.navigation-submenu-item a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-asset-search .header-search-input-container input,
.mobile-search .header-search-input-container input {
  width: 100%;
  height: 40px;
  padding: 8px 0 8px 40px;
  border-radius: 6px;
  border: none;
}
@media (min-width: 1199px) {
  .navigation-asset-search .header-search-input-container input,
  .mobile-search .header-search-input-container input {
    width: 251px;
  }
}
.navigation-asset-search .header-search-input-container .input-group-material-icon,
.mobile-search .header-search-input-container .input-group-material-icon {
  position: absolute;
  left: 8px;
  top: 16px;
  height: unset;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
@media (min-width: 991px) {
  .navigation-asset-search .header-search-input-container .input-group-material-icon,
  .mobile-search .header-search-input-container .input-group-material-icon {
    left: 0;
    top: 0;
    height: 100%;
  }
}

.item-highlight {
  position: absolute;
  top: 25px;
  width: 100%;
  height: 2px;
}

.navigation-submenu-item svg {
  fill: var(--white);
}

.navigation-submenu-item .navigation-submenu-list {
  display: none;
}

.navigation-submenu-item--hover:hover .navigation-submenu-list,
.navigation-submenu-item--hover:focus .navigation-submenu-list {
  display: block;
}

.navigation-submenu-item--open .navigation-submenu-list {
  display: block;
}

.navigation-submenu-item-checkbox {
  display: none;
}

.navigation-submenu-list {
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: none;
  background: var(--oldblue);
  min-width: 140px;
  position: absolute;
  top: 52px;
  border-top: 0.07rem solid var(--oldblue);
  margin-left: 0;
  z-index: 2;
}

.navigation-submenu-list-icon {
  position: absolute;
  left: 50%;
  top: -14px;
  margin-left: -9px;
}

.navigation-submenu-list-item {
  padding: 0.5rem 1rem 0.5rem 1rem;
  white-space: nowrap;
}

.navigation-submenu-list-item a {
  display: block;
  color: var(--white);
  text-decoration: none;
  border-bottom: 0.07rem solid transparent;
}

.navigation-submenu-item-absolute-primary {
  padding-left: 0;
  padding-right: 0;
}

.navigation-submenu-item-absolute-secondary {
  padding-left: 0;
  padding-right: 10px;
}

.navigation-hamburger-wrapper {
  z-index: 1;
}

.navigation-submenu-checkbox {
  display: none;
  opacity: 0;
  z-index: 10;
  background: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 5px;
  border: 0.15rem solid var(--color-red-400);
}

@media screen and (max-width: 575px) {
  .navigation {
    align-items: center;
  }
  .navigation-inner {
    flex-direction: column;
    position: relative;
  }
  .navigation-submenu-item {
    width: 100%;
    font-size: 2rem;
    font-variation-settings: var(--font-medium);
    height: auto;
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    line-height: 2.75rem;
  }
  .navigation-submenu-list-item {
    font-size: 1.25rem;
    font-weight: 400;
  }
  .navigation-submenu-item-absolute-primary {
    position: absolute;
    right: 0;
    width: auto;
    height: 40px;
    top: 5px;
    padding-right: 15px;
    border-bottom: none;
  }
  .navigation-submenu-item-absolute-secondary {
    position: absolute;
    right: 47px;
    width: auto;
    height: 40px;
    top: 7px;
    border-bottom: none;
  }
  .navigation-submenu-list {
    position: relative;
    top: inherit;
    border-bottom: 0.08rem solid var(--color-blue-400);
    line-height: 2.25rem;
    padding-top: 0;
  }
  /* open submenu-item-list on hover and on click checkbox */
  .navigation-submenu-item-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 31px;
    opacity: 0;
    display: block;
    cursor: pointer;
  }
  .navigation-submenu-item-checkbox ~ .navigation-submenu-list {
    display: none;
  }
  .navigation-submenu-item-checkbox:checked ~ .navigation-submenu-list {
    display: block !important;
  }
  .navigation-hamburger-wrapper {
    display: block;
  }
  .navigation-submenu-checkbox {
    display: block;
    cursor: pointer;
  }
  .navigation-submenu {
    border-bottom: 0.24rem solid var(--oldblue);
    position: absolute;
    height: auto;
    top: 35px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    transform: translatex(100%);
    transition: all 0.25s;
    width: calc(100% + 30px);
    padding-left: 0;
    padding-right: 0;
    margin: 0 -15px;
    background: var(--oldblue);
  }
  .navigation-submenu-checkbox:checked ~ .navigation-submenu {
    transform: translatex(0) !important;
    opacity: 1;
  }
  .navigation-submenu {
    display: none !important;
  }
  /* just a display none/block on submenu */
  .navigation-submenu-checkbox:checked ~ .navigation-submenu {
    display: block !important;
  }
  /* just a display none/block on submenu */
  .navigation-inner {
    align-items: flex-start !important;
    padding-left: 0;
    padding-right: 0;
  }
  .navigation-submenu-item {
    display: block;
    text-align: center;
  }
  .navigation-submenu-item a {
    justify-content: center;
  }
  .navigation-submenu-item-checkbox {
    width: 40px;
    right: 0;
    left: inherit;
    opacity: 0;
    z-index: 1000;
  }
  .navigation-submenu-list {
    background: transparent;
    border-bottom: none;
    border-top: none;
  }
  .navigation-submenu-list-icon {
    display: none;
  }
  .navigation-submenu-item > a:before {
    content: "";
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
  }
  .navigation-submenu-item > a:after {
    content: "";
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='none' stroke-width='7' stroke='%23ffffff'/%3E%3C/svg%3E");
    background-size: 17px 12px;
    z-index: 100;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    transition: transform 0.25s linear;
  }
  .navigation-submenu-item .navigation-submenu-item-checkbox:checked + a:after,
  .navigation-submenu-item.navigation-submenu-item--open a:after {
    transform: rotate(0deg);
  }
}
.navigation-positioning-mobile {
  position: absolute;
}

.link-selector .dropdown-menu {
  z-index: 5;
  border-radius: unset;
  background: var(--white);
  box-shadow: 1px 1px 11px 0px var(--color-grey-500);
  border: 0;
  line-height: 1.5;
  padding: 10px 15px;
}
.link-selector .dropdown-menu .link-selector-link {
  font-variation-settings: var(--font-regular);
  display: block;
}
.link-selector .dropdown-toggle::after {
  border: inherit;
  vertical-align: inherit;
  transform: rotate(180deg);
}
.link-selector.show.dropdown .dropdown-toggle::after {
  transform: rotate(0deg);
}

.navigation-header {
  background: var(--oldblue);
  width: 100%;
  font-size: 1rem;
  color: var(--white);
}
@media screen and (min-width: 991px) {
  .navigation-header {
    height: 72px;
  }
}
.navigation-header * {
  outline: none;
}
.navigation-header .navigation-submenu-item--active {
  box-shadow: inset 8px 0 var(--color-blue-400);
}
.navigation-header .navigation-submenu-item--active .item-active {
  display: none;
}
.navigation-header .navigation-hamburger-wrapper {
  display: block;
}
.navigation-header .navigation-hamburger-wrapper .navigation-hamburger-inner::before {
  top: 0.5rem;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.navigation-header .navigation-hamburger-wrapper .navigation-hamburger-inner::after {
  top: 1rem;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.navigation-header.open .navigation-hamburger-inner {
  background-color: color-mix(in srgb, var(--white) 0%, transparent);
}
.navigation-header.open .navigation-hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 0.5rem, 0) rotate(45deg);
  background-color: #fff4f4;
}
.navigation-header.open .navigation-hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 0.5rem, 0) rotate(-45deg);
  background-color: #fff4f4;
}
.navigation-header.open .navigation-items {
  margin-top: 3rem;
  display: block;
  width: 80%;
  height: calc(100vh - 3rem);
  overflow-y: scroll;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--oldblue);
}
.navigation-header.open .navigation-items .navigation-item {
  padding: 0.3rem 2rem;
  font-size: 1rem;
}
.navigation-header.open .navigation-items .navigation-item:first-of-type {
  padding-top: 1rem;
}
@media (max-width: 991px) {
  .navigation-header.open .navigation-items .navigation-item:first-of-type {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.navigation-header .inner {
  display: flex;
  height: 100%;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .navigation-header .inner {
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 0 16px;
  }
}
@media (min-width: 1500px) {
  .navigation-header .inner {
    justify-content: center;
  }
}
.navigation-header .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 155px;
}
.navigation-header .logo-container .iban {
  font-size: 12px;
  letter-spacing: 0.17px;
  font-weight: normal;
  color: var(--white);
  margin-top: 5px;
}
.navigation-header .navigation-mobile-search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.navigation-header .navigation-items {
  display: none;
}
.navigation-header .navigation-items.open {
  margin-top: 2rem;
  display: block;
  width: 80%;
  height: 100vh;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--oldblue);
}
.navigation-header .navigation-items.open .navigation-item {
  padding: 0.5rem 2rem;
}
.navigation-header .navigation-action-bar {
  display: none;
}
.navigation-header .private-links {
  position: absolute;
  display: none;
  border-radius: 4px;
}
@media (min-width: 992px) {
  .navigation-header .navigation-hamburger-wrapper {
    display: none;
  }
  .navigation-header .logo-container {
    justify-content: flex-end;
  }
  .navigation-header .navigation-submenu-item--active {
    box-shadow: none;
  }
  .navigation-header .navigation-submenu-item--active .item-active {
    display: block;
    background: var(--color-grey-800);
  }
  .navigation-header .navigation-submenu-item--active .private-links-link {
    border-bottom: 4px solid var(--color-blue-400);
  }
  .navigation-header .navigation-action-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 992px) and (min-width: 1500px) {
  .navigation-header .navigation-action-bar {
    width: 155px;
    justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .navigation-header .navigation-action-bar .action-bar-item {
    position: relative;
    cursor: pointer;
  }
  .navigation-header .navigation-action-bar .action-bar-item:not(:last-child) {
    margin-right: 1rem;
  }
  .navigation-header .navigation-action-bar .profile-icon-wrapper {
    background: linear-gradient(var(--color-red-500), var(--color-blue-400));
    border-radius: 24px;
  }
  .navigation-header .navigation-action-bar .profile-icon {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    width: 40px;
    height: 40px;
  }
  .navigation-header .navigation-action-bar .profile-icon .icon {
    padding: 7px;
  }
  .navigation-header .navigation-action-bar .profile-icon .badge {
    position: absolute;
    left: 1.6875rem;
    top: -0.25rem;
    width: fit-content;
  }
  .navigation-header .navigation-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 32px;
    width: 100%;
  }
}
@media (min-width: 992px) and (min-width: 1500px) {
  .navigation-header .navigation-items {
    width: 1140px;
    padding: 0 16px;
  }
}
@media (min-width: 992px) {
  .navigation-header .navigation-items .navigation-item {
    position: relative;
    cursor: pointer;
    margin: 0 0.5rem;
    font-size: 0.875rem;
    white-space: nowrap;
  }
}
@media (min-width: 992px) and (min-width: 1500px) {
  .navigation-header .navigation-items .navigation-item {
    font-size: 1rem;
    margin: 0 1rem;
  }
}
@media (min-width: 992px) {
  .navigation-header .navigation-items .navigation-item.navigation-item-mobile {
    display: none;
  }
  .navigation-header .navigation-items .navigation-item:hover .item-highlight {
    display: block;
    background: var(--color-grey-800);
    background-color: var(--color-grey-500);
    transition: all 0.25s;
  }
  .navigation-header .navigation-items .navigation-item-seperator,
  .navigation-header .navigation-items .search-outer {
    display: none;
  }
  .navigation-header .private-links {
    display: none;
    border-radius: 10px;
    position: absolute;
    top: 45px;
    right: -15px;
    right: 0;
    white-space: nowrap;
    padding: 0.5rem;
    background: var(--oldblue);
  }
  .navigation-header .private-links-long {
    width: auto;
    left: -13rem;
  }
  .navigation-header .private-links.open {
    display: block;
  }
  .navigation-header .private-links .navigation-item {
    position: relative;
    margin-right: 0;
    padding: 0.5rem;
    width: fit-content;
  }
  .navigation-header .private-links .navigation-item:hover .highlight {
    display: block;
    background: var(--color-grey-800);
    transition: all 0.25s;
  }
  .navigation-header .private-links .navigation-item .highlight {
    position: absolute;
    top: 35px;
    width: calc(100% - 16px);
    height: 2px;
  }
}

.second-level-navigation {
  position: fixed;
  top: 80px;
  width: 100%;
  background: var(--white);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-grey-800);
}
.second-level-navigation .item {
  padding: 8px;
  margin: 0px 8px 0 16px;
  font-variation-settings: var(--font-bold);
  font-size: 1rem;
  color: var(--color-grey-500);
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  border-bottom: 3px solid transparent;
}
.second-level-navigation .item:hover, .second-level-navigation .item.active {
  border-color: var(--color-blue-400);
}

.trans-zoom {
  transition: transform 300ms ease;
}
.trans-zoom:hover {
  transform: scale(1.05, 1.05);
}

.opacity-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.25s linear;
}
.opacity-container.no-max-height {
  max-height: unset;
}
.opacity-container.active {
  opacity: 1;
  max-height: 100%;
  overflow: visible;
}

/**** material design input/textarea/dropdown ****/
/*************************************************/
/*
  Spin-Buttons in Chrome deaktivieren
 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
}

/**
 verhindert einen roten Rand im Firefox um input-Felder
 */
input:required {
  box-shadow: none;
}

/* SASS module start input-group-material */
/******************************************/
.input-group-material {
  position: relative;
  width: 100%;
  /* additional classes on input-group-material */
  /* light version */
  /* small version */
  /* error state for small version */
  /* small version end */
  /* input variation as dropdown */
}
.input-group-material.input-group-right .input-group-material-icon {
  right: 0;
  top: 1rem;
}
@media (min-width: 992px) {
  .input-group-material.input-group-right .input-group-material-icon {
    top: 0.5rem;
  }
}
.input-group-material.asset-search-header {
  width: 100%;
}
.input-group-material .inbox-search input {
  border: none;
}
.input-group-material input,
.input-group-material textarea {
  border: 1px solid var(--color-grey-500);
  border-radius: 4px;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  font-variation-settings: var(--font-regular);
  letter-spacing: 0.11px;
  line-height: normal;
  padding: 1rem 0;
  text-indent: 1rem;
  transition: border 0.3s ease-in-out;
  width: 100%;
  outline: none;
  height: 3rem;
}
.input-group-material input::placeholder,
.input-group-material textarea::placeholder {
  color: var(--color-grey-50) -lighter;
}
.input-group-material input:-webkit-autofill,
.input-group-material textarea:-webkit-autofill {
  box-shadow: 0 0 0px 1000px var(--white) inset;
  -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
}
.input-group-material input:-webkit-autofill::first-line,
.input-group-material textarea:-webkit-autofill::first-line {
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  font-variation-settings: var(--font-regular);
  line-height: normal;
}
.input-group-material input.asset-search-input,
.input-group-material textarea.asset-search-input {
  background: none;
  color: gray;
  font-size: 16px;
  width: 100%;
}
.input-group-material input.date,
.input-group-material textarea.date {
  color: var(--color-grey-500);
}
.input-group-material.input-group-material-small.input-group-material--light > label.asset-search-header-label {
  text-transform: none;
  font-size: 0.875rem;
}
@media (min-width: 1500px) {
  .input-group-material.input-group-material-small.input-group-material--light > label.asset-search-header-label {
    font-size: 1.25rem;
  }
}
.input-group-material.input-group-material-small.input-group-material--light input:focus ~ label.asset-search-header-label {
  font-size: 0.75rem;
}
.input-group-material textarea {
  height: 6rem;
  padding: 1rem 1rem;
  text-indent: 0;
}
.input-group-material textarea.expanded {
  height: 12rem;
}
.input-group-material label {
  color: var(--color-grey-50);
  font-size: 0.75rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: -1.2rem;
  left: 0;
  transition: 200ms ease all;
}
.input-group-material label .label-required {
  font-size: 0.75rem;
}
.input-group-material input:not(.empty).ng-valid ~ label,
.input-group-material textarea:not(.empty).ng-valid ~ label {
  color: var(--oldblue);
}
.input-group-material input:not(.empty) ~ label .label-required:not(.no-fade),
.input-group-material textarea:not(.empty) ~ label .label-required:not(.no-fade),
.input-group-material web-components-phone-input:not(.empty) ~ label .label-required:not(.no-fade),
.input-group-material textarea:not(.empty).ng-valid ~ label .label-required:not(.no-fade),
.input-group-material textarea:not(.empty).ng-invalid ~ label .label-required:not(.no-fade),
.input-group-material input:focus ~ label .label-required:not(.no-fade),
.input-group-material textarea:focus ~ label .label-required:not(.no-fade),
.input-group-material select:focus ~ label .label-required:not(.no-fade),
.input-group-material select:not(.empty) ~ label .label-required:not(.no-fade),
.input-group-material select.ng-invalid:not(.ng-pristine) ~ label .label-required:not(.no-fade) {
  opacity: 0;
}
.input-group-material .input-group-material-icon {
  display: inline-block;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  color: var(--color-grey-500);
}
.input-group-material .input-group-material-icon.top-space {
  top: 1rem;
}
.input-group-material .input-group-material-icon ~ label {
  font-size: 16px;
}
.input-group-material .input-group-material-icon ~ input {
  color: var(--color-grey-500);
  padding-left: 22px;
}
.input-group-material .input-group-material-icon .icon {
  width: 14px;
  height: 14px;
  fill: var(--color-grey-500);
}
.input-group-material .input-group-material-icon-right {
  position: absolute;
  top: 2px;
  right: 0;
  width: 20px;
  color: var(--color-grey-500);
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-group-material .input-group-material-message {
  visibility: visible;
  font-size: 0.75rem;
  padding-top: 2px;
  margin-bottom: 4px;
  color: var(--color-grey-50);
}
.input-group-material .input-group-material-icon-state {
  display: block;
  position: absolute;
  right: 0px;
  top: -13px;
  height: 15px;
  width: 15px;
}
.input-group-material.input-group-material-message-hidden .input-group-material-message {
  visibility: hidden !important;
}
.input-group-material.input-group-material--centered {
  text-align: center;
}
.input-group-material.input-group-material--centered input {
  text-align: center;
  padding-left: 22px;
}
.input-group-material.input-group-material--centered label {
  width: 100%;
}
.input-group-material.input-group-material--light input,
.input-group-material.input-group-material--light textarea {
  border-bottom-color: var(--color-grey-800);
  color: var(--white);
}
.input-group-material.input-group-material--light input ~ label,
.input-group-material.input-group-material--light input:focus ~ label, .input-group-material.input-group-material--light.keepcolor input.ng-valid ~ label, .input-group-material.input-group-material--light.keepcolor input:not(.empty).ng-valid ~ label, .input-group-material.input-group-material--light.keepcolor input:focus.ng-valid ~ label {
  color: var(--color-grey-800);
}
.input-group-material.input-group-material--light input:not(.empty).ng-valid ~ label {
  color: var(--color-green-400);
}
.input-group-material.input-group-material-small label {
  font-size: 0.875rem;
}
.input-group-material.input-group-material-small input {
  font-size: 0.875rem;
  height: 1.75rem;
}
.input-group-material.input-group-material-small input:focus ~ label, .input-group-material.input-group-material-small input:not(.empty).ng-valid ~ label, .input-group-material.input-group-material-small textarea:focus ~ label, .input-group-material.input-group-material-small textarea:not(.empty).ng-valid ~ label {
  top: -0.75rem;
  font-size: 0.75rem;
}
.input-group-material.input-group-material-small .input-group-material-icon {
  display: inline-flex;
  position: absolute;
  top: 0.5rem;
}
.input-group-material.input-group-material-small .input-group-material-icon .icon {
  width: 14px;
  height: 14px;
}
.input-group-material.input-group-material-small input:not(.ng-pristine).ng-valid ~ .input-group-material-icon-state:after {
  width: 12px;
  height: 12px;
}
.input-group-material.input-group-material-small .input-group-material-icon ~ input {
  padding-left: 18px;
  font-size: 0.875rem;
}
.input-group-material.input-group-material-small .input-group-material-icon ~ input.asset-search-input {
  padding-left: 2px;
}
.input-group-material.input-group-material-small .input-group-material-icon ~ label {
  font-size: 0.875rem;
  top: 4px;
}
@media (min-width: 1500px) {
  .input-group-material.input-group-material-small .input-group-material-icon ~ label {
    top: -2px;
  }
}
.input-group-material.input-group-material-small input-group-material--error input ~ .input-group-material-icon-state:after, .input-group-material.input-group-material-small input:not(.ng-pristine).ng-invalid ~ .input-group-material-icon-state:after {
  width: 12px;
  height: 12px;
}
.input-group-material.input-group-material-dropdown input {
  color: transparent;
  text-shadow: 0 0 0 gray;
  cursor: pointer;
}
.input-group-material.input-group-material-dropdown .input-group-material-icon-state {
  top: 0;
}
.input-group-material.input-group-material-with-icon input {
  padding-left: 20px !important;
}
.input-group-material:not(.keepcolor) .input-group-material--valid input:not(.empty),
.input-group-material:not(.keepcolor) input:not(.ng-pristine).ng-valid {
  border-color: var(--color-green-400);
}
.input-group-material:not(.keepcolor) ~ .input-group-material-icon-state::after {
  display: block;
  justify-content: flex-end;
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='533.973' fill='%2363cb82' height='533.973'%3E%3Cpath d='M477.931 52.261c-12.821-12.821-33.605-12.821-46.427 0l-266.96 266.954-62.075-62.069c-12.821-12.821-33.604-12.821-46.426 0L9.616 303.572c-12.821 12.821-12.821 33.604 0 46.426l85.289 85.289 46.426 46.426c12.821 12.821 33.611 12.821 46.426 0l46.426-46.426 290.173-290.174c12.821-12.821 12.821-33.605 0-46.426l-46.425-46.426z'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 5;
  right: 0.75rem;
  top: 30px;
  content: "";
}

/* input group material liste dropdown */
/* todo: needed? and refactor */
/***************************************/
.input-group-material-liste,
web-components-asset-search ngb-typeahead-window.dropdown-menu {
  position: absolute;
  background: var(--white);
  font-size: 0.875rem;
  border: none;
  z-index: 5;
  box-shadow: 0px 12px 24px 8px rgba(132, 132, 132, 0.35);
  display: none;
  margin: 0px;
  padding: 0px;
  border-radius: unset;
}

web-components-asset-search .asset-search-multi-order ngb-typeahead-window.dropdown-menu {
  position: relative !important;
  box-shadow: none;
  max-height: 350px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: none !important;
  margin: 1rem -1rem 1rem 0rem;
}

.input-group-material input:focus ~ .input-group-material-liste {
  display: block;
}

.input-group-material-liste-item {
  width: 100%;
  border: none;
  background: transparent;
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--color-grey-800);
}
.input-group-material-liste-item.autocompletionempty {
  white-space: initial;
}
.input-group-material-liste-item.disabled {
  opacity: 0.5;
}

.input-group-material-liste > .input-group-material-liste-item:hover,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item .input-group-material-liste-item:hover,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item.active .input-group-material-liste-item,
.input-group-material-liste > .input-group-material-liste-item:focus,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item .input-group-material-liste-item:focus {
  background: var(--color-grey-800);
  cursor: pointer;
}

tickets-zendesk-faq-search .dropdown-item.active {
  background: var(--color-grey-800);
  color: var(--color-grey-50);
}

web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item:hover,
web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item:focus {
  outline: none;
}

web-components-asset-search ngb-typeahead-window.dropdown-menu > .dropdown-item.active {
  color: unset;
  background-color: unset;
}

.input-group-material-liste-headline,
.dropdown-item .input-group-material-liste-headline {
  font-size: 0.75rem;
  letter-spacing: 0.09px;
  background: var(--color-grey-800);
  padding: 0.5rem 1rem;
  color: var(--color-grey-50);
}
@media screen and (min-width: 767px) {
  .input-group-material-liste-headline,
  .dropdown-item .input-group-material-liste-headline {
    min-width: 450px;
  }
}

/*** input group material liste end ***/
/*** DEFAULT STATE for dropdown with icon after ***/
/******************************************************/
.input-group-material.input-group-material-dropdown input:not(.ng-valid) ~ .input-group-material-icon-state:after,
.input-group-material.input-group-material-dropdown input:not(.ng-invalid) ~ .input-group-material-icon-state:after {
  position: absolute;
  right: 11px;
  top: 22px;
  content: "";
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 48 48' width='25'%3E%3Cpath d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z' fill='%23949494' fill-opacity='1'/%3E%3Cpath d='M0-.75h48v48h-48z' fill='none'/%3E%3C/svg%3E") center no-repeat;
  width: 12px;
  height: 7px;
  margin-top: -10px;
  z-index: -1;
  display: block;
}

/*** VALID STATE for input/dropdown with icon after ***/
/******************************************************/
.input-group-material:not(.keepcolor):not(.input-group-material--error) input:not(.empty).ng-valid ~ label,
.input-group-material:not(.keepcolor):not(.input-group-material--error) web-components-number-input.ng-valid input:not(.empty) ~ label,
.input-group-material:not(.keepcolor):not(.input-group-material--error) web-components-phone-input:not(.empty).ng-valid ~ label,
.input-group-material:not(.keepcolor):not(.input-group-material--error) select:not(.empty).ng-valid ~ label,
.input-group-material.input-group-material--valid input:not(.empty) ~ label,
.input-group-material.input-group-material--valid select:not(.empty) ~ label,
.input-group-material.input-group-material--valid input:focus ~ label,
.input-group-material.input-group-material--valid select:focus ~ label {
  color: var(--color-green-400);
}

.input-group-material.input-group-material--valid .input-group-material-message,
.input-group-material:not(.input-group-material--error) input:not(.ng-pristine).ng-valid ~ .input-group-material-message {
  visibility: visible;
  color: var(--color-green-400) !important;
}

/* valid state for small version */
.input-group-material.input-group-material-small input-group-material--valid input:not(.empty) ~ .input-group-material-icon-state:after,
.input-group-material.input-group-material-small input:not(.ng-pristine).ng-valid ~ .input-group-material-icon-state:after {
  width: 12px;
  height: 12px;
}

/*** ERROR STATE for input/dropdown with icon after ***/
/******************************************************/
.input-group-material.input-group-material--error input ~ .input-group-material-icon-state:after,
.input-group-material input:not(.ng-pristine).ng-invalid ~ .input-group-material-icon-state:after {
  display: inline-flex;
  justify-content: flex-end;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f85457' height='24' width='24'%3E%3Cpath d='M22.245 4.015a.808.808 0 0 1 0 1.139l-6.276 6.27a.81.81 0 0 0 0 1.14l6.273 6.272a.81.81 0 0 1 0 1.14l-2.285 2.277a.814.814 0 0 1-1.142 0l-6.271-6.271a.81.81 0 0 0-1.141 0l-6.276 6.267a.81.81 0 0 1-1.141 0l-2.282-2.28a.81.81 0 0 1 0-1.14l6.278-6.269a.81.81 0 0 0 0-1.14L1.709 5.147a.808.808 0 0 1 0-1.14l2.284-2.278a.813.813 0 0 1 1.142.001L11.405 8a.81.81 0 0 0 1.141.001l6.276-6.267a.812.812 0 0 1 1.141 0l2.282 2.281z'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 5;
  right: 0.75rem;
  top: 30px;
  content: "";
}

.input-group-material input:not(.ng-pristine).ng-invalid,
.input-group-material web-components-phone-input:not(.ng-pristine).ng-invalid,
.input-group-material.input-group-material--error input,
.input-group-material.input-group-material--error input:focus,
.input-group-material select:not(.ng-pristine).ng-invalid,
.input-group-material.input-group-material--error select,
.input-group-material.input-group-material--error select:focus {
  border-color: var(--color-red-400);
}
.input-group-material input:not(.ng-pristine).ng-invalid ~ label,
.input-group-material web-components-phone-input:not(.ng-pristine).ng-invalid ~ label,
.input-group-material.input-group-material--error input ~ label,
.input-group-material.input-group-material--error input:focus ~ label,
.input-group-material select:not(.ng-pristine).ng-invalid ~ label,
.input-group-material.input-group-material--error select ~ label,
.input-group-material.input-group-material--error select:focus ~ label {
  color: var(--color-red-400);
}

.input-group-material.input-group-material--error .input-group-material-message,
.input-group-material input:not(.ng-pristine).ng-invalid ~ .input-group-material-message,
.input-group-material web-components-phone-input:not(.ng-pristine).ng-invalid ~ .input-group-material-message,
.input-group-material select:not(.ng-pristine).ng-invalid ~ .input-group-material-message {
  visibility: visible;
  color: var(--color-red-400) !important;
}

.input-group-material select:not(.ng-pristine).ng-invalid,
.input-group-material select:not(.ng-pristine).ng-invalid:focus,
.input-group-material.input-group-material--error select,
.input-group-material.input-group-material--error select:focus {
  border-bottom-color: var(--color-red-400);
}

/***********************************/
/******** WARNING, NOT ERROR ******/
/***********************************/
.input-group-material-message.text-color-primary {
  color: var(--color-blue-400) !important;
}

/************************************************/
/**** experimental material 2               *****/
/************************************************/
.input-group-material-bordered input {
  border: 1px solid var(--color-grey-500);
  padding: 3px;
  height: 38px;
}

.input-group-material-bordered input ~ label {
  top: 7px;
  left: 7px;
}

.input-group-material-bordered input:focus ~ label,
.input-group-material-bordered input.ng-valid ~ label {
  left: 7px;
  padding: 0 2px;
  background: var(--white);
  top: -8px;
}

.input-group-material-bordered .input-group-material-icon-right {
  top: 6px;
  right: 6px;
}

.input-group-material-bordered.input-group-material--error:after {
  content: "";
}

.watchlist .input-group-material.input-group-material--light input {
  color: var(--oldblue);
  font-size: 16px;
  height: 2rem;
}

.watchlist .input-group-material .input-group-material-icon .icon {
  width: 20px;
  height: 20px;
  position: relative;
}
.watchlist .input-group-material .input-group-material-icon ~ label {
  top: 0.75rem;
  left: 2.5rem;
}
.watchlist .input-group-material input:focus ~ label,
.watchlist .input-group-material input:not(.empty) ~ label {
  font-size: 0.75rem;
  top: -1rem;
  left: 0;
}
.watchlist .input-group-material.input-group-material-small .input-group-material-icon .icon {
  width: 14px !important;
  height: 14px !important;
}
.watchlist .input-group-material.input-group-material-small .input-group-material-icon ~ label {
  left: 24px;
  font-size: 1rem;
}
.watchlist .input-group-material.input-group-material-small input {
  padding-left: 22px;
}

@media screen and (max-width: 767px) {
  .order-mask .optional .link-secondary {
    margin-bottom: 0.5rem;
  }
}
.order-mask .exchange,
.order-mask .cash .label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: var(--color-grey-50);
}
.order-mask .cash .value {
  font-size: 1.75rem;
  font-variation-settings: var(--font-medium);
  color: var(--color-grey-50);
  text-align: right;
}
.order-mask .cash .label {
  text-align: right;
}
.order-mask .summary .value {
  font-size: 28px;
  font-variation-settings: var(--font-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #090a0c;
}
.order-mask .summary .cash {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  text-align: right;
  color: #090a0c;
}

.asset-search .input-group-material .input-group-material-icon {
  right: 1rem;
}
.asset-search .input-group-material .input-group-material-icon .icon {
  width: 20px;
  height: 20px;
  position: relative;
  color: var(--color-grey-500);
}
.asset-search .input-group-material .input-group-material-icon ~ label {
  font-size: 0.75rem;
  left: 0rem;
}
@media (max-width: 768px) {
  .asset-search .input-group-material input {
    font-size: 12px;
    padding: 0.5rem 0;
  }
}
.asset-search .input-group-material input::placeholder {
  color: var(--color-grey-500) -lighter;
}

.input-group-material.asset-search-default .input-group-material-icon {
  top: 0.75rem;
  right: 0.5rem;
}
.input-group-material.asset-search-default input {
  border: 1px solid #02172d;
  text-indent: 0;
  padding-left: 1rem;
  height: 3rem;
}
@media (max-width: 768px) {
  .input-group-material.asset-search-default input {
    font-size: 12px;
  }
}

.savings-plan .savings-plan-search .input-group-material .input-group-material-icon .icon,
.savings-plan .underlying-search .input-group-material .input-group-material-icon .icon,
.leverage-products .savings-plan-search .input-group-material .input-group-material-icon .icon,
.leverage-products .underlying-search .input-group-material .input-group-material-icon .icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 0.2rem;
}
.savings-plan .savings-plan-search .input-group-material .input-group-material-icon ~ label,
.savings-plan .underlying-search .input-group-material .input-group-material-icon ~ label,
.leverage-products .savings-plan-search .input-group-material .input-group-material-icon ~ label,
.leverage-products .underlying-search .input-group-material .input-group-material-icon ~ label {
  font-size: 0.75rem;
  left: 0rem;
}
.savings-plan .savings-plan-search .input-group-material input,
.savings-plan .underlying-search .input-group-material input,
.leverage-products .savings-plan-search .input-group-material input,
.leverage-products .underlying-search .input-group-material input {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.savings-plan .btn.fit, .savings-plan .fit.zero-user-note-button,
.leverage-products .btn.fit,
.leverage-products .fit.zero-user-note-button {
  text-transform: initial;
}
.savings-plan .btn:not(.fit), .savings-plan .zero-user-note-button:not(.fit),
.leverage-products .btn:not(.fit),
.leverage-products .zero-user-note-button:not(.fit) {
  width: 100%;
  height: 48px;
  white-space: nowrap;
}

.inbox .input-group-material .input-group-material-icon .icon {
  width: 20px;
  height: 20px;
  position: relative;
}
.inbox .input-group-material .input-group-material-icon ~ label {
  font-size: 0.75rem;
  top: -1.2rem;
  left: 0;
}
.inbox .input-group-material input {
  padding-left: 0;
  padding-right: 0;
}
.inbox .input-group-material input.date {
  background-color: rgba(238, 240, 242, 0.7);
  text-indent: 1rem;
}

.mobile-search input {
  text-indent: 0;
}

.not-allowed {
  cursor: not-allowed;
}

.force-word-break {
  word-break: break-word;
}

.auth .input-group-material input:not(.ng-pristine).ng-invalid ~ .input-group-material-message {
  color: #fff !important;
}
.auth input::placeholder {
  color: var(--color-grey-50);
}

.onboarding input {
  background: rgba(238, 240, 242, 0.7);
  border: 1px solid rgba(238, 240, 242, 0.7);
}
.onboarding input:focus {
  border: 1px solid var(--color-grey-50);
}
.onboarding input::placeholder {
  color: var(--color-grey-50);
}
.onboarding label,
.onboarding input:not(:focus).ng-valid.empty ~ label {
  transition: 0.25s ease all;
  top: 13px;
  left: 16px;
  font-size: 1rem;
}
.onboarding .input-group-material:not(.keepcolor):not(.input-group-material--error) select:not(.empty).ng-valid ~ label,
.onboarding .input-group-material:not(.keepcolor):not(.input-group-material--error) input:not(.empty).ng-valid ~ label,
.onboarding .input-group-material.input-group-material--error input ~ label,
.onboarding .input-group-material select:not(.ng-pristine).ng-invalid ~ label,
.onboarding .input-group-material.input-group-material--error select ~ label,
.onboarding .input-group-material input:not(.ng-pristine).ng-invalid ~ label,
.onboarding input:valid ~ label,
.onboarding input:focus ~ label,
.onboarding web-components-phone-input ~ label,
.onboarding input:disabled ~ label {
  font-size: 0.75rem;
  top: -1.2rem;
  left: 0;
}

.kryptoonboarding input {
  background: rgba(238, 240, 242, 0.7);
  border: 1px solid rgba(238, 240, 242, 0.7);
}
.kryptoonboarding input:focus {
  border: 1px solid var(--color-grey-50);
}
.kryptoonboarding input::placeholder {
  color: var(--color-grey-50);
}
.kryptoonboarding input:disabled {
  color: #7b8593;
}
.kryptoonboarding input:disabled ~ label {
  color: #7f7f7f;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: 0.26px;
  margin-bottom: 0;
}

h1,
.h1,
h2,
.h2 {
  font-size: 26px;
}

h3,
.h3 {
  font-size: 1.25rem;
  font-variation-settings: var(--font-medium);
}

h4,
.h4 {
  font-size: 1rem;
  font-variation-settings: var(--font-bold);
  text-transform: uppercase;
}

h5,
.h5 {
  font-size: 1.25rem;
  font-variation-settings: var(--font-medium);
}
h5.footer,
.h5.footer {
  letter-spacing: 0.125rem;
}

h6,
.h6 {
  font-variation-settings: var(--font-regular);
  line-height: 1.3125rem;
  letter-spacing: 0.05rem;
}

.box-info {
  border: 3px solid;
  padding: 1rem;
}
.box-info.box-info-secondary {
  color: var(--color-grey-500);
}
.box-info.box-info-primary {
  background-color: var(--oldblue);
  color: var(--white);
  border-color: var(--oldblue);
}
.box-info.box-info-hollow {
  background-color: transparent;
  border-color: var(--oldblue);
  color: var(--oldblue);
}
.box-info.box-info-offering {
  flex-grow: 1;
  min-width: 260px;
  max-width: 340px;
  min-height: 240px;
  margin-bottom: 30px;
}

.hover-box-container {
  position: relative;
  border: 2px solid var(--color-grey-800);
}
.hover-box-container .hover-box-title,
.hover-box-container .hover-box {
  display: flex;
  opacity: 0;
  position: absolute;
  transition: opacity 300ms ease;
  min-width: 100%;
  min-height: 100%;
}
.hover-box-container .hover-box {
  background: var(--oldblue);
}
.hover-box-container .hover-box-title {
  flex-direction: column;
}
.hover-box-container:hover .hover-box {
  opacity: 0.9;
}
.hover-box-container:hover .hover-box-title {
  opacity: 1;
}

.box {
  margin: 0.5rem 0 0 0;
  background-color: var(--color-grey-800);
  padding: 2rem 1rem;
}

.slim-footer {
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: var(--white);
  font-size: 0.6rem;
}
.slim-footer.dark {
  background: var(--oldblue);
}
.slim-footer.dark a {
  color: var(--white);
}
@media (min-width: 991px) {
  .slim-footer {
    font-size: 0.875rem;
  }
}

.sign-up-image {
  z-index: 2;
  position: relative;
}
.sign-up-image img {
  position: relative;
}
@media (min-width: 1500px) {
  .sign-up-image img {
    bottom: 100px;
  }
}

@media (min-width: 992px) {
  .entry-quote-box .icon {
    opacity: 0;
  }
  .entry-quote-box:hover .icon {
    opacity: 1;
  }
}
/**** tables ****/
/*** depot & list ***/
th {
  font-weight: unset;
  font-variation-settings: var(--font-medium);
}

.table,
.table-depot {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.table tr.top-align td,
.table-depot tr.top-align td {
  vertical-align: top;
}

@media (min-width: 992px) {
  .table-orders th:nth-child(2) {
    width: 17%;
  }
  .table-orders td {
    max-width: 150px;
  }
}
.table.portfolio-transfer th:first-child {
  width: 20%;
}
.table.portfolio-transfer th:last-child {
  width: 20%;
}

.table thead th,
.table thead td {
  border-bottom: 1px solid var(--color-grey-700);
  border-top: none;
}

.table th,
.table-depot th {
  font-size: 0.875rem;
  font-variation-settings: var(--font-medium);
  font-weight: unset;
  vertical-align: bottom;
  letter-spacing: 0.1px;
  border-bottom: 1px solid var(--color-grey-700);
}

.table-depot th:first-child {
  width: 30%;
}

.table td,
.table-depot td {
  font-size: 16px;
  border-bottom: 1px solid var(--color-grey-700);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .table-depot td {
    font-size: 0.875rem;
  }
}

.table td,
.table th,
.table-depot td,
.table-depot th {
  padding: 8px 10px;
  text-align: right;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .table td,
  .table th,
  .table-depot td,
  .table-depot th {
    padding: 8px 5px;
  }
}

.table td:first-child,
.table th:first-child,
.table-depot td:first-child,
.table-depot th:first-child {
  text-align: left;
  padding-left: 0;
}

.table td:last-child,
.table th:last-child,
.table-depot td:last-child,
.table-depot th:last-child {
  padding-right: 0;
}

@media screen and (min-width: 992px) {
  .table-positions tr:nth-last-child(2) td {
    border-bottom: 8px solid var(--color-grey-700);
  }
}

@media screen and (max-width: 991px) {
  .table-depot td {
    display: block;
    text-align: inherit;
    padding-right: 0;
    padding-left: 0;
  }
  .table-depot tr td:last-child {
    border-bottom: 2px solid var(--oldblue);
  }
  .table-depot th {
    display: none;
  }
  .table-depot tr:last-child td,
  .table-positions tr:nth-last-child(2) td {
    border-bottom-color: var(--color-grey-500);
  }
  .table-positions tr:last-child td:nth-last-child(3),
  .table-depot tr:nth-last-child(2) td:last-child,
  .table-depot tr:last-child td:last-child {
    border-bottom-color: var(--oldblue);
    border-bottom-width: 2px;
  }
  .table-depot tr:nth-child(2) td:first-child {
    border-top: 2px solid var(--oldblue);
  }
  .table-positions tr:last-child td:last-child {
    border: none;
  }
}
.table-tickets,
.table-post {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table-tickets th:first-child,
.table-post th:first-child {
  width: 100%;
}

.table-tickets td,
.table-post td {
  border-bottom: 1px solid var(--color-grey-700);
  vertical-align: top;
  color: var(--color-grey-50);
}
.table-tickets td > div,
.table-post td > div {
  overflow: hidden;
}

.table-post td {
  vertical-align: middle;
}

.table-tickets th,
.table-post th {
  border-bottom: 1px solid var(--color-grey-700);
}

.table-tickets td,
.table-tickets th,
.table-post td,
.table-post th {
  padding: 8px 10px;
  text-align: right;
}

.table-tickets td:first-child,
.table-tickets th:first-child,
.table-post td:first-child,
.table-post th:first-child {
  text-align: left;
}

@media screen and (max-width: 991px) {
  .table-tickets td,
  .table-post td {
    display: block;
    text-align: inherit;
    padding-right: 0.9rem;
  }
  .table-tickets tr td:last-child,
  .table-tickets tr:last-child td:last-child,
  .table-post tr:last-child td:last-child,
  .table-post tr td:last-child {
    border-bottom: 2px solid var(--oldblue);
  }
  .table-tickets tr:nth-child(2) td:first-child,
  .table-post tr:nth-child(2) td:first-child {
    border-top: 2px solid var(--oldblue);
  }
  .table-tickets th,
  .table-post th {
    display: none;
  }
  .table-tickets tr:last-child td,
  .table-post tr:last-child td {
    border-bottom-color: var(--color-grey-500);
  }
}
/*** kontoumsaetze **/
.table-konto {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.table-konto th {
  font-size: 0.875rem;
  font-weight: normal;
  vertical-align: bottom;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  border-bottom: 1px solid var(--color-grey-700);
}

.table-konto td {
  border-bottom: 1px solid var(--color-grey-700);
}
.table-konto td > div {
  overflow: hidden;
}

.table-konto td,
.table-konto th {
  padding: 8px 10px;
  text-align: right;
}

.table-konto td:first-child,
.table-konto th:first-child {
  text-align: left;
  padding-left: 0;
  white-space: nowrap;
}

.table-konto td:last-child,
.table-konto th:last-child {
  padding-right: 0;
}

.table-konto th:nth-child(1) {
  width: 10%;
}

.table-konto th:nth-child(2) {
  width: 15%;
}

.table-konto th:nth-child(3) {
  width: 15%;
}

.table-konto th:nth-child(4) {
  width: 52.5%;
}

.table-konto th:nth-child(5) {
  width: 7.5%;
}

.table-konto tr:last-child td {
  border-bottom-color: var(--color-grey-700);
}

@media screen and (max-width: 991px) {
  .table-konto td {
    display: block;
    text-align: inherit;
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .table-konto tr td:last-child {
    border-bottom: 2px solid var(--color-grey-700);
  }
  .table-konto th {
    display: none;
  }
  .table-konto tr:last-child td {
    border-bottom-color: var(--color-grey-700);
  }
  .table-konto tr:last-child td:last-child {
    border-bottom-color: var(--color-grey-700);
    border-bottom-width: 2px;
  }
}
/*** price comparison ***/
.table-price-comparison {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1px;
}
.table-price-comparison th {
  background-color: var(--oldblue);
  font-size: 13px;
  font-weight: normal;
  vertical-align: bottom;
}
.table-price-comparison td {
  font-size: 15px;
  border-bottom: 1px solid var(--color-grey-500);
}
.table-price-comparison td,
.table-price-comparison th {
  padding: 10px 10px;
  text-align: right;
  vertical-align: middle;
}
.table-price-comparison tr th:last-child {
  border-left: var(--oldblue);
  border-left-style: solid;
  border-left-width: 1px;
}
.table-price-comparison th:first-child {
  width: 30%;
}
.table-price-comparison td:first-child {
  text-align: left;
  padding-left: 2px;
}
.table-price-comparison tr:nth-child(3) td,
.table-price-comparison tr:nth-child(4) td {
  border-bottom-color: var(--oldblue);
}
.table-price-comparison td.empty {
  padding-right: 22px;
}
.table-price-comparison tr:nth-child(4) td > div {
  min-width: 3.75rem;
}
.table-price-comparison td.disclaimer {
  padding: 5px 0px 0px 0px;
  font-size: 13px;
  border-bottom: none;
  color: var(--color-grey-500);
}
.table-price-comparison td.more-link {
  padding: 30px 0px 0px 2px;
  border: none;
}
.table-price-comparison td.headline {
  padding: 30px 0px 5px 0px;
}
.table-price-comparison.table-price-comparison--mobile th:first-child {
  width: inherit;
}
.table-price-comparison.table-price-comparison--mobile tr td:last-child,
.table-price-comparison.table-price-comparison--mobile tr th:last-child {
  width: 4rem;
}

.table-blue th {
  background-color: var(--oldblue);
  color: var(--white);
  font-size: 13px;
  font-weight: normal;
  vertical-align: bottom;
}

.table-myths td {
  vertical-align: top;
}
.table-myths tr td:last-child,
.table-myths tr td:first-child {
  width: 45%;
  border-top: 1px dashed var(--color-grey-500);
  padding-top: 0.5rem;
}
.table-myths tr:last-child td:first-child,
.table-myths tr:last-child td:last-child {
  border-bottom: 1px dashed var(--color-grey-500);
}

.table-compressed {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}
.table-compressed thead {
  display: none;
}

.table-compressed td {
  font-size: 16px;
  border-bottom: 1px solid var(--oldblue);
  padding: 4px 10px;
}
.table-compressed td div {
  min-height: 25px;
}

@media screen and (min-width: 767px) {
  .table-watchlist td:first-child {
    min-width: 10rem;
  }
  .table-watchlist td:nth-child(3) {
    min-width: 7rem;
  }
}
@media screen and (min-width: 991px) {
  .table-watchlist td:first-child {
    min-width: 15rem;
  }
}
.table-watchlist .vertical-align-top {
  vertical-align: top;
}

.table-basic {
  width: 100%;
}
.table-basic tr td {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  padding: 8px 0;
  border-bottom: 1px solid #d9d9d9;
}
.table-basic tr td:first-of-type {
  font-weight: bold;
}
.table-basic tr td:last-of-type {
  text-align: right;
}
.table-basic tr:last-of-type td {
  border-bottom: none;
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .flex-md-text-ellipsis {
    text-overflow: ellipsis;
    max-width: 70px;
    overflow: hidden;
    white-space: nowrap;
  }
}
table.light-opacity th:not(.ignore-opacity),
table.light-opacity td:not(.ignore-opacity) {
  opacity: 0.5;
}

.table-depot tr.no-border td {
  border-bottom: none;
}

.note {
  font-size: 12px;
  background: #fffad2;
  padding: 6px;
  word-break: break-word;
}

textarea {
  resize: none;
}

.table-simple {
  width: calc(100% + 32px);
  margin: 0 -16px;
}
.table-simple thead {
  background: var(--color-grey-800);
}
.table-simple thead th {
  color: var(--color-grey-400);
  font-size: 14px;
}
.table-simple tr:last-child td {
  border: none;
  padding-bottom: 0;
}
.table-simple th,
.table-simple td {
  padding: 8px 16px;
}
.table-simple td {
  border-bottom: 1px solid var(--color-grey-700);
}

.snackbar {
  position: fixed;
  bottom: 0px;
  right: 15px;
  width: 290px;
  transition: bottom 0.25s cubic-bezier(0, 0, 0.1, 1);
  z-index: 1000;
}

.snackbar-header {
  background: var(--white);
  font-variation-settings: var(--font-medium);
  height: 35px;
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  cursor: pointer;
  padding: 0 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}

.snackbar-content {
  position: relative;
  background: var(--white);
  padding: 24px 16px 8px 16px;
  font-size: 12px;
  font-variation-settings: var(--font-medium);
  box-shadow: 0 16px 14px 0 rgba(0, 0, 0, 0.14);
}
.snackbar-content hr {
  border-color: var(--color-grey-700);
}

.snackbar-close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.snackbar--open {
  bottom: 0;
}

/**** icon with tooltip ****/
.icon-tooltip-wrapper {
  position: relative;
  top: 2px;
  overflow: visible;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
}

.icon-tooltip-content {
  border: 1px solid var(--color-grey-800);
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  padding: 20px 10px 10px 10px;
  left: 0;
  background: var(--white);
  color: #333;
  width: 240px;
  height: 140px;
  display: none;
  z-index: 8;
  box-shadow: 1px 1px 11px 0px var(--color-grey-500);
  transition: opacity 0.25s linear;
  opacity: 0;
  font-size: 0.75rem;
}
.icon-tooltip-content.autoheight {
  height: auto;
}
.icon-tooltip-content.left-aligned {
  left: unset;
  right: 0;
}

.icon-tooltip-close {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  cursor: pointer;
}

.icon-tooltip-content--show {
  display: block;
  opacity: 1;
}

.text-underline-dotted {
  text-decoration: underline dotted;
  cursor: pointer;
}

ngb-tooltip-window.tooltip.show {
  opacity: 1;
}
ngb-tooltip-window.tooltip.gb-tooltip .tooltip-inner {
  background: var(--color-grey-800);
  box-shadow: 1px 1px 5px 0px var(--color-grey-500);
  color: #212529;
}
ngb-tooltip-window.tooltip.gb-tooltip .arrow::before {
  border-top-color: var(--color-grey-500);
}
ngb-tooltip-window.tooltip.zero-tooltip .tooltip-inner {
  background: var(--white);
  border-radius: 4px;
  box-shadow: 0 0px 12px 0 rgba(73, 74, 74, 0.32);
  color: var(--color-grey-400);
  padding: 4px 8px;
}
ngb-tooltip-window.tooltip.zero-tooltip .arrow::before {
  border-top-color: var(--white);
  border-bottom-color: var(--white);
}
ngb-tooltip-window.tooltip.without-arrow .tooltip-inner {
  max-width: 400px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 12px 24px -5px rgba(0, 0, 0, 0.2);
  color: #212529;
}
ngb-tooltip-window.tooltip.without-arrow .arrow::before {
  opacity: 0;
  border-bottom-color: var(--color-grey-500);
}

.gj-datepicker.input-group {
  width: 100%;
  border-bottom: 1px solid var(--color-grey-500);
}

.gj-datepicker.input-group input {
  border: none;
  padding: 2px 0 2px 0;
}

.gj-datepicker.input-group input-group-append {
  border: none;
}

.gj-datepicker.input-group button {
  border: none;
  background: transparent;
}

.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.gj-datepicker .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled).active,
.gj-datepicker .btn-outline-secondary:not(:disabled):not(.disabled):active,
.gj-datepicker .show > .btn-outline-secondary.dropdown-toggle {
  background: none;
  border: none;
  color: var(--color-grey-500);
}

.gj-datepicker-bootstrap [role=right-icon] button .gj-icon,
.gj-datepicker-bootstrap [role=right-icon] button .material-icons {
  top: 5px;
}

.gj-datepicker input + span.input-group-append:after {
  display: block;
  content: "";
  height: 2px;
  width: 0;
  transition: width 0.25s linear;
  background: var(--color-grey-300);
  position: absolute;
  left: 0;
  bottom: -2px;
}

.gj-datepicker input:focus + span.input-group-append:after {
  width: 100%;
}

.gj-datepicker input.ng-valid + span.input-group-append:after {
  width: 100%;
  background-color: var(--color-green-400);
}

.ngb-dp-day.disabled {
  text-decoration: line-through;
  cursor: not-allowed;
  text-decoration-color: var(--color-blue-400);
}
.ngb-dp-day.disabled div {
  background: var(--color-grey-800);
}

/* sorter arrow module */
/* usage: class = "sorter-before" + optional: "sorter-before--down" "sorter-before--active" */
/* usage: class = "sorter-after" + optional: "sorter-after--down" "sorter-after--active" */
.sorter {
  position: relative;
}

.sorter-icon, .sorter-before:before, .sorter-after:after {
  display: inline-block;
  width: 12px;
  height: 1rem;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='sorter-arrow' viewBox='0 0 12 12'%3E%3Cg id='Web'%3E%3Cg id='zero-web-new-color' transform='translate(-406.000000, -1512.000000)'%3E%3Cg id='Group-2' transform='translate(345.000000, 353.000000)'%3E%3Cg id='Group-7' transform='translate(0.000000, 1030.000000)'%3E%3Cg id='ic_sort' transform='translate(60.000000, 130.000000)'%3E%3Cg%3E%3Cpath fill='%239c9c9c' d='M6.999344,11c-0.314744,0-0.628934-0.119271-0.868029-0.358918L3.32237,7.832689 C2.947991,7.457206,2.947991,6.846494,3.321818,6.472115c0.374379-0.374379,0.985643-0.376036,1.360574-0.002209 l1.356709,1.357261L6.036892-0.036996C6.036892-0.568194,6.468698-1,6.999344-1 c0.529541,0,0.961899,0.431253,0.963004,0.960795l0.002209,7.859746l1.349531-1.34953 c0.374378-0.375484,0.985091-0.37714,1.361678,0c0.181115,0.180011,0.281612,0.421866,0.282717,0.679183 c0,0.257317-0.100497,0.500276-0.282717,0.682496l-2.808945,2.808393C7.627727,10.880729,7.313535,11,6.999344,11z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  transform: rotate(180deg);
}

.sorter-icon-flat, .sorter-after-flat:after {
  display: inline-block;
  width: 12px;
  height: 0.5rem;
  content: "";
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='none' stroke-width='9' stroke='%235ca3ef'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  transition: all 0.2s linear;
}

.disabled .sorter-icon-flat, .disabled .sorter-after-flat:after {
  cursor: not-allowed;
}

.sorter-icon--active, .sorter-before.sorter-before--active:before, .sorter-after-flat:after.sorter-after--active, .sorter-after.sorter-after--active:after {
  display: inline-block;
  width: 12px;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  transition: all 0.2s linear;
}

.sorter-after:after {
  display: inline-block;
  content: "";
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.sorter-after.sorter-after--active:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='sorter-arrow' viewBox='0 0 12 12'%3E%3Cg id='Web'%3E%3Cg id='zero-web-new-color' transform='translate(-406.000000, -1512.000000)'%3E%3Cg id='Group-2' transform='translate(345.000000, 353.000000)'%3E%3Cg id='Group-7' transform='translate(0.000000, 1030.000000)'%3E%3Cg id='ic_sort' transform='translate(60.000000, 130.000000)'%3E%3Cg%3E%3Cpath fill='%235ca3ef' d='M6.999344,11c-0.314744,0-0.628934-0.119271-0.868029-0.358918L3.32237,7.832689 C2.947991,7.457206,2.947991,6.846494,3.321818,6.472115c0.374379-0.374379,0.985643-0.376036,1.360574-0.002209 l1.356709,1.357261L6.036892-0.036996C6.036892-0.568194,6.468698-1,6.999344-1 c0.529541,0,0.961899,0.431253,0.963004,0.960795l0.002209,7.859746l1.349531-1.34953 c0.374378-0.375484,0.985091-0.37714,1.361678,0c0.181115,0.180011,0.281612,0.421866,0.282717,0.679183 c0,0.257317-0.100497,0.500276-0.282717,0.682496l-2.808945,2.808393C7.627727,10.880729,7.313535,11,6.999344,11z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.sorter-after--down:after {
  transform: rotate(360deg);
}

.sorter-after-flat:after {
  display: inline-block;
  content: "";
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.sorter-after-flat--down:after {
  transform: rotate(180deg);
}

.sorter-before:before {
  display: inline-block;
  content: "";
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.sorter-before.sorter-before--active:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='sorter-arrow' viewBox='0 0 12 12'%3E%3Cg id='Web'%3E%3Cg id='zero-web-new-color' transform='translate(-406.000000, -1512.000000)'%3E%3Cg id='Group-2' transform='translate(345.000000, 353.000000)'%3E%3Cg id='Group-7' transform='translate(0.000000, 1030.000000)'%3E%3Cg id='ic_sort' transform='translate(60.000000, 130.000000)'%3E%3Cg%3E%3Cpath fill='%235ca3ef' d='M6.999344,11c-0.314744,0-0.628934-0.119271-0.868029-0.358918L3.32237,7.832689 C2.947991,7.457206,2.947991,6.846494,3.321818,6.472115c0.374379-0.374379,0.985643-0.376036,1.360574-0.002209 l1.356709,1.357261L6.036892-0.036996C6.036892-0.568194,6.468698-1,6.999344-1 c0.529541,0,0.961899,0.431253,0.963004,0.960795l0.002209,7.859746l1.349531-1.34953 c0.374378-0.375484,0.985091-0.37714,1.361678,0c0.181115,0.180011,0.281612,0.421866,0.282717,0.679183 c0,0.257317-0.100497,0.500276-0.282717,0.682496l-2.808945,2.808393C7.627727,10.880729,7.313535,11,6.999344,11z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.sorter-before--down:before {
  transform: rotate(360deg);
}
.sorter-before--active {
  white-space: nowrap;
}

.sorter-big:after, .sorter-big:before,
.sorter-big:after,
.sorter-big:before {
  width: 24px !important;
  height: 12px !important;
  margin-bottom: 6px;
}
.sorter-big.sorter-align-center,
.sorter-big.sorter-align-center {
  margin-bottom: -2px;
}

/* placeholder only needed on generic dropdown use */
.select-dropdown ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-grey-300);
}

.select-dropdown ::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-grey-300);
}

.select-dropdown :-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-grey-300);
}

.select-dropdown :-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-grey-300);
}

.select-dropdown select::-ms-expand {
  display: none;
}

.select-dropdown,
.select-dropdown > * {
  cursor: pointer;
}

.select-dropdown {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 0;
}
.select-dropdown.dropdown-authdevices {
  min-width: 9rem;
  width: calc(100% + 3rem);
}
.select-dropdown::after {
  position: absolute;
  right: 11px;
  top: 2rem;
  content: "";
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 48 48' width='25'%3E%3Cpath d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z' fill='%23949494' fill-opacity='1'/%3E%3Cpath d='M0-.75h48v48h-48z' fill='none'/%3E%3C/svg%3E") center no-repeat;
  width: 12px;
  height: 7px;
  margin-top: -10px;
  pointer-events: none;
  display: block;
}
.select-dropdown--disabled {
  box-shadow: none;
  cursor: default !important;
}
.select-dropdown--disabled::after {
  position: absolute;
  right: 11px;
  top: 25px;
  content: "";
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 48 48' width='25'%3E%3Cpath d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z' fill='%23B1B1B1' fill-opacity='1'/%3E%3Cpath d='M0-.75h48v48h-48z' fill='none'/%3E%3C/svg%3E") center no-repeat;
  width: 12px;
  height: 7px;
  margin-top: -10px;
  z-index: -1;
  display: block;
}
.select-dropdown--disabled select,
.select-dropdown select[disabled=disabled] {
  border-color: var(--color-grey-300) !important;
  color: var(--color-grey-50);
  pointer-events: none;
  box-shadow: none !important;
  background: var(--color-grey-300) !important;
}
.select-dropdown--disabled::after {
  background: none;
}
.select-dropdown select {
  border: 1px solid rgba(238, 240, 242, 0.7);
  box-sizing: border-box;
  background: rgba(238, 240, 242, 0.7);
  outline: none;
  height: 3rem;
  width: 100%;
  padding: 0rem 1rem;
  user-select: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  border-radius: 6px;
  color: var(--color-grey-50);
}
.select-dropdown select:disabled {
  cursor: not-allowed;
}
.select-dropdown option,
.select-dropdown .select-item {
  font-size: em(14px);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select-dropdown.select-dropdown--error:focus select {
  border: 1px solid var(--color-red-400);
}
.select-dropdown.select-dropdown--error select {
  border: 1px solid var(--color-red-400);
}
.select-dropdown.select-dropdown--error select:focus {
  border: solid var(--color-grey-50);
}

/* default state */
.select-dropdown option:not(:checked) {
  color: var(--color-grey-50);
} /* or whatever your default style is */
/* checked state */
.select-dropdown option:checked {
  font-weight: bold;
}

/* selected state, set class "selected" on <select>-selector with javascript only possible */
.select-dropdown select.selected {
  color: var(--color-grey-50);
}

/* This is the default state */
.custom-radio .custom-control-label::before {
  background-color: transparent;
  top: 2px;
  border: 1px solid var(--color-grey-300);
}

.custom-radio .custom-control-label {
  padding-left: 10px;
  font-size: 14px;
}

/* This is the checked state */
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--oldblue);
  /* this bg image SVG is just a white circle, you can replace it with any valid SVG code */
  border-radius: 50%;
  top: 2px;
}

/* active state i.e. displayed while the mouse is being pressed down */
.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: var(--white);
  top: 3px;
}

/* This is the default state */
.custom-checkbox .custom-control-label::before {
  background-color: transparent;
  top: 3px;
  border: 1px solid var(--color-grey-300);
}

.custom-checkbox .custom-control-label {
  padding-left: 10px;
  font-size: 14px;
}

/* This is the checked state */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--oldblue);
  /* this bg image SVG is just a white circle, you can replace it with any valid SVG code */
  border-radius: 0;
}

/* active state i.e. displayed while the mouse is being pressed down */
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  color: var(--white);
  top: 3px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--white);
  border-color: inherit;
  background-color: inherit;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-control.custom-checkbox.checkbox--alert label::after,
.custom-control.custom-checkbox.checkbox--alert label::before {
  left: inherit;
  right: -1.55rem;
  top: 0.15rem;
}
.custom-control.custom-checkbox.checkbox--alert label {
  margin-right: 1.5rem;
}
.custom-control.custom-checkbox.checkbox--alert .custom-control-input:checked ~ .custom-control-label.custom-control-label--success::before, .custom-control.custom-checkbox.checkbox--alert .custom-control-input:checked ~ .custom-control-label.custom-control-label--success::after {
  background-color: #28a745;
}
.custom-control.custom-checkbox.checkbox--alert .custom-control-input:checked ~ .custom-control-label.custom-control-label--info::before, .custom-control.custom-checkbox.checkbox--alert .custom-control-input:checked ~ .custom-control-label.custom-control-label--info::after {
  background-color: #061d3c;
}
.custom-control.custom-checkbox.checkbox--alert .custom-control-input:checked ~ .custom-control-label.custom-control-label--danger::before, .custom-control.custom-checkbox.checkbox--alert .custom-control-input:checked ~ .custom-control-label.custom-control-label--danger::after {
  background-color: #dc3545;
}
.custom-control.custom-checkbox.checkbox--alert .custom-control-input:focus:not(:checked) ~ .custom-control-label.custom-control-label--success::before {
  border-color: #28a745;
}
.custom-control.custom-checkbox.checkbox--alert .custom-control-input:focus:not(:checked) ~ .custom-control-label.custom-control-label--info::before {
  border-color: #061d3c;
}
.custom-control.custom-checkbox.checkbox--alert .custom-control-input:focus:not(:checked) ~ .custom-control-label.custom-control-label--danger::before {
  border-color: #dc3545;
}
.custom-control.custom-checkbox.checkbox--alert .custom-control-input:active ~ .custom-control-label::before {
  top: 0.15rem;
}

ul:not(.list-square):not(.list):not(.list-doc):not(.list-letter):not(.list-number):not(.pagination) {
  padding-left: 24px;
  list-style: none;
}
ul:not(.list-square):not(.list):not(.list-doc):not(.list-letter):not(.list-number):not(.pagination) li::before {
  content: "•";
  color: var(--color-blue-400);
  display: inline-block;
  position: relative;
  width: 16px;
  margin-left: -1em;
  font-size: 24px;
  top: 4px;
}

.list-square,
.list-doc {
  list-style: none;
  padding-left: 24px;
}

.list-square li,
.list-doc li {
  position: relative;
  font-size: 1rem;
  line-height: 130%;
  padding-bottom: 7px;
}

.list-square li:before {
  content: " ";
  background-size: cover;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Crect width='10' height='10' stroke='none' fill='%235ca3ef' /%3E%3C/svg%3E");
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: -19px;
  top: 5px;
}
.list-square.list-square--smaller li:before {
  width: 6px;
  height: 6px;
  left: -15px;
  top: 7px;
}

.list-square.plussign li {
  font-size: 1rem;
  padding-left: 0.5rem;
}

.list-square.plussign li:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='81px' height='81px' version='1.1' viewBox='-0.5 -0.5 81 81' xmlns='http://www.w3.org/2000/svg'%3E%3Cg pointer-events='none'%3E%3Crect width='80' height='80' fill='%23248eff'/%3E%3Cpath d='m20 36h16v-16h8v16h16v8h-16v16h-8v-16h-16z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  width: 14px;
  height: 14px;
  left: -1.4rem;
  top: 0.2rem;
}

.list-square.check li {
  font-size: 14px;
  line-height: unset;
}

.list-square.check li:before {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 32 32' %3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cg fill='%23fff' fill-rule='nonzero'%3E %3Cg%3E %3Crect width='32' height='32' fill='%23248eff'/%3E %3Cpath d='M20 0.453L7.706 12.797 4.305 9.263 0.71 12.797 7.5 19.547 23.288 3.785z' transform='translate(4 6)'/%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  width: 14px;
  height: 14px;
  left: -1.4rem;
  top: 0.2rem;
}

.list-square.quotes li {
  font-size: 1rem;
  padding-left: 0.5rem;
}

.list-square.quotes li:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16.25 16.25'%3E%3Cdefs%3E%3Cstyle%3E.cls-1{fill:none;}.cls-2{fill:%23ccccd6;}.cls-3{clip-path:url(%23clip-path);}.cls-4{fill:%23fff;}%3C/style%3E%3CclipPath id='clip-path'%3E%3Crect class='cls-1' width='16.25' height='16.25'/%3E%3C/clipPath%3E%3C/defs%3E%3Crect class='cls-2' width='16.25' height='16.25'/%3E%3Cg class='cls-3'%3E%3Cpath class='cls-4' d='M7.1,4.31a4.69,4.69,0,0,0-2,.94A3.38,3.38,0,0,0,3.92,6.8a8.94,8.94,0,0,0-.3,2.25v2.89H7.16V8.42H5.25a3.81,3.81,0,0,1,.23-1.5A2.32,2.32,0,0,1,7.1,5.64Zm5.46,0a7.27,7.27,0,0,0-1.74.7A3.74,3.74,0,0,0,9.38,6.86a6.89,6.89,0,0,0-.29,2.19v2.89h3.53V8.42H10.71a3.32,3.32,0,0,1,.41-1.87,2.8,2.8,0,0,1,1.44-.91Z'/%3E%3C/g%3E%3C/svg%3E");
  width: 14px;
  height: 14px;
  left: -1.4rem;
  top: 0.2rem;
}

.list-letter li:before,
.list-number li:before {
  position: absolute;
  left: 1.5rem;
}
.list-letter.provision,
.list-number.provision {
  padding-left: 20px;
}
.list-letter.provision li,
.list-number.provision li {
  margin: 10px 0;
}
.list-letter.provision li:before,
.list-number.provision li:before {
  font-variation-settings: var(--font-medium);
}

.list-letter {
  list-style-type: upper-latin;
}

.list-number {
  list-style-type: decimal;
}

.list {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}
.list.footer a {
  transition: 0.4s;
}
.list.footer a:hover {
  color: var(--color-blue-400);
}
.list li {
  margin-bottom: 0.5rem;
}

.list-doc {
  padding-left: 44px;
}
.list-doc li {
  line-height: unset;
  padding-left: 5px;
  margin-left: -24px;
}
.list-doc li:not(.defaultsymbol):before {
  content: " ";
  background-size: cover;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' id='copy-doc' viewBox='0 0 15 18'%3E%3Cg stroke='%235CA3EF' stroke-width='1.391' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M13.8 3.783a.7.7 0 0 1 .2.491v12.03a.69.69 0 0 1-.684.696H1.684A.69.69 0 0 1 1 16.304V1.696A.69.69 0 0 1 1.684 1h9.095c.182 0 .356.073.484.204L13.8 3.783zM3.395 11.783h8.21M3.395 13.87h8.21M3.395 9.696h8.21M3.395 7.609h8.21M3.395 5.522h4.789M3.395 5.522h4.789M3.395 7.609h8.21M3.395 9.696h8.21M3.395 11.783h8.21M3.395 13.87h8.21'/%3E%3C/g%3E%3C/svg%3E");
  width: 13px;
  height: 16px;
  position: absolute;
  left: -19px;
  top: 5px;
}

.autocompletion-clear {
  position: relative;
  top: -2.5rem;
  right: 1rem;
}

web-components-asset-search .dropdown-item {
  display: block;
  padding: inherit;
}

web-components-asset-search.asset-search-input ngb-typeahead-window {
  left: -85% !important;
  width: 28rem;
}
@media screen and (max-width: 575px) {
  web-components-asset-search.asset-search-input ngb-typeahead-window {
    left: -30% !important;
    width: 20rem;
  }
  web-components-asset-search.asset-search-input .input-group-material input {
    font-size: 1rem;
  }
}

.dropdown-menu {
  max-width: calc(100vw - 16px);
}
@media (max-width: 768px) {
  .dropdown-menu.position-dropdown {
    transform: translate(-160px, 40px) !important;
  }
}
@media (max-width: 768px) {
  .dropdown-menu {
    max-width: calc(100vw - 32px);
  }
}

.ellipsis {
  text-overflow: ellipsis;
}

.pac-container {
  border-radius: 0px;
  border-top: none;
  margin-left: 1px;
  z-index: 1;
}
.pac-container .pac-icon {
  display: none;
}
.pac-container .pac-item {
  padding: 8px 12px;
}
.pac-container .pac-item:hover {
  cursor: pointer;
}

@keyframes delayedUiBlock {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
.loading-indicator {
  width: 100%;
  height: 100%;
  background: color-mix(in srgb, var(--white) 70%, transparent);
  z-index: 9;
  position: absolute;
  top: 0px;
  left: 0px;
  animation: delayedUiBlock 0.25s;
  margin-left: auto;
}
.loading-indicator.no-delay {
  animation: none;
}
.loading-indicator .description {
  position: absolute;
  top: 52%;
  font-size: 18px;
  color: var(--color-blue-400);
  font-variation-settings: var(--font-semi-bold);
}

.auth .loading-indicator {
  background: color-mix(in srgb, var(--color-grey-50) 70%, transparent);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--color-blue-400);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.blink {
  transition: background-color 150ms ease-in;
}
.blink.blink--green {
  background-color: var(--color-green-400);
  transition: background-color 100ms ease-out;
}
.blink.blink--red {
  background-color: var(--color-red-400);
  transition: background-color 100ms ease-out;
}

.quoteindicator {
  position: relative;
}
.quoteindicator:after {
  content: "";
  width: 18px;
  position: absolute;
  height: 0.5rem;
  right: -1.1rem;
  top: 5px;
  background-size: 17px 0.5rem;
  transform: scale(0.7);
  transition: opacity 1s ease-in;
  opacity: 0;
}
.quoteindicator.quoteindicator-variant2:after {
  top: 0.6rem;
}
.quoteindicator.quoteindicator-variant3:after {
  top: 0.45rem;
}
.quoteindicator.quoteindicator-variant4:after {
  top: 0.3rem;
}
.quoteindicator.quoteindicator-variant5:after {
  top: 0.9rem;
}
@media screen and (max-width: 575px) {
  .quoteindicator.quoteindicator-variant5:after {
    top: 0.45rem;
  }
}
.quoteindicator.quoteindicator--down:after {
  transform: scale(0.7) rotate(180deg);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23fa455a' stroke-width='5' stroke='none'/%3E%3C/svg%3E");
  transition: opacity 100ms ease-out;
}
.quoteindicator.quoteindicator--show:after {
  opacity: 1;
  transition: opacity 500ms ease-out;
}
.quoteindicator.quoteindicator--up:after {
  transform: scale(0.7) rotate(0deg);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%2334b287' stroke-width='5' stroke='none'/%3E%3C/svg%3E");
  transition: opacity 500ms ease-out;
}

.detract-attention {
  color: var(--color-grey-500);
}
.detract-attention .nav-tabs .nav-link,
.detract-attention .nav-tabs .nav-link.active,
.detract-attention .nav-tabs .nav-link:hover {
  color: var(--color-grey-500);
  border-bottom: 1px solid var(--color-grey-500);
}
.detract-attention .nav-tabs .nav-link:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23ffffff' stroke-width='5' stroke='%23ccccd6'/%3E%3C/svg%3E");
}
.detract-attention .border-color-basic {
  border-color: var(--color-grey-500) !important;
}
.detract-attention .color-text-secondary,
.detract-attention .text-color-basic {
  color: var(--color-grey-500);
  border-color: var(--color-grey-500);
}
.detract-attention .input-group-material label {
  color: var(--color-grey-500);
}
.detract-attention .input-group-material .input-group-material-message {
  color: var(--color-grey-500);
}
.detract-attention .input-group-material input {
  color: var(--color-grey-50);
  border: 1px solid var(--color-grey-500);
  background: transparent;
  cursor: not-allowed;
}
.detract-attention .input-group-material select {
  background: transparent;
  cursor: not-allowed;
}
.detract-attention .optional * {
  color: var(--color-grey-500);
  cursor: not-allowed;
}

ngb-alert.alert {
  display: none;
}

ngb-alert.alert.show {
  display: block;
}

.alert {
  border-radius: 8px;
  margin-bottom: 30px;
}

.alert-danger {
  color: var(--color-grey-50);
  background-color: color-mix(in srgb, var(--color-red-500) 20%, transparent);
  border-color: var(--color-red-500);
}

.alert-important {
  color: var(--color-grey-50);
  background-color: #f4cbdc;
  border-color: var(--color-red-500);
}

.alert-success {
  color: var(--color-grey-50);
  background-color: rgba(11, 174, 73, 0.4);
  border-color: #0bae49;
}

.alert-dismissible .close {
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  background-color: var(--color-grey-500);
  color: var(--white);
  border-radius: 50%;
  font-size: 22px;
  height: 24px;
  width: 24px;
  padding: 0;
}

.auth a {
  color: var(--color-blue-400);
}
.auth .alert-danger,
.auth .alert-success {
  color: var(--white);
  box-shadow: none;
}
.auth .input-group-material-message {
  color: #fff !important;
}

.tile {
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 30px;
}
.tile.inbox {
  padding-top: 0rem;
}
.tile.products .asset-class:hover use {
  color: var(--color-blue-400);
}
.tile hr {
  border-color: var(--color-grey-700);
}
.tile hr.less-margin {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.row.same-height .col-lg-4 {
  padding: 0px 15px 0px 15px;
}
.row.same-height .tile {
  height: 100%;
  margin-bottom: 0;
}

.leverage-products .leverage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  min-height: 45px;
  cursor: pointer;
  font-variation-settings: var(--font-bold);
  border-radius: 4px;
  background-color: var(--color-grey-800);
  transition: all 0.15s ease-in-out;
}
.leverage-products .leverage input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  outline: none;
  border: none;
}
.leverage-products .leverage input::-webkit-input-placeholder {
  font-size: 10px;
}
.leverage-products .leverage input::-moz-placeholder {
  font-size: 10px;
}
.leverage-products .leverage input:-ms-input-placeholder {
  font-size: 10px;
}
.leverage-products .leverage input:-moz-placeholder {
  font-size: 10px;
}
.leverage-products .leverage.disabled {
  cursor: not-allowed;
}
.leverage-products .leverage.call.active, .leverage-products .leverage.call:hover {
  color: var(--color-green-400);
  background: color-mix(in srgb, var(--color-green-400) 20%, transparent);
}
.leverage-products .leverage.put.active, .leverage-products .leverage.put:hover {
  color: var(--color-red-400);
  background: color-mix(in srgb, var(--color-red-400) 20%, transparent);
}
.leverage-products .leverage.custom {
  border: 1px solid var(--color-grey-50);
  width: 160px;
}
.leverage-products .product {
  transition: all 0.2s ease-in-out;
}
.leverage-products .product:hover {
  background: var(--color-grey-800);
}

.modal-dialog .modal-content {
  padding: 16px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 12px 24px -5px rgba(0, 0, 0, 0.2);
}
.modal-dialog.push-alert-modal .modal-content {
  background: none;
}
.modal-dialog.exit-intent .modal-content {
  padding: 0;
}

.disallowed-hint {
  margin: 0.5rem 0;
  color: var(--color-green-300);
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--color-green-300);
  background: var(--color-green-600);
}

.checkbox {
  display: block;
  width: unset;
  position: relative;
  padding-left: 35px;
  padding-top: 2px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #000;
}
.checkbox.bold {
  font-weight: bold;
}
.checkbox.error {
  color: var(--color-red-400);
}
.checkbox.error .checkmark {
  border: solid 1px var(--color-red-400);
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .checkmark {
  border: solid 1px var(--color-blue-400);
  background-color: var(--color-blue-400);
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background: rgba(238, 240, 242, 0.7);
  border: solid 1px var(--oldblue);
  border-radius: 6px;
}
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.chart-container {
  width: 100%;
  height: 460px;
  position: relative;
}
.chart-container .loading {
  width: 100%;
  height: 100%;
}
.chart-container .no-chart-info {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

#chart-settings {
  border: none;
  background: transparent;
  outline: none;
  color: var(--color-grey-300);
}

.dropdown-menu h5 {
  padding: 0.25rem 1.5rem;
}

.stx_line_chart {
  color: var(--color-blue-400);
}

.stx_mountain_chart {
  color: color-mix(in srgb, var(--color-blue-400) 75%, transparent);
  background-color: color-mix(in srgb, var(--color-blue-400) 80%, transparent);
  border-top-color: var(--color-blue-400);
}

.stx-panel-control.stx-show {
  display: none;
}

.chartSize {
  display: none;
}