.autocompletion-clear {
  position: relative;
  top: -2.5rem;
  right: 1rem;
}

web-components-asset-search .dropdown-item {
  display: block;
  padding: inherit;
}

web-components-asset-search.asset-search-input {
  ngb-typeahead-window {
    left: -85% !important;
    width: 28rem;
  }

  @media screen and (max-width: 575px) {
    ngb-typeahead-window {
      left: -30% !important;
      width: 20rem;
    }
    .input-group-material input {
      font-size: 1rem;
    }
  }
}

.dropdown-menu {
  max-width: calc(100vw - 16px);

  &.position-dropdown {
    @media (max-width: 768px) {
      transform: translate(-160px, 40px) !important;
    }
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 32px);
  }
}

.ellipsis {
  text-overflow: ellipsis;
}

// google places autocomplete
.pac-container {
  border-radius: 0px;
  border-top: none;
  margin-left: 1px;
  z-index: 1;

  .pac-icon {
    display: none;
  }

  .pac-item {
    padding: 8px 12px;

    &:hover {
      cursor: pointer;
    }
  }
}
