.blink {
  transition: background-color 150ms ease-in;
  &.blink--green {
    background-color: var(--color-green-400);
    transition: background-color 100ms ease-out;
  }
  &.blink--red {
    background-color: var(--color-red-400);
    transition: background-color 100ms ease-out;
  }
}

.quoteindicator {
  position: relative;

  &:after {
    content: '';
    width: 18px;
    position: absolute;
    height: 0.5rem;
    right: -1.1rem;
    top: 5px;
    background-size: 17px 0.5rem;
    transform: scale(0.7);
    transition: opacity 1s ease-in;
    opacity: 0;
  }

  &.quoteindicator-variant2:after {
    top: 0.6rem;
  }
  &.quoteindicator-variant3:after {
    top: 0.45rem;
  }
  &.quoteindicator-variant4:after {
    top: 0.3rem;
  }
  &.quoteindicator-variant5:after {
    top: 0.9rem;
    @media screen and (max-width: 575px) {
      top: 0.45rem;
    }
  }

  &.quoteindicator--down:after {
    transform: scale(0.7) rotate(180deg);
    background-image: svg-url(
      "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='#{#fa455a}' stroke-width='5' stroke='none'/%3E%3C/svg%3E"
    );
    transition: opacity 100ms ease-out;
  }

  &.quoteindicator--show:after {
    opacity: 1;
    transition: opacity 500ms ease-out;
  }

  &.quoteindicator--up:after {
    transform: scale(0.7) rotate(0deg);
    background-image: svg-url(
      "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='#{#34b287}' stroke-width='5' stroke='none'/%3E%3C/svg%3E"
    );
    transition: opacity 500ms ease-out;
  }
}
