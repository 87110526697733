@use 'sass:math';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: 0.26px;
  margin-bottom: 0;
}

h1,
.h1,
h2,
.h2 {
  font-size: 26px;
}
h3,
.h3 {
  font-size: 1.25rem;
  font-variation-settings: var(--font-medium);
}

h4,
.h4 {
  font-size: 1rem;
  font-variation-settings: var(--font-bold);
  text-transform: uppercase;
}
h5,
.h5 {
  font-size: 1.25rem;
  font-variation-settings: var(--font-medium);
  &.footer {
    letter-spacing: rem(2px);
  }
}
h6,
.h6 {
  font-variation-settings: var(--font-regular);
  line-height: 1.3125rem;
  letter-spacing: 0.05rem;
}
