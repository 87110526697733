.detract-attention {
  color: var(--color-grey-500);

  .nav-tabs .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-link:hover {
    color: var(--color-grey-500);
    border-bottom: 1px solid var(--color-grey-500);
  }
  .nav-tabs .nav-link:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.039 42.52'%3E%3Cpath d='M4.643 42.518l38.571-37.5 36.072 37.5' fill-rule='evenodd' fill='%23ffffff' stroke-width='5' stroke='%23ccccd6'/%3E%3C/svg%3E");
  }

  .border-color-basic {
    border-color: var(--color-grey-500) !important;
  }

  .color-text-secondary,
  .text-color-basic {
    color: var(--color-grey-500);
    border-color: var(--color-grey-500);
  }

  .input-group-material label {
    color: var(--color-grey-500);
  }
  .input-group-material .input-group-material-message {
    color: var(--color-grey-500);
  }
  .input-group-material input {
    color: var(--color-grey-50);
    border: 1px solid var(--color-grey-500);
    background: transparent;
    cursor: not-allowed;
  }

  .input-group-material select {
    background: transparent;
    cursor: not-allowed;
  }

  .optional * {
    color: var(--color-grey-500);
    cursor: not-allowed;
  }
}

ngb-alert.alert {
  display: none;
}

ngb-alert.alert.show {
  display: block;
}

.alert {
  @extend .zero-backdrop;
  border-radius: 8px;
  margin-bottom: 30px;
}

.alert-danger {
  color: var(--color-grey-50);
  background-color: color-mix(in srgb, var(--color-red-500) 20%, transparent);
  border-color: var(--color-red-500);
}

.alert-important {
  color: var(--color-grey-50);
  background-color: #f4cbdc;
  border-color: var(--color-red-500);
}

.alert-success {
  color: var(--color-grey-50);
  background-color: rgba(11, 174, 73, 0.4);
  border-color: #0bae49;
}

.alert-dismissible .close {
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  background-color: var(--color-grey-500);
  color: var(--white);
  border-radius: 50%;
  font-size: 22px;
  height: 24px;
  width: 24px;
  padding: 0;
}

.auth {
  a {
    color: var(--color-blue-400);
  }

  .alert-danger,
  .alert-success {
    color: var(--white);
    box-shadow: none;
  }

  .input-group-material-message {
    color: #fff !important;
  }
}
